import React, { useState, useEffect } from 'react'
import { IonBadge, IonContent, IonPage, IonGrid, IonCol, IonRow } from '@ionic/react'
import { RouteComponentProps, withRouter, useParams } from 'react-router-dom'
import { StaticContext } from 'react-router-dom'
import track from 'react-tracking'

//COMPONENTS
import GlobalComponents from '../../components'
import PromotionSlider from '../../components/promotion-slider'
import { PromotionModal } from '../promotion-modal'

//MODELS
import User from '../../models/User'
import Category from '../../models/Category'
import Settings from '../../models/Settings'
import { CartModel } from '../../models/CartModel'
import { ProductModel } from '../../models/ProductModel'
import { PromotionModel } from '../../models/PromotionModel'
import { HashtagModel, ProductHashtag } from '../../models/HashtagModel'
import Office from '../../models/Office'
import { ProviderModel } from '../../models/ProviderModel'

//ICONOS
import cartToolbarIcon from '../../assets/icons/nav_cart_blue.svg'

//UTILS
import { EventEmitter } from '../../utils/events'
import { getCartLength } from '../../utils/cart'

//ESTILOS
import './HashtagView.scss'
import { navigateToCart } from '../../utils/navigation'


//TIPOS
type IPathParams = {
  id: string
}
interface RouteState {
  promotionHashtag?: ProductHashtag
  title?: string
}
type IProps = RouteComponentProps<IPathParams, StaticContext, RouteState> & {
  user: User
  settings: Settings
  categories: Category
  productModel: ProductModel
  tracking?: any
  cartModel: CartModel
  promotion: PromotionModel
  offices: Office
  providerModel: ProviderModel
  hashtagModel: HashtagModel
}

const HashtagView: React.FC<IProps> = props => {
  const { history, hashtagModel, cartModel, productModel } = props
  const { id: hashtagId } = useParams<IPathParams>()
  const [loading, setLoading] = useState<boolean>(true)
  const [cart, setCart] = useState<any>([])
  const [cartLengthData, setCartLengthData] = useState<any>({ cartLength: 0, prevLength: 0 })
  const [animateAtc, setAnimateAtc] = useState<string>('')

  const [isPromotionModalOpen, setIsPromotionModalOpen] = useState<boolean>(false)
  const [hashtag, setHashtag] = useState<any>({})
  const [bannerHashtags, setBannersHashtags] = useState<any[]>([])

  const [productsCategory, setProductsCategory] = useState<any>([])

  useEffect(() => {
    const cartLocalStorage = localStorage.getItem('@cart')
    if (cartLocalStorage) setCart(JSON.parse(cartLocalStorage).products)
    getVendors()
  }, []) 

  useEffect(() => {
    const cartLocal = localStorage.getItem('@cart')
    const cart = cartLocal ? JSON.parse(cartLocal) : null

    if (cart) {
      const updatedCartLength: any = cart.products?.length || null
      setCartLengthData({ cartLength: updatedCartLength, prevLength: updatedCartLength })
    }

    getCart()

    EventEmitter.subscribe('UpdateCartCount', ({ cartUpdateAction }: any) => {
      setCartLengthData((prevState: any) => {
        let newCartLength = prevState.cartLength
        if(cartUpdateAction === 'add') newCartLength = newCartLength + 1
        if(cartUpdateAction === 'subtract') newCartLength = newCartLength - 1
        const didAdd = newCartLength > prevState.cartLength
        if (didAdd) {
          setAnimateAtc('badge-anim-in')
          setTimeout(() => { setAnimateAtc('badge-anim-out') }, 1);
        }
        return { 
          cartLength: newCartLength,
          prevLength: prevState.cartLength 
        }
      })
    })

    return () => {
      EventEmitter.unsubscribe('UpdateCartCount')
    }
  }, [])

  const getCart = async () => {
    const cartLength: any = await getCartLength()
    setCartLengthData({ cartLength, prevLength: cartLength })
  }

  const getVendors = async () => {
    const vendors = await hashtagModel.getVendors(hashtagId)
    const _categories = []

    for (const _v of Array.from(vendors)) {
      // @ts-ignore 
      const [providerName] = Object.keys(_v)
      // @ts-ignore 
      const products = _v[providerName].docs
      if (products.length > 0) {
        // @ts-ignore 
        const _id = products[0].providerId
        _categories.push({
          _id,
          products,
          name: providerName
        })
      }
    }

    const sortedCategories = _categories.sort((a: any, b: any) => {
      if ( a.products.length < b.products.length ) return 1;
      if ( a.products.length > b.products.length ) return -1;
      return 0;
    })
    
    getHashtag()
    setProductsCategory(sortedCategories)
  }

  const getHashtag = async () => {
    if (hashtagId) {
      const fetchedHashtag = await hashtagModel.getHashtag(hashtagId)
      setHashtag(fetchedHashtag)
      setBannersHashtags([fetchedHashtag])
    }
    setLoading(false)
  }

  const goToCart = () => navigateToCart(history, props.location.state || props.history.location.state);

  const onMoreInfoClick = (categoryId: any, categoryName: string) => {
    history.push(`/vendors/${categoryId}/hashtag/${hashtagId}`, {
      title: categoryName
    })
  }

  const { tag } = hashtag


  return (
      <IonPage className="hashtags-page" >
        <div className='relative-header-wrapper'>
          {cartLengthData.cartLength > 0 && <IonBadge className={`cart-badge-btn ${animateAtc}`} >{cartLengthData.cartLength}</IonBadge> }
          <GlobalComponents.ToolBar
            title={tag || ""}
            secondaryButtons={[{ type: 'back', onClick: () => { history.push('/home') } }]}
            secondaryHeader={true}
            boxShadow={true}
            primaryButtons={[
              {
                icon: cartToolbarIcon,
                key: 'cart',
                onClick: goToCart,
                badge: cart.length,
                animateAtc,
              },
            ]}
          />
        </div>
        <IonContent >
          {/* TODO: No tiene relevancia, no agrega valor este modal
          
          {(isPromotionModalOpen && hashtag && hashtag.image && hashtag.image.url) &&
            <PromotionModal
              placementName='hashtag-view'
              isOpenPromotion={isPromotionModalOpen}
              promotionModal={{
                image: hashtag.image
              }}
              closePromotionModal={() => setIsPromotionModalOpen(false)}
              {...props}
            />
          } */}

          {!loading && bannerHashtags && bannerHashtags.length > 0 && (
            <IonGrid style={{ paddingTop: "0px" }} >
              <IonRow className="hashtags-banner-row" >
                <IonCol style={{ paddingTop: "0px" }} >
                  <div className="promotions-home-wrapper" onClick={() => { setIsPromotionModalOpen(true) }}>
                    <PromotionSlider 
                      promotions={bannerHashtags}
                      cartModel={cartModel}
                      productModel={productModel}
                      history={history}
                      providers={localStorage.getItem('@providers')}
                      typeBanners={'hashtags'}
                      placement='hasthag-view'
                    />
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          )}
          
          
          {!loading &&
            productsCategory.length > 0 &&
            productsCategory.map((category: any, index: number) => (
                <GlobalComponents.ProductSlider
                key={`category-slider-${category._id}`}
                products={category.products}
                placementName={`hashtag-view-category-product-slider-${category.name}`}
                info={{
                  title: category.name,
                  id: category._id,
                  brandId: '',
                }}
                hidePrice= {false}
                onMoreInfoClick={() => {
                  onMoreInfoClick(category._id, category.name)
                }}
                onGoToCart={goToCart}
                {...props}
                categoryPosition={index + 1}
                fromSlider={false}
              />
            ))}
          {productsCategory.length <= 0 && !loading && <GlobalComponents.GuruWhiteScreen text="No hay proveedores que tengan productos en este hashtag." />}
          {loading && <GlobalComponents.LoadingComponent />}
        </IonContent>
      </IonPage>
    )
}

export default track({ page: 'HashtagView' })(withRouter(HashtagView))

