import React from 'react'
import { IonModal } from '@ionic/react'
import User from '../../models/User'
import ButtonComponent from '../../components/basic-button/ButtonComponent'
import { countryCl, countryMx, countryPe } from '../../utils/countriesTexts'

import './ModalTermsConditions.scss'

interface Props {
  user: User,
  history:any
}

interface State {
  modal: boolean
  termsTexts: any
}

export default class ModalTermsConditions extends React.PureComponent<Props, State>{
  state: State = {
    modal: false,
    termsTexts: { title: '', paragraph: ''}
  }
  
  componentDidMount() {
    this.checkUserTermsByCountry()
  }

  checkUserTermsByCountry = async () => {
    const userInfo: any = await this.props.user.getUserMe() || null
    const countryDataLocal = localStorage.getItem('countryName')
    const countryData = countryDataLocal ? JSON.parse(countryDataLocal) : null
    const termsTexts = this.textByCountry(countryData?.countryCode)

    const termsandconditions = userInfo?.termsandconditions !== undefined 
      ? userInfo?.termsandconditions 
      : true

    if (userInfo && !termsandconditions) this.setState({ modal: true, termsTexts })
  }

  textByCountry = (countryCode: string) => {
    const countryTexts = [
      { 
        country: countryCl, 
        title: 'Términos legales por aceptar', 
        paragraph: 'Hemos actualizado nuestros términos.'
      },
      {
        country: countryPe, 
        title: 'Términos legales por aceptar', 
        paragraph: 'Hemos actualizado nuestros términos y condiciones además de políticas de uso de datos.'
      },
      {
        country: countryMx, 
        title: 'Términos legales por aceptar', 
        paragraph: 'Hemos actualizado nuestros términos y condiciones además de políticas de uso de datos.'
      }
    ]

    return countryTexts.find(({country}: any) => country === countryCode) || countryTexts[0]
  }

  onDismissModal = () => this.setState({ modal:false })

  render(){
    const { modal, termsTexts } = this.state
    return (
      <IonModal className="terms-modal" backdropDismiss={false} isOpen={modal}>
        <div className="content">
            <div className="body">
              <h5 className="container-title-modal">{termsTexts.title}</h5>
              <div className="container-information-modal">{termsTexts.paragraph}</div>
            </div>
            <div className="footer">
              <ButtonComponent 
                text="Leer y aceptar"
                className="btn-tertiary"
                onClick={() => this.props?.history?.push('/terms-conditions')}
              />
            </div>
        </div>
      </IonModal>
    );
  }
}
 