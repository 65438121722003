import React, { useState, useEffect } from 'react'
import {
  IonModal,
  IonContent,
  IonPage,
  IonIcon
} from '@ionic/react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import parse from 'html-react-parser';

// Clients
import {getTermsAndConditionsLogin} from '../../clients/termsConditions'


// Icons
import { close } from 'ionicons/icons'

import './TermsConditionsCoupons.scss'

interface IPathParams {
}

type RouteState = {}

type Props = RouteComponentProps<IPathParams, RouteState> & {
  isOpen: boolean
  closeTermsModal: () => void
}

const TermsConditionsCoupons: React.FC<Props> = (props) => {
  const { isOpen, closeTermsModal } = props
  const [termsConditions, setTermsConditions] = useState<any>({})


  useEffect(() => {
    getLegalDocs()
  }, [])

  const getLegalDocs = async () => {
    const termsConditions = await getTermsAndConditionsLogin();
    setTermsConditions(termsConditions)
  }

  const termsParagraph = (paragraph: any) => {
    if(paragraph.includes("[link]")) {
      let parsedParagraph = paragraph.replace("[link]:", '')
      parsedParagraph = parsedParagraph.split(':')
      const url = `${parsedParagraph[1]}:${parsedParagraph[2]}`
      parsedParagraph = `${parsedParagraph[0]} <a href=${url}>${url}</a>`
      return parse(parsedParagraph)
    }

    return paragraph
  }
  
  const shouldRenderDoc = (index: number, currentTerms: number) => index === currentTerms 

  return (
    <IonModal isOpen={isOpen}>
      <IonPage className="terms-conditions-coupons ds-content-page"  >
        <div className="header">
          <IonIcon icon={close} onClick={() => closeTermsModal()}  />
        </div>
        {termsConditions?.content?.map((terms : any, index: number) => {
          const shouldRenderSection: any = shouldRenderDoc(index, 1 )
          
          if (shouldRenderSection) return (
            <div key={index} className="terms-page">
              <div className="terms-wrapper">
                <h3 className='title'>{terms.title}</h3>
                <div className='paragraph'>{terms.subtitle}</div>
                <div className='general-text'>{terms.paragraph}</div>
                <div>
                  {terms.content && terms.content.length > 0 && terms.content.map((internalTerms:any, index: any) => {
                    return (
                      <div key={index}>
                        <div className='points'>{internalTerms.title}</div>
                        <div className='general-text'>{internalTerms.paragraph}</div>
                        {internalTerms.content && internalTerms.content.length > 0 && internalTerms.content.map((moreInternalTerms:any, index: number) => {
                        const paragraph = termsParagraph(moreInternalTerms.paragraph)
                          return (
                            <div key={index} className='general-text'><span>{moreInternalTerms.title}</span> {paragraph} </div>
                          )
                        })}
                      </div>
                    )
                  })}
                </div>
              </div>    
            </div>       
          )
          return ''
        })}
    
      </IonPage>
    </IonModal>
  )
}

export default withRouter(TermsConditionsCoupons)

