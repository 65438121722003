import Axios from 'axios'
import { orderBy } from 'lodash'
import { ProductIn } from './category'

export const fetchFavorites = async (providerId: string): Promise<ProductIn[] | undefined> => {
  try {
    const result = await Axios.get<ProductIn[]>(`${process.env.REACT_APP_BFF_NODE}/v1/products/provider/${providerId}/favourites`)
    return orderBy(result.data, ['order'], ['asc'])
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
  }
}

export const fetchProducts = async (category: string): Promise<ProductIn[]> => {
  try {
    const result = await Axios.get<ProductIn[]>(`${process.env.REACT_APP_BFF_F}/products/category/${category}`)
    return orderBy(result.data, ['order'], ['asc'])
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const fetchProductByProviderIdAndSku = async (
  providerId: string,
  sku: string,
): Promise<ProductIn | undefined> => {
  try {
    const result = await Axios.get<ProductIn[]>(
      `${process.env.REACT_APP_BFF_NODE}/v2/products/${sku}/providers/${providerId}`,
    )
    return result.data.length === 1 ? result.data[0] : undefined
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
    console.log({error}) 
  }
}

export const fetchProductById = async (
  productId: string,
): Promise<ProductIn | undefined> => {
  try {
    const result = await Axios.get<ProductIn[]>(
      `${process.env.REACT_APP_BFF_NODE}/v1/products/${productId}`,
     )
    return result.data.length === 1 ? result.data[0] : undefined
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const totalPrice = async (idProduct:any, quantity:any) => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v2/products/${idProduct}/price`,{
      params: {
        quantity: quantity,
      },
    })
    return result.data
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const getSuggestedProductsForProduct = async (idProduct:any, cartId: string, mimimun: number = 1, maximum: number = 6) => {
  try {
    const result = await Axios.get<any>(
      `${process.env.REACT_APP_BFF_NODE}/v1/products/singleSuggested?cartId=${cartId}&productId=${idProduct}`,
      {
        params: {
          mimimun,
          maximum,
        },
      }
    )
    return result.data
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const getSuggestedProductsForCart = async (providerId: string[], cartId: string, mimimun: number = 1, maximum: number = 10) => {
  try {
    const result = await Axios.get<any>(
      `${process.env.REACT_APP_BFF_NODE}/v1/products/multiSuggested?cartId=${cartId}&providerId=${providerId.toString()}`,
      {
        params: {
          mimimun,
          maximum,
        },
      }
    )
    console.log({providerId, cartId, mimimun, maximum, result})
    return result.data
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const postAlertOfProduct = async (productId: string): Promise<any> => {
  try {
    const result = await Axios.post<any>(`${process.env.REACT_APP_BFF_NODE}/v1/products/${productId}/subscribe`)
    return result
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
  }
}

export const getOffersOfHome = async (page:number, limit:number = 12): Promise<any> => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/products/offers?page=${page}&limit=${limit}`)
    return result
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
    return null
  }
}

export const getVendorsWithOffersAtHome = async (): Promise<any> => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/vendors/products/offers`)
    return result
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
  }
}

export const getProductsByVendor = async (page:number, limit:number = 9, vendorId:string): Promise<any> => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/products/offers?page=${page}&limit=${limit}&providerId=${vendorId}`)
    return result
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
  }
}