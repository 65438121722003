import { IonContent, IonPage } from '@ionic/react'
import React from 'react'
import NavbarHomeProviders from '../../components/navbar-home-providers'
import './index.scss'
import { REACT_APP_BFF_IMAGE } from '../../config'

interface Brand {
  _id: string
  name: string
  logo: string
}

const brands: Brand[] = [
  {
    _id: '5f68a775eba37c00242c1f41',
    name: 'Ballerina',
    logo: 'm_ballerina-ballerina.png',
  },
  {
    _id: '5f3db71a3e58d50023de026d',
    name: 'Bonanza',
    logo: 'bonanza_1626973907329.png',
  },
  {
    _id: '5f3db71a3e58d50023de026d',
    name: 'Bonanza',
    logo: 'bonanza_1626973907329.png',
  },
  {
    _id: '5f3db71a3e58d50023de026d',
    name: 'Bonanza',
    logo: 'bonanza_1626973907329.png',
  },
  {
    _id: '5f3db71a3e58d50023de026d',
    name: 'Bonanza',
    logo: 'bonanza_1626973907329.png',
  },
  {
    _id: '5f3db71a3e58d50023de026d',
    name: 'Bonanza',
    logo: 'bonanza_1626973907329.png',
  },
  {
    _id: '5f3db71a3e58d50023de026d',
    name: 'Bonanza',
    logo: 'bonanza_1626973907329.png',
  },
  {
    _id: '5f3db71a3e58d50023de026d',
    name: 'Bonanza',
    logo: 'bonanza_1626973907329.png',
  },
  {
    _id: '5f3db71a3e58d50023de026d',
    name: 'Bonanza',
    logo: 'bonanza_1626973907329.png',
  },
  {
    _id: '5f3db71a3e58d50023de026d',
    name: 'Bonanza',
    logo: 'bonanza_1626973907329.png',
  },
  {
    _id: '5f3db71a3e58d50023de026d',
    name: 'Bonanza',
    logo: 'bonanza_1626973907329.png',
  },

  {
    _id: '5f3db71a3e58d50023de026d',
    name: 'Bonanza',
    logo: 'bonanza_1626973907329.png',
  },
  {
    _id: '5f3db71a3e58d50023de026d',
    name: 'Bonanza',
    logo: 'bonanza_1626973907329.png',
  },
  {
    _id: '5f3db71a3e58d50023de026d',
    name: 'Bonanza',
    logo: 'bonanza_1626973907329.png',
  },
]

function BrandsFulfillment() {
  return (
    <IonPage >
      <IonContent >
        <NavbarHomeProviders redirectTo="home-fulfillment" />

        <div className="brands-fulfillment-content">
          <div>
            <h3>Marcas</h3>

            <div>
              {brands.map((brand) => (
                <div key={brand._id}>
                  <img src={`${REACT_APP_BFF_IMAGE}brands/${brand.logo}`} alt={brand.name} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default BrandsFulfillment
