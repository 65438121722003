import React from 'react';
import { IonIcon } from '@ionic/react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { MapLocationCoordinates } from '../../models/MapLocationCoordinates';
import { useGeolocalization } from '../../hooks/useGeolocation';

import locationIcon from '../../assets/icons/icon_location-pin.svg';
import addressIcon from './../../assets/icons/icn_address.svg';

import './MapLocationPicker.scss';

interface FindMeButtonProps {
  onClick: () => void;
  disabled: boolean;
}

const FindMeButton: React.FC<FindMeButtonProps> = ({ onClick, disabled }) => (
  <button
    onClick={onClick}
    type="button"
    className={`find-me-button ${disabled ? 'find-me-button--disabled' : ''}`}
  >
    <img src={addressIcon} className="find-me-button__icon" alt="find-me-icon" />
    <span>Encuéntrame</span>
  </button>
);

interface MapLocationPickerProps {
  userCountryCode: string;
  coordinates?: MapLocationCoordinates;
  setCoordinates?: (coordinates: MapLocationCoordinates) => void;
  handleFindMe?: () => void;
  isFindMeDisabled?: boolean;
}

const MapLocationPicker: React.FC<MapLocationPickerProps> = ({
  userCountryCode,
  coordinates: propCoordinates,
  setCoordinates: propSetCoordinates,
  handleFindMe: propHandleFindMe,
  isFindMeDisabled: propIsFindMeDisabled,
}) => {
  const {
    coordinates: hookCoordinates,
    setCoordinates: hookSetCoordinates,
    handleFindMe: hookHandleFindMe,
    geolocationDisabled: hookGeolocationDisabled,
    // isLoaded,
    error
  } = useGeolocalization(userCountryCode);

  const coordinates = propCoordinates || hookCoordinates;
  const setCoordinates = propSetCoordinates || hookSetCoordinates;
  const handleFindMe = propHandleFindMe || hookHandleFindMe;
  const isFindMeDisabled = propIsFindMeDisabled !== undefined ? propIsFindMeDisabled : hookGeolocationDisabled;

  const handleMapChange = (e: google.maps.MapMouseEvent) => {
    if (e.latLng) {
      setCoordinates({
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      });
    }
  };

  if (error) return <div>Error: {error}</div>;

  return (
    <div className="geolocalization-map">
      <div className="geolocalization-map__marker-container">
        <IonIcon className="map-marker" slot="icon-only" icon={locationIcon} />
      </div>

      <div className="geolocalization-map__find-btn-container">
        <FindMeButton onClick={handleFindMe} disabled={isFindMeDisabled} />
      </div>


        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '55vh' }}
          center={coordinates}
          zoom={18}
          onClick={handleMapChange}
          options={{ fullscreenControl: false }}
        >
          {/* <Marker position={coordinates} /> */}
        </GoogleMap>
      {/* ) : (
        <div>Loading map...</div>
      )} */}
    </div>
  );
};

export default MapLocationPicker;