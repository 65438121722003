import React from 'react'
import {
  IonContent,
  IonPage,
} from '@ionic/react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import User from '../../models/User'
import track from 'react-tracking'

import ImgLoginComponent  from '../img-login-component/ImgLoginComponent'
import AdaptativeLoginForm from '../adaptative-login-form/AdaptativeLoginForm'

import { Device } from '@capacitor/device';
import { App } from '@capacitor/app';

import logo from './../../assets/logo.png'

import './RecoverAccess.scss'



type IPathParams = {}

type Props = RouteComponentProps<IPathParams> & {
  user: User
}

interface State{
  appVersion: any
  appInfo: any
  country: any
}

class RecoverAccess extends React.PureComponent<Props, State> {
  state: State ={
    appVersion: {},
    appInfo: {},
    country: {}
  }

  async componentDidMount(){


    const countryName = localStorage.getItem('countryName') 
    const country = countryName ? JSON.parse(countryName) :  null 
    this.setState({country:country})

    const info = await Device.getInfo()
    const response = await this.props.user.appCurrentVersion()
    const appInfoCapacitor = await App.getInfo().catch(err => null)

    this.setState({
      appVersion: response,
      appInfo: { ...info, appVersion: appInfoCapacitor?.version || "" }
    })
  }

  render() {
    const {appInfo, country } = this.state 

    return (
      <IonPage className="recover-access" >
        <IonContent className="content-recover" >
          <ImgLoginComponent 
          img={logo}
          text= {'SOLICITAR INGRESO'}
          arrowBackOnclik={true}
          {...this.props}
          />
          <div className="input-validate">
            <div className="subtitle-validate-user">Rellena el siguiente formulario con tus datos</div>
          </div>
          <AdaptativeLoginForm
            message={'solicitud de ingreso'}
            subject={"[Solicitud - ingreso] - Usuario solicitando ingreso"}
            automaticMessage={true}
            {...this.props}
          />
          
        </IonContent>
        <div className={`app-version ${appInfo.platform}`}>
          <span>©Almacén Gurú - {country.countryName}</span>
          {process.env.REACT_APP_NODE_ENV !== 'production' && 
            <span className="capitalize">
              - {process.env.REACT_APP_NODE_ENV} 
            </span>&&
            <span> - {appInfo.platform} </span>
          }
          {(appInfo.platform === 'ios' || appInfo.platform === 'android') && 
            <span> versión {appInfo.appVersion}</span>
          }
        </div>
      </IonPage>
    )
  }
}

export default track({ page: 'RecoverAccess' })(withRouter(RecoverAccess))

