import React from 'react';
import { IonIcon } from '@ionic/react';
import { GoogleMap as GoogleMapComponent, Marker } from '@react-google-maps/api';
import { location } from 'ionicons/icons';
import { useGeolocalization } from '../../hooks/useGeolocation';
import { MapLocationCoordinates } from '../../models/MapLocationCoordinates';

import './GoogleMap.scss';

interface GoogleMapProps {
  userCountryCode: string;
  coordinates?: MapLocationCoordinates;
  zoom?: number;
}

const CustomMarker: React.FC<{ lat: number; lng: number }> = () => (
  <IonIcon className="icon-location" slot="icon-only" icon={location} />
);

const GoogleMap: React.FC<GoogleMapProps> = ({ userCountryCode, coordinates: propCoordinates, zoom: propZoom }) => {
  const {
    coordinates: hookCoordinates,
    error,
  } = useGeolocalization(userCountryCode);

  const coordinates = propCoordinates || hookCoordinates;
  const zoom = propZoom || 18;

  const mapOptions: google.maps.MapOptions = {
    fullscreenControl: false,
    // Add any other map options you need here
  };

  if (error) return <div>Error: {error}</div>;
  // if (!isLoaded) return <div>Loading map...</div>;

  return (
    <div className="container-map">
      <GoogleMapComponent
        mapContainerStyle={{ width: '100%', height: '100%' }}
        center={coordinates}
        zoom={zoom}
        options={mapOptions}
      >
        <Marker position={coordinates} icon={location} />
      </GoogleMapComponent>
    </div>
  );
};

export default GoogleMap;