import React from 'react'
import { IonContent, IonPage } from '@ionic/react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import track from 'react-tracking'

// Models
import User from '../../models/User'

// Icons
import arrowBack from './../../assets/icons/arrow-back-blue.svg'

// Clients
import { userValidate } from '../../clients/login'

// Components
import HeaderWithoutToolbar from '../../components/header-without-toolbar/HeaderWithoutToolbar'
import GuruHelper from '../../components/guru-helper/GuruHelper'
import ButtonComponent from '../../components/basic-button/ButtonComponent'
import ContactSupport from '../../components/contact-support/ContactSupport'
import AlertModal from '../../components/modals/AlertModal'
import DniField from '../../components/dni-field/DniField'
import PhoneUsernameField from '../../components/phone-username-field/PhoneUsernameField'

// Utils
import { countryCl, countryMx, countryPe, dniByCountry, phoneAsUsernameCountries } from '../../utils/countriesTexts'

// Styles
import './ValidateUser.scss'

type IPathParams = {}

type Props = RouteComponentProps<IPathParams> & {
  user: User
}

interface State {
  country: any
  userName: any
  approvedUserName: boolean
  spinner: boolean
  modalInfo: any
  openOrCloseModal: boolean
}

const countries = [
  {
    country: countryCl,
    title: 'RUT Inválido',
    firstParagraph: 'Lo sentimos, el RUT ingresado no se encuentra en nuestro sistema.',
    secondParagraph: 'Te invitamos a registrarte para disfrutar de nuestro servicio.',
    redirect: '/login',
  },
  {
    country: countryPe,
    title: 'RUC no encontrado',
    firstParagraph: 'Lo sentimos, el RUC ingresado no se encuentra en nuestro sistema.',
    secondParagraph: 'Te invitamos a solicitar la creación de usuario nuevo.',
    redirect: '/login',
  },
  {
    country: countryMx,
    title: 'Teléfono no encontrado',
    firstParagraph: 'Lo sentimos, el teléfono ingresado no se encuentra en nuestro sistema.',
    secondParagraph: 'Te invitamos a solicitar la creación de usuario nuevo.',
    redirect: '/login',
  },
]

class ValidateUser extends React.PureComponent<Props, State> {
  state: State = {
    country: '',
    userName: '',
    approvedUserName: false,
    spinner: false,
    modalInfo: '',
    openOrCloseModal: false,
  }

  async componentDidMount() {
    const countryName = localStorage.getItem('countryName')
    const country = countryName ? JSON.parse(countryName) : null
    this.setState({ country: country })
  }

  validateUser = (user: any, approved: boolean) => {
    this.setState({
      userName: user,
      approvedUserName: approved,
    })
  }

  handlerValidateUser = async () => {
    const { userName } = this.state
    const user: any = userName
    this.setState({ spinner: true })
    try {
      const infoUser = await userValidate(user.username.replaceAll('.', '').replace('-', ''))
      if (infoUser?.email || infoUser?.phone) {
        this.setState({ spinner: false })
        infoUser.rut = userName.username
        this.props?.history?.push('/recovery-password', { infoUser })
      } else {
        this.props?.history?.push('/help-form', {
          text: 'No encontramos información de contacto. Por favor, deja tus datos y nuestro equipo lo ayudará',
        })
      }
    } catch (e) {
      this.infoModal()
      this.setState({ openOrCloseModal: true })
      this.setState({ spinner: false })
    }
  }

  infoModal = () => {
    const { country } = this.state
    this.setState({
      modalInfo:
      country &&
      country.countryCode &&
      countries.find(countryFound => countryFound.country === country.countryCode)
    })
  }

  renderGuruHelper = () => {
    const dniName = dniByCountry()

    return (
      <GuruHelper
        label={`Verifica tu ${dniName} Comercial`}
        text={`¡Bienvenido! Primero, verifiquemos que tu ${dniName} Comercial está registrado`}
      />
    )
  }

  renderUsernameField = (isPhone: boolean) => {
    const { country } = this.state
    const countryCode = country?.countryCode

    if (isPhone && phoneAsUsernameCountries.includes(countryCode)) {
      return <PhoneUsernameField validateUser={this.validateUser} />
    }

    return <DniField countryCode={countryCode} properties={{ validateUser: this.validateUser, informativeText: '' }} />
  }

  render() {
    const { openOrCloseModal, modalInfo, approvedUserName, spinner } = this.state
    const { history } = this.props

    return (
      <IonPage className="register-page-password" >
        <IonContent className="container-validate-user" >
          <HeaderWithoutToolbar icon={arrowBack} text="Recupera tu acceso" onClick={history.goBack} />
          {this.renderGuruHelper()}

          <div className="username-field-wrapper">{this.renderUsernameField(true)}</div>

          <div className="container-validate-btn">
            <ButtonComponent
              onClick={this.handlerValidateUser}
              disabled={!approvedUserName}
              text="Verificar"
              className={`btn-primary ${spinner ? 'loading' : ''} `}
            />
          </div>

          {openOrCloseModal && (
            <AlertModal
              label={`${dniByCountry()} no registrado`}
              text={`No es posible recuperar tu acceso porque el ${dniByCountry()} que ingresaste no está inscrito.`}
              buttonText={'Regístrate'}
              isOpen={openOrCloseModal}
              buttonAction={() => this.props?.history?.push(modalInfo.redirect)}
              onDismiss={() => this.props?.history?.push(modalInfo.redirect)}
            />
          )}
        </IonContent>
        <div className="container-help">
          <ContactSupport />
        </div>
      </IonPage>
    )
  }
}

export default track({ page: 'RecoveryPassword' })(withRouter(ValidateUser))
