import React from 'react'
import { IonChip, IonIcon, IonInput, IonModal } from "@ionic/react"
import './index.scss'
import { addOutline, removeOutline, closeOutline } from 'ionicons/icons'
import NewProductCard from '../new_product_card'

const products = [
    {
      product: {
        productId: '5efb730a63f30a0022903ac0',
        providerName: 'EGC Foodservice ',
        productName: 'Aceite Vegetal 900 Cc',
        imageFullPath: 'https://static.almacenguru.com/static/5efb71a990d93500220c8886/9964801203_1652890308291.jpg',
        offerPrice: 0,
        package: 'unidad',
        price: 1800,
        promotionalDynamicDetail: null,
        promotionalDynamicType: 'directOfferPrice',
        quantityMax: 60,
        quantityMin: 1,
        stock: null,
        units: 1,
        uom: 'Unidades',
        outOfStock: true,
      },
      paymentMethod: '',
      providerId: '5efb71a990d93500220c8886',
    },
    {
      product: {
        productId: '5efb730a63f30a0022903ac0',
        providerName: 'EGC Foodservice ',
        productName: 'Aceite Vegetal 900 Cc',
        imageFullPath: 'https://static.almacenguru.com/static/5efb71a990d93500220c8886/9964801203_1652890308291.jpg',
        offerPrice: 1690,
        package: 'unidad',
        price: 1800,
        promotionalDynamicDetail: null,
        promotionalDynamicType: 'directOfferPrice',
        quantityMax: 60,
        quantityMin: 1,
        stock: null,
        units: 1,
        uom: 'Unidades',
        outOfStock: false,
      },
      paymentMethod: '',
      providerId: '5efb71a990d93500220c8886',
    },
    {
      product: {
        productId: '5efb730a63f30a0022903ac0',
        providerName: 'EGC Foodservice ',
        productName: 'Aceite Vegetal 900 Cc',
        imageFullPath: 'https://static.almacenguru.com/static/5efb71a990d93500220c8886/9964801203_1652890308291.jpg',
        offerPrice: 1690,
        package: 'unidad',
        price: 1800,
        promotionalDynamicDetail: null,
        promotionalDynamicType: 'directOfferPrice',
        quantityMax: 60,
        quantityMin: 1,
        stock: null,
        units: 1,
        uom: 'Unidades',
        outOfStock: false,
      },
      paymentMethod: '',
      providerId: '5efb71a990d93500220c8886',
    },
    {
      product: {
        productId: '5efb730a63f30a0022903ac0',
        providerName: 'EGC Foodservice ',
        productName: 'Aceite Vegetal 900 Cc',
        imageFullPath: 'https://static.almacenguru.com/static/5efb71a990d93500220c8886/9964801203_1652890308291.jpg',
        offerPrice: 1690,
        package: 'unidad',
        price: 1800,
        promotionalDynamicDetail: null,
        promotionalDynamicType: 'directOfferPrice',
        quantityMax: 60,
        quantityMin: 1,
        stock: null,
        units: 1,
        uom: 'Unidades',
        outOfStock: false,
      },
      paymentMethod: '',
      providerId: '5efb71a990d93500220c8886',
    },
    {
      product: {
        productId: '5efb730a63f30a0022903ac0',
        providerName: 'EGC Foodservice ',
        productName: 'Aceite Vegetal 900 Cc',
        imageFullPath: 'https://static.almacenguru.com/static/5efb71a990d93500220c8886/9964801203_1652890308291.jpg',
        offerPrice: 1690,
        package: 'unidad',
        price: 1800,
        promotionalDynamicDetail: null,
        promotionalDynamicType: 'directOfferPrice',
        quantityMax: 60,
        quantityMin: 1,
        stock: null,
        units: 1,
        uom: 'Unidades',
        outOfStock: true,
      },
      paymentMethod: '',
      providerId: '5efb71a990d93500220c8886',
    },
    {
      product: {
        productId: '5efb730a63f30a0022903ac0',
        providerName: 'EGC Foodservice ',
        productName: 'Aceite Vegetal 900 Cc',
        imageFullPath: 'https://static.almacenguru.com/static/5efb71a990d93500220c8886/9964801203_1652890308291.jpg',
        offerPrice: 1690,
        package: 'unidad',
        price: 1800,
        promotionalDynamicDetail: null,
        promotionalDynamicType: 'directOfferPrice',
        quantityMax: 60,
        quantityMin: 1,
        stock: null,
        units: 1,
        uom: 'Unidades',
        outOfStock: false,
      },
      paymentMethod: '',
      providerId: '5efb71a990d93500220c8886',
    },
  ]

interface Props {
    showModal: boolean
    setShowModal: (show: boolean) => void
}

function ModalDetailProduct({ showModal, setShowModal }: Props) {
  return (
    <IonModal isOpen={showModal} id="modal-detail-product" backdropDismiss={false} >
        <div className="wrapper">
            <div className='content-close'>
              <IonIcon icon={closeOutline} onClick={() => setShowModal(false)} ></IonIcon>
            </div>

            <div className='content-image'>
                <img src="https://static.almacenguru.com/static/5efb71a990d93500220c8886/9964801203_1652890308291.jpg" alt="" />

                <h3>Vinagre Vino Blanco 1 Litro</h3>
                <span>Hernandez</span>
            </div>

            <div className='content-info'>
                <div className='content-info__container'>
                    <div className='info'>
                        <span>Proveedor</span>
                        <span>EGC Foodservice</span>
                    </div>
                </div>

                <div className='separator'></div>

                <div className='content-info__container'>
                    <div className='info'>
                        <span>{"Cantidad mínima"}</span>
                        <span>1</span>
                    </div>

                    <div className='info'>
                        <span>Cantidad máxima</span>
                        <span>999</span>
                    </div>
                </div>

                <div className='separator'></div>

                <div className='content-info__container'>
                    <div className="info">
                        <span></span>

                        <span className='buttons'>
                        <div className="inputs-background"></div>

                        <IonChip className="button btn-left" onClick={() => ({})} >
                            <IonIcon className="icon" icon={removeOutline} ></IonIcon>
                        </IonChip>

                        <IonInput type="number" className="value-cell" value={3}  />

                        <IonChip
                            className={`button btn-right button__${true ? 'disabled' : 'active'}`}
                            onClick={() => ({})}
                            disabled={false}
                            
                        >
                            <IonIcon className="icon" icon={addOutline} ></IonIcon>
                        </IonChip>
                        </span>
                    </div>

                    <div className='info'>
                        <span>Unidades por</span>
                        <span>1 Unidades</span>
                    </div>

                    <div className='info'>
                        <span>Precio por unidad</span>
                        <span>$975</span>
                    </div>
                </div>

                <div className='separator'></div>
            </div>

            <div className='content-total'>
                <span>Total</span>
                <span>$70.920</span>
            </div>

            <div className='content-sugeridos'>
                <h5>Sugeridos</h5>

                <div className="products">
                  {products.map((product, index) => (
                    <NewProductCard
                    key={index}
                    product={product.product}
                    paymentMethod={product.paymentMethod}
                    providerId={product.providerId}
                    setShowModal={() => ({})}
                />
                  ))}
                </div>
            </div>
        </div>
    </IonModal>
  )
}

export default ModalDetailProduct