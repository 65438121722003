import React from 'react'
import {
  IonContent,
  IonPage,
} from '@ionic/react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import User from '../../models/User'
import track from 'react-tracking'

import AdaptativeLoginForm from '../adaptative-login-form/AdaptativeLoginForm'
import arrowBack from './../../assets/icons/arrow-back-blue.svg'

import HeaderWithoutToolbar from '../../components/header-without-toolbar/HeaderWithoutToolbar'
import GuruHelper from '../../components/guru-helper/GuruHelper'
import ContactSupport from '../../components/contact-support/ContactSupport'

import './HelpForm.scss'

type IPathParams = {}

type Props = RouteComponentProps<IPathParams> & {
  user: User
}

interface State{}

class HelpForm extends React.PureComponent<Props, State> {

  render() {
    const { history } = this.props

    return (
      <IonPage className="help-form" >
        <IonContent className="container-help-form" >
          <HeaderWithoutToolbar 
            icon={arrowBack}
            text="Recupera tu acceso"
            onClick={history.goBack}
          />
          <GuruHelper 
            label="Rellena el formulario"
            text="Deja tus datos de contacto para comunicarnos contigo lo más pronto posible"
          />
          <AdaptativeLoginForm
            message={'recuperación de acceso'}
            subject={"[Recuperación - Contraseña] - Usuario Recuperando Contraseña"}
            automaticMessage={true}
            {...this.props}
          />
          <div className="container-help" >
            <ContactSupport />
          </div>
        </IonContent>
      </IonPage>
    )
  }
}

export default track({ page: 'HelpForm' })(withRouter(HelpForm))

