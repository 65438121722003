import React, { useState, useEffect, useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { IonContent, IonPage } from '@ionic/react'
import { useTracking, track } from 'react-tracking'
import { getCoordinates } from '../../clients/offices'
import { OfficeRegistration } from '../../clients/officeRegistration'

// Components
import HeaderWithoutToolbar from '../../components/header-without-toolbar/HeaderWithoutToolbar'
import GuruHelper from '../../components/guru-helper/GuruHelper'
import DropdownSelect from '../../components/select-dropdown/DropdownSelect'
import TextInput from '../../components/input-form/TextInput'
import ButtonComponent from '../../components/basic-button/ButtonComponent'
import GuruAcceptModal from '../../components/modal-action-accept/ModalActionAccept'

// Models
import User from '../../models/User'
import Office from '../../models/Office'

// Icons
import arrowBack from './../../assets/icons/arrow-back-blue.svg'

// Clients
import { getBusinessTypes, getComunesByDepartmentAndCountryCode, getComunesByProvincesAndCountryCode, getDepartmentsByCountryCode, getProvincesByDepartmentsAndCountryCode } from '../../clients/register'

// Utils
import { commercialNameByCountry, countriesWithProvinces, countryMx, countryTerritorySelectText, territorialNames } from '../../utils/countriesTexts'
import { getLocalStorageObject } from '../../utils/localstorageData'

// Styles
import './BranchOffice.scss'

type TerritoryType = "region" | "province" | "commune"
type SupportedCountry = "CL" | "PE" | "MX" | null

interface BranchOfficeProps {
  user: User
  offices: Office
}

const BranchOffice: React.FC<BranchOfficeProps> = ({ user, offices }) => {
  const history = useHistory()
  const location = useLocation<{ lateRegistration?: boolean, businessTypeId?: string }>()
  const tracking = useTracking()

  const [formData, setFormData] = useState({
    commercialName: '',
    deparment: '',
    province: '',
    address: '',
    comune: '',
    postalCode: '',
    selectedBusinessType: null as any,
  })

  const [territories, setTerritories] = useState({
    departments: [] as string[],
    provinces: [] as string[],
    comunes: [] as string[],
  })

  const [country, setCountry] = useState<SupportedCountry>(null)
  const [isValidRegister, setIsValidRegister] = useState(false)
  const [businessTypes, setBusinessTypes] = useState<any[]>([])
  const [businessTypesOptions, setBusinessTypesOptions] = useState<string[]>([])

  useEffect(() => {
    if (location.state?.lateRegistration) fetchBusinessTypes()
    getCurrentCountry()
  }, [location.state?.lateRegistration])

  const fetchBusinessTypes = useCallback(async () => {
    const countryCode = getLocalStorageObject('countryName')?.countryCode
    const types = await getBusinessTypes(countryCode)
    
    const parsedTypes = types.map(({ _id, name, visible }: any) => ({ value: name, label: name, id: _id }))
    const options = types.filter(({ visible }: any) => visible).map(({ name }: any) => name)

    setBusinessTypes(parsedTypes)
    setBusinessTypesOptions(options)
  }, [])

  const getCurrentCountry = useCallback(async () => {
    const currentCountry = localStorage.getItem('countryName')
    if (currentCountry) { 
      const countryCode = JSON.parse(currentCountry).countryCode as SupportedCountry
      setCountry(countryCode)
      await getDepartments(countryCode)
    }
  }, [])

  const getDepartments = useCallback(async (countryCode: string) => {
    const departments = await getDepartmentsByCountryCode(countryCode)
    if (departments) setTerritories(prev => ({ ...prev, departments }))
  }, [])

  const getProvinces = useCallback(async (deparment: string) => {
    if (!country) return
    const provinces = await getProvincesByDepartmentsAndCountryCode(deparment, country)
    setTerritories(prev => ({ ...prev, provinces: provinces || [] }))
  }, [country])

  const getComunes = useCallback(async (territory: string, type: 'province' | 'department') => {
    if (!country) return
    const comunesData = type === 'province' 
      ? await getComunesByProvincesAndCountryCode(territory, country)
      : await getComunesByDepartmentAndCountryCode(territory, country)
    const comunes = comunesData?.map((comune: any) => comune.name) || []
    setTerritories(prev => ({ ...prev, comunes }))
  }, [country])

  const handleInputChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = field === 'postalCode' ? event.target.value.substring(0, 5) : event.target.value
    setFormData(prev => ({ ...prev, [field]: value }))
  }

  const handleSelectChange = (field: string) => (value: string) => {
    setFormData(prev => ({ ...prev, [field]: value }))
    
    if (field === 'deparment') {
      setFormData(prev => ({ ...prev, province: '', comune: '' }))
      setTerritories(prev => ({ ...prev, comunes: [] }))
      if (value && countriesWithProvinces.includes(country || "")) {
        getProvinces(value)
      } else if (value) {
        getComunes(value, 'department')
      }
    } else if (field === 'province') {
      setFormData(prev => ({ ...prev, comune: '' }))
      if (value) getComunes(value, 'province')
    } else if (field === 'selectedBusinessType') {
      const selected = businessTypes.find(type => type.value === value)
      setFormData(prev => ({ ...prev, selectedBusinessType: selected }))
    }
  }

  const createOffice = useCallback(async () => {
    const { commercialName, deparment, province, address, comune, postalCode, selectedBusinessType } = formData
    const isLateRegistration = location.state?.lateRegistration

    try {
      const coordinatesResponse = await getCoordinates(address, comune, country || '') as any
      const { latitude, longitude } = coordinatesResponse?.data as { latitude: number, longitude: number }

      const officeData: OfficeRegistration = {
        casaMatriz: true,
        name: '',
        businessName: commercialName,
        bussinesTypeId: selectedBusinessType?.id || location.state?.businessTypeId || '',
        location: {
          address,
          city: comune,
          deparment,
          province,
          postalCode,
          longitude,
          latitude,
        }
      }

      if (isLateRegistration) {
        const accessToken = localStorage.getItem('@access_token')
        if (!accessToken) throw new Error('No access token found')

        const userData = getLocalStorageObject('@user')
        officeData.name = userData?.name || "Sin nombre"

        await offices.createOfficeFromLoggedIn(officeData)
        window.location.href = '/home'
      } else {
        const authData = getLocalStorageObject('registerSuccesfull')
        if (!authData) throw new Error('No authentication data found')

        officeData.name = authData.user.name || "Sin nombre"

        await user.authenticateWith(authData)
        await user.editCacheUser(authData.user)
        const result = await offices.createOfficeFromRegister(officeData, user)

        if (result) {
          history.push('/home', { ...authData, welcomeGuru: true })
        }
      }
    } catch (error) {
      console.error("Error creating office:", error)
    }
  }, [formData, location.state, offices, user, history, country])

  const signOut = useCallback(async () => {
    await user.signOut()
    history.push('/')
    window.location.reload()
  }, [user, history])

  const validateDisabledSubmit = useCallback((): boolean => {
    const { commercialName, address, comune, deparment, province, postalCode, selectedBusinessType } = formData
    const localizationValidations = !commercialName || !address 
    const territoriesValidations = !comune || !deparment || (country === "CL" ? false : !province)
    const postalCodeValidation = (country === countryMx && postalCode.length !== 5)
    const businessTypeValidation = location.state?.lateRegistration ? !selectedBusinessType : false

    return localizationValidations || postalCodeValidation || businessTypeValidation || territoriesValidations
  }, [formData, country, location.state?.lateRegistration])

  const countryTerritoryName = useCallback((territoryType: TerritoryType) => {
    if (!country) return ""
    const countryTerritorialNames = territorialNames[country]
    return countryTerritorialNames[territoryType]
  }, [country])

  return (
    <IonPage>
      <IonContent className="container-add-office">
        <HeaderWithoutToolbar 
          icon={arrowBack}
          text="Tu sucursal"
          onClick={signOut}
        />
        <GuruHelper 
          label="Agrega tu dirección"
          text="¡Último paso! Detalla la dirección de tu negocio para recibir pedidos"
        />

        {location.state?.lateRegistration && (
          <div className="container-address-items">
            <DropdownSelect 
              label="Tipo de Negocio"
              isDisabled={false}
              options={businessTypesOptions}
              firstOption=""
              onSelect={handleSelectChange('selectedBusinessType')}
              defaultSelected={businessTypesOptions[0]}
            />
          </div>
        )}

        <div className="container-address-items">
          <TextInput 
            onChange={handleInputChange('commercialName')}
            placeholder="Ej: Abarrotes Lucy"
            label={commercialNameByCountry()}  
            isValid={!!formData.commercialName.length}
            error="El nombre comercial es obligatorio"
            value={formData.commercialName}
            type="text"
            toValidate={formData.commercialName}
            autoComplete="off"
          />
        </div>
        
        <div className="container-address-items">
          <DropdownSelect 
            label={countryTerritoryName("region")}
            options={territories.departments}
            firstOption={countryTerritorySelectText("region", country)}
            isDisabled={!territories.departments.length}
            onSelect={handleSelectChange('deparment')}
          />
        </div>

        {country !== "CL" && (
          <div className="container-address-items">
            <DropdownSelect 
              label={countryTerritoryName("province")}
              options={territories.provinces}
              firstOption={countryTerritorySelectText("province", country)}
              isDisabled={!territories.provinces.length}
              onSelect={handleSelectChange('province')}
            />
          </div>
        )}

        <div className="container-address-items">
          <DropdownSelect 
            label={countryTerritoryName("commune")}
            options={territories.comunes}
            firstOption={countryTerritorySelectText("commune", country)}
            isDisabled={!territories.comunes.length}
            onSelect={handleSelectChange('comune')}
          />
        </div>

        {country === countryMx && (
          <div className="container-address-items">
            <TextInput 
              iconValid="icn_address"
              iconDefault="icn_address"
              onChange={handleInputChange('postalCode')}
              placeholder="Ej: 20997"
              label="Código Postal"  
              isValid={formData.postalCode.length === 5}
              error="Ingresa un código postal válido (5 dígitos)"
              value={formData.postalCode}
              type="number"
              toValidate={formData.postalCode}
              autoComplete="off"
            />
          </div>
        )}

        <div className="container-address-items">
          <TextInput 
            iconValid="icn_address"
            iconDefault="icn_address"
            onChange={handleInputChange('address')}
            placeholder="Ej: Mariano Angulo 123"
            label="Dirección"  
            isValid={!!formData.address.length}
            error="La dirección debe tener al menos un carácter"
            value={formData.address}
            type="text"
            toValidate={formData.address}
            autoComplete="off"
          />
        </div>

        <div className="container-btn-add-direction">
          <ButtonComponent 
            text="Agregar"
            disabled={validateDisabledSubmit()}
            onClick={() => setIsValidRegister(true)}
            className="btn-primary"
          />
        </div>
      </IonContent>

      <GuruAcceptModal
        className="btn-primary"
        textButton="Hacer mi primer pedido"
        label="¡Estás listo!"
        secondLabel="Bienvenido a tu cuenta"
        onClickButton={createOffice}
        disabled={false}
        isOpen={isValidRegister}
      />

      <style>
        {`
          .footer-menu-home {
            display: none !important; 
          }
        `}
      </style>
    </IonPage>
  )
}

export default track({ page: 'BranchOffice' })(BranchOffice)