import React from 'react'
import { IonSpinner, IonModal, IonContent } from '@ionic/react'
import track from 'react-tracking'
import { StaticContext } from 'react-router-dom'
import { withRouter, RouteComponentProps } from 'react-router-dom'

// MODELS
import User from '../../models/User'
import Settings from '../../models/Settings'
import { ProductModel } from '../../models/ProductModel'
import { CartModel } from '../../models/CartModel'
import { ProviderModel } from '../../models/ProviderModel'
import Office from '../../models/Office'

// COMPONENTS
import ToolBar from '../../components/tool-bar/ToolBar'
import GuruHelper from '../../components/guru-helper/GuruHelper'
import ButtonComponent from '../basic-button/ButtonComponent'

import { getSuggestedProductsForCart } from '../../clients/product'
import { ProductCard } from '../product-card'

// UTILS
import { areCartProductsLoading } from '../../utils/locationStateValidations'
import { countryCl, countryPe, countryMx } from '../../utils/countriesTexts'
import { registerBasicUserInfoEvent, sendMetric } from '../../pages/firebase/firebaseTags'

import './SuggestedCheckout.scss'
import { EventEmitter } from '../../utils/events'
import { getLocalStorageObject } from '../../utils/localstorageData'
import { VisibilityTracker } from '../../hoc'

interface IPathParams {
  id: string
}

type IProps = RouteComponentProps<IPathParams, StaticContext> & {
  readonly user: User
  readonly settings: Settings
  readonly productModel: ProductModel
  readonly cartModel: CartModel
  readonly providerModel: ProviderModel
  readonly offices: Office
  isOpenModal: boolean
  closeModalSuggested: (proceedWithPayment: boolean, shouldUpdateCart: boolean) => void
  providersToCheckout: string[]
  paymentFormMethod: string
  idCart: string
}

interface State {
  spinner: boolean
  continueBtnDisabled: boolean
  productsPerProvider: any
  productSliders: any[]
  countryInfo: any
  shouldUpdateCart: boolean
}

const mapProductFromSuggested = (product: any) => ({
  ...product,
  provider: product.providerId,
  providerId: product.providerId._id,
  filename: product.imageFullPath ?? product.filename,
  vendorActiveInComune: product.providerId.active,
})

const countries = [
  {
    country: countryCl,
    title: '¡Te pillamos!',
    subTitle: 'No olvides agregar los productos más comprados en tu comuna.',
  },
  {
    country: countryPe,
    title: '¡Ampay!',
    subTitle: 'No olvides agregar los productos más comprados en tu distrito.',
  },
  {
    country: countryMx,
    title: 'Llévele, llévele',
    subTitle: 'No olvides agregar los productos más comprados en tu colonia.',
  },
]

const countryInfoTitle = () => {
  const countryName = localStorage.getItem('countryName')
  const country = countryName ? JSON.parse(countryName) : null

  return country?.countryCode
    ? countries.find((countryFound) => countryFound.country === country.countryCode)
    : countries[0]
}
class SuggestedCheckout extends React.PureComponent<IProps, State> {
  state: State = {
    spinner: false,
    continueBtnDisabled: false,
    productsPerProvider: {},
    productSliders: [],
    countryInfo: countryInfoTitle(),
    shouldUpdateCart: false,
  }

  getPastRouteInfo = {
    route: 'SUGGESTED_CHECKOUT',
    paymentFormMethod: this.props.paymentFormMethod,
    cartId: this.props.idCart,
  }

  mapProductSlidersValues = () => {
    const { productsPerProvider } = this.state
    
    let aux = Object.entries(productsPerProvider).map(([providerId, data]) => ({
      _id: providerId,
      data: data
    }))
    
    
    this.setState({ 
      productSliders: aux, 
      spinner: false, 
      continueBtnDisabled: false 
    })
  }

  componentDidMount = async () => {
    const preCarts = getLocalStorageObject('@cart')
    if (preCarts) {
      localStorage.setItem('preProducts', JSON.stringify(preCarts.products))
    } else {
      localStorage.removeItem('preProducts')
    }

    registerBasicUserInfoEvent({
      eventName: 'cart_page_suggested_checkout_products_modal_view',
      placementName: 'suggested_checkout_modal'
    })

    // sendMetric('view_pre_checkout')
    const { providersToCheckout } = this.props
    localStorage.setItem('@areRemovedProducts', JSON.stringify(false))
    localStorage.setItem('@goBack', JSON.stringify(false))

    EventEmitter.subscribe('UpdateCartCount', () => {
      this.setState({ shouldUpdateCart: true })
    })

    if (!providersToCheckout || providersToCheckout.length === 0) {
      this.proceedWithoutChanges()
      return
    }
    this.setState({ spinner: true, continueBtnDisabled: false })

    const productsPerProvider = await getSuggestedProductsForCart(providersToCheckout, this.props.idCart)
    
    this.setState({ productsPerProvider }, () => {
      this.mapProductSlidersValues()
    })
  }

  componentWillUnmount = () => {
    EventEmitter.unsubscribe('UpdateCartCount')
  }

  goBack = () => this.props.closeModalSuggested(false, this.state.shouldUpdateCart)

  proceedWithoutChanges = () => {
    this.setState({ continueBtnDisabled: true })
    // sendMetric('click_continue_checkout')
    registerBasicUserInfoEvent({
      eventName: 'suggested_checkout_products_modal_continue_with_checkout_button_click',
      placementName: 'suggested_checkout_modal'
    })

    const interval = setInterval(() => {
      if (!areCartProductsLoading()) {
        clearInterval(interval)
        this.props.closeModalSuggested(true, this.state.shouldUpdateCart)
      }
    }, 1000)
  }

  handleWhenProductIsVisible = (product: any) => {
    registerBasicUserInfoEvent({
      eventName: 'suggested_checkout_product_card_view',
      placementName: 'suggested_checkout_modal',
      item_id: product.id,
      item_name: product.name,
      item_sku: product.sku,
      item_vendor_name: product.provider
    })
  }

  handleOnAddToCartProductButtonClick = (product: any) => {
    registerBasicUserInfoEvent({
      eventName: 'suggested_checkout_product_card_add_to_cart',
      placementName: 'suggested_checkout_modal',
      item_id: product.id,
      item_name: product.name,
      item_sku: product.sku,
      item_vendor_name: product.provider
    })
  }

  handleOnClickProductCardContainer = (product: any) => {
    this.setProductEditId(product?.providerId?._id)
    registerBasicUserInfoEvent({
      eventName: 'suggested_checkout_product_card_click',
      placementName: 'suggested_checkout_modal',
      item_id: product.id,
      item_name: product.name,
      item_sku: product.sku,
      item_vendor_name: product.provider
    })
  }

  renderProductCard = (product: any) => {
    const { settings, cartModel, history } = this.props

    return (
        <div
          className="container-card"
          key={product.id}
          onClick={() => this.handleOnClickProductCardContainer(product)}
        >
          <VisibilityTracker onVisible={() => this.handleWhenProductIsVisible(product)} threshold={0.3}>
            <ProductCard
              key={product.id}
              product={mapProductFromSuggested(product)}
              settings={settings}
              cartModel={cartModel}
              history={history}
              pastRouteInfo={this.getPastRouteInfo}
              renderShortcut={true}
              renderAsModal={false}
              closeOneByOne={true}
              onAddToCart={() => this.handleOnAddToCartProductButtonClick(product)}
            />
          </VisibilityTracker>

        </div>
    )
  }

  setProductEditId = (providerId: string) => {
    const productEdit = { id: providerId }
    localStorage.setItem('onEditProduct', JSON.stringify(productEdit))
  }

  renderForSingleProvider = () => {
    const { productSliders } = this.state
    return productSliders.map((provider: any) => (
      <div key={provider._id} id={provider._id}>
        {provider.data.map((product: any) => this.renderProductCard(product))}
      </div>
    ))
  }

  renderForMultipleProviders = () => {
    const { productSliders } = this.state
    return productSliders.map((provider: any) => (
      <div className="product-slider" key={provider._id}>
        <div className="header">
          <span>{provider.data[0].providerId.name}</span>
        </div>
        <div className="slider-body">
          <div>{provider.data.map((product: any) => this.renderProductCard(product))}</div>
        </div>
      </div>
    ))
  }

  renderProductSliders = () => {
    const { productSliders } = this.state
    const slidersLength = productSliders.length
    if (slidersLength === 0) return null
    if (slidersLength === 1) return this.renderForSingleProvider()
    if (slidersLength > 1) return this.renderForMultipleProviders()
  }

  render() {
    const { isOpenModal } = this.props

    const { spinner, continueBtnDisabled, countryInfo } = this.state

    return (
      <IonModal isOpen={isOpenModal} className="suggested-checkout-modal" backdropDismiss={false}>
        {
          <ToolBar
            title={countryInfo.title}
            secondaryButtons={[{ type: 'back', onClick: this.goBack, disabled: continueBtnDisabled }]}
            secondaryHeader={true}
            textCenter={true}
            boxShadow={true}
          />
        }
        {
          <IonContent className="body" >
            {!spinner && (
              <div className="info-section">
                <GuruHelper label="" text={countryInfo.subTitle} />
              </div>
            )}
            {spinner && (
              <div className="loading-suggested">
                <p className="loading">Cargando sugeridos</p>
                <IonSpinner className="spinner-mid" name="crescent"  />
              </div>
            )}
            {this.renderProductSliders()}
          </IonContent>
        }
        {
          <div className="confirmation-section">
            <ButtonComponent
              className={`btn-primary ${continueBtnDisabled ? 'loading' : ''}`}
              disabled={continueBtnDisabled}
              onClick={this.proceedWithoutChanges}
              text={'Continuar'}
            />
          </div>
        }
      </IonModal>
    )
  }
}

export default track({
  page: 'Checkout',
})(withRouter(SuggestedCheckout))
