
import React from 'react'
import {
  IonIcon,
  IonContent, 
  IonButton, 
} from '@ionic/react'
import { StaticContext } from 'react-router-dom'
import { withRouter, RouteComponentProps } from 'react-router-dom'

// Iconos
import logo from './../../assets/logo.png'
import { checkmarkCircle } from 'ionicons/icons'

import './OrderConfirmedModal.scss'
import { registerBasicUserInfoEvent } from '../../pages/firebase/firebaseTags'

interface IPathParams {
}

type IProps = RouteComponentProps<IPathParams, StaticContext> & {
  onClick: () => void
}

class OrderConfirmedModal extends React.PureComponent<IProps> {

  handleKeepBuyingOptionClick = () => {
    registerBasicUserInfoEvent({
      eventName: 'order_confirmation_modal_page_keep_buying_button_click',
      placementName: 'order_confirmation_modal'
    })
    this.props?.history?.push('/home')
  }

  handleMyOrdersOptionClick = () => {
    registerBasicUserInfoEvent({
      eventName: 'order_confirmation_modal_page_my_orders_button_click',
      placementName: 'order_confirmation_modal'
    })
    this.props.onClick()
  }

  componentDidMount(): void {
    registerBasicUserInfoEvent({
      eventName: 'order_confirmation_modal_page_view',
      placementName: 'order_confirmation_modal'
    })
  }

  render() {
  
    return (
      <IonContent >
        <div className='modal-orders-confirmed'>
          <div className="content">
              <div className="body-orders-confirmed">
                <div className='star' style={{ backgroundImage: `url(${logo})` }} ></div>
                <div className='container-info-productModal'>
                  <div className='info-modal' >Tu pedido fue realizado con éxito</div>
                </div>
                <div className="container-btn-next">
                   <IonButton 
                      className='next-button'
                      mode="ios"
                      onClick={this.handleKeepBuyingOptionClick}                     
                    >
                      {'Seguir comprando'}
                    </IonButton>
                </div>
                <div className="container-btn-return">
                  <IonButton 
                  className='return-button'
                  mode="ios"
                  onClick={() => this.props.onClick()}                     >
                    {'Ver mis pedidos'}
                  </IonButton>
                </div>
               
              
              </div>
          </div>
        </div>
      </IonContent>
    )
  }
}


export default withRouter(OrderConfirmedModal)