import React, { useEffect, useState } from 'react'
import ErrorList from '../input-form/ErrorList'
import { IoCheckmark, IoAlert, IoChevronDown } from "react-icons/io5";

import Select from 'react-select';
import './SearchInput.scss'

interface SearchInputProps {
  iconValid?: string;
  iconInvalid?: string;
  iconDefault?: string;
  isValid?: boolean;
  placeholder?: string;
  label?: string;
  error?: string;
  onChange: any;
  value: any;
  toValidate?: string | number | undefined;
  maxLength?: number
  type: string
  autoComplete?: string
  required?: boolean
  options: any[]
  isSearchable?: boolean
  arrow?: boolean
  defaultValue?: any
  isDisabled?: boolean
}

const SearchInput: React.FC<SearchInputProps> = props => {
  const {
    isValid,
    label, 
    error, 
    onChange, 
    iconValid,
    iconDefault,
    iconInvalid,
    value, 
    toValidate,
    options,
    placeholder,
    isSearchable,
    arrow,
    defaultValue,
    isDisabled
  } = props

  const [selectedValue, setSelectedValue] = useState<any>('')

  useEffect(() => {
    if (defaultValue) return setSelectedValue(defaultValue)    
    if (value) return setSelectedValue(value)
  }, [])

  const inputValidation = (isValid:boolean | undefined, value:string | number |undefined, toValidate:string | number | undefined) => {
    if(toValidate === "") return 'valid'
    if(value && isValid) return 'valid'
    return 'invalid'
  }

  const InputIcon = ({ isValid, value, toValidate, arrow }: { isValid?: boolean, value: any, toValidate: any, arrow?: boolean }) => {
    if(toValidate === "") return null
    if(value && isValid && !arrow) return <IoCheckmark color="blue" />
    if(arrow) return <IoChevronDown />
    return <IoAlert color="red" />
  }

  const onChangeValue = (selectedOption:any) => {
    setSelectedValue(selectedOption)
    onChange(selectedOption)
  }

  return (
    <div className="container-input-search-component">
      <div className="container-label-icon">
        {(iconDefault || iconInvalid || iconValid) &&
          <div className="icon-label"> 
            <InputIcon isValid={isValid} value={value} toValidate={toValidate} arrow={arrow} />
          </div>
        }
        <label className={`${inputValidation(isValid, value, toValidate)}`}>
          {label}
        </label>
      </div>
      <div className={`container-input-search ${!isValid ? 'error' : ''}`}>
        <Select 
          noOptionsMessage={() => 'Sin resultados'}
          onChange={onChangeValue}
          options={options}
          value={selectedValue}
          placeholder={placeholder}
          isSearchable={isSearchable}
          className={arrow ? 'arrow' : ''}
          isDisabled={isDisabled}
          defaultValue={defaultValue}
        />
        <div className="container-alert-icon">
          <InputIcon isValid={isValid} value={value} toValidate={toValidate} arrow={arrow} />
        </div>
      </div>
      {toValidate !== "" && !isValid && error && <ErrorList rule={error} />}
    </div>
  )
}

export default SearchInput