import React, { useState, useEffect } from 'react'
import { IonPage, IonContent, IonIcon } from '@ionic/react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { StaticContext } from 'react-router-dom'

// Models
import { Incident, IncidentContactInfo, IncidentEvidence, IncidentInfo } from '../../models/Incident'

// Assets
import arrowBack from './../../assets/icons/arrow-back-blue.svg'
import orderInfoExample from './../../assets/inc-img_tutorial.jpg'

// Components
import HeaderWithoutToolbar from '../../components/header-without-toolbar/HeaderWithoutToolbar'
import GuruHelper from '../../components/guru-helper/GuruHelper'
import ButtonComponent from '../../components/basic-button/ButtonComponent'
import AlertModal from '../../components/modals/AlertModal'
import TextInput from '../../components/input-form/TextInput'
import ImageUpload from '../../components/image-upload/ImageUpload'

// Utils
import { currentApp } from '../../utils/currentCountry'
import { getLocalStorageObject } from '../../utils/localstorageData'
import { newValidatePhone, validateEmail } from '../../utils/basetypes'
import { formatCurrency } from '../../utils/intl'
import { returnOptionsToUOM } from '../../utils/uomAndPackage'
import { namePayTitle } from '../../utils/orders'
import { parseToStandardFormat } from '../../utils/dates'

// Icons
import iconPhone from '../../assets/icons/act_phone.svg'
import iconWhatsapp from '../../assets/icons/act_whatsapp.svg'
import iconEmail from '../../assets/icons/icn_email_blue.svg'
import guruLogo from '../../assets/ag-logo.png'
import placeholderImage from '../../assets/icons/placeholder-image.svg'
import { add, remove } from 'ionicons/icons'

// CSS
import './OrderHelp.scss'

interface IPathParams {}

interface RouteState {
  option: any,
  order: any,
}

type Props = RouteComponentProps<IPathParams, StaticContext, RouteState> & {
  readonly orders: any
}

const OrderHelp: React.FC<Props> = (props) => {
  const { history } = props

  const [question, setQuestion] = useState<any>({})
  const [prevQuestions, setPrevQuestions] = useState<any>([])
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [guruHelpText, setGuruHelpText] = useState("")
  const [isModalOpen, setModalOpen] = useState(false)
  const [modalData, setModalData] = useState({
    modalTitle: "",
    text: "",
    buttonText: "",
    buttonAction: () => {},
    onDismiss: () => {},
    buttonMargin: "",
  })
  const [modifiedProducts, setModifiedProducts] = useState<any[]>([])
  const [evidenceData, setEvidenceData] = useState<IncidentEvidence>({
    productsWithIssues: [],
    comment: "",
    images: []
  })

  const [currentComponent, setCurrentComponent] = useState("")
  const [contactDataForm, setContactDataForm] = useState<IncidentContactInfo>({
    contact: "",
    contactType: "",
    otherPhone: "",
    otherEmail: "",
  })
  const [incidenceData, setIncidenceData] = useState<any[]>([])
  const [incidencePhaseIndex, setIncidencePhaseIndex] = useState<number>(0)
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false)

  const order = history?.location?.state?.order
  
  useEffect(() => {
    const question = history?.location?.state?.option

    if (!question || !order) history.goBack();
    const { title, description, helpText, type } = question
    setQuestion(question)
    setTitle(title)
    setDescription(description)
    setGuruHelpText(helpText) 
    setCurrentComponent(type)
    setModifiedProducts([ ...order?.products ])

    setNewIncidenceData(question)
  }, [])

  const renderIncidenceComponent = () => {
    const componentsByType = [
      { type: 'information-order', componentFunc: renderOrderInfo },
      { type: 'check-list', componentFunc: renderCheckList },
      { type: 'radio-list', componentFunc: renderRadioList },
      { type: 'comment', componentFunc: renderComment },
      { type: 'recover-coupon', componentFunc: renderRecoverCoupon },
      { type: 'full-review-order', componentFunc: renderFullReviewOrder },
      { type: 'edit-order', componentFunc: renderEditOrder },
      { type: 'review-order', componentFunc: renderReviewEditedOrder },
      { type: 'evidence', componentFunc: renderEvidenceComponent },
      { type: 'contact-form', componentFunc: renderContactForm },
      { type: 'contact-other', componentFunc: renderContactOther },
    ];
    
    const selectedTypeFunc = componentsByType.find(({ type }) => type === currentComponent)?.componentFunc

    if (!selectedTypeFunc) return <></>
    return selectedTypeFunc()
  }

  const setNewIncidenceData = (option: any) => {
    const { type, title } = option

    let value: any = ""
    if (type === 'check-list') value = []

    setIncidenceData([...incidenceData, { type, title, value } ])
  }

  const removeOneIncidenceData = () => {
    const incidenceDataCopy = [ ...incidenceData ]
    incidenceDataCopy.pop()
    setIncidenceData(incidenceDataCopy)
  }

  const renderOrderInfo = () => {
    return (
      <div>
        <div className="info-type">
          <img src={orderInfoExample} alt="info-example"/>
        </div>
        <div className="info-type-question-section">
          <div>
            <h5>¿Es la información que necesitas?</h5>
          </div>
          <div>
            <ButtonComponent
              text="No"
              className="btn-tertiary"
              onClick={() => { sendFeedback(false) }}
            />
            <ButtonComponent
              text="Sí"
              className="btn-tertiary"
              onClick={() => { sendFeedback(true) }}
            />
          </div>
        </div>
      </div>
    )
  }

  const renderCheckList = () => {
    const { sub } = question
    return (
      <div className="checklist-type">
        {Boolean(sub) && sub.map((checkOption: any, index: number) => {
          const { title } = checkOption
          const checked = checkIfOptionChecked(checkOption)

          return (
            <div 
              className="checklist-type-option"
              onClick={() => onChecklistChange(title, checked)}
              key={index}
            >
              <p className="paragraph-1 no-margin">
                {title}
              </p>
              <div className="checklist-type-checkbox-container">
                <input 
                  type="checkbox"
                  id={title} 
                  value={title} 
                  checked={checked}
                  className="checklist-type-checkbox"
                  onChange={() => {}}
                />
              </div>
            </div>
          )
        })}
        <div className="fixed-btn-section">
          <div className="fixed-btn-container">
            <ButtonComponent
              text="Continuar"
              className="btn-primary"
              disabled={!Boolean(incidenceData[incidencePhaseIndex]?.value?.length)}
              onClick={() => { continueIncidenceForm(question) }}
            />
          </div>
        </div>
      </div>
    )
  }

  const onChecklistChange = (checkTitle: string, wasAlreadyChecked: boolean) => {
    const incidenceDataCopy = [ ...incidenceData ]
    const currentValue = incidenceDataCopy[incidencePhaseIndex]?.value

    if (!wasAlreadyChecked) {
      incidenceDataCopy[incidencePhaseIndex]?.value.push(checkTitle)
    }
    
    if (wasAlreadyChecked) {
      incidenceDataCopy[incidencePhaseIndex].value = currentValue.filter((checkValue: string) => checkValue !== checkTitle)
    }

    setIncidenceData(incidenceDataCopy)
  }

  const renderRadioList = () => {
    const { sub } = question

    return (
      <div className="radiolist-type">
        {Boolean(sub) && sub.map((radioOption: any, index: number) => {
          const { title } = radioOption
          const checked = checkIfOptionChecked(radioOption)

          return (
            <div 
              className="radiolist-type-option" 
              onClick={() => onRadiolistChange(title, checked) } 
              key={index}
            >
              <p className="paragraph-1 no-margin">
                {title}
              </p>
              <div className="radiolist-type-option-container">
                <input
                  type="radio"
                  className="radiolist-type-radio"
                  id={title}
                  value={title}
                  checked={checked}
                  onChange={() => {}}
                />
              </div>
            </div>
          )
        })}

        <div className="fixed-btn-section">
          <div className="fixed-btn-container">
            <ButtonComponent
              text="Continuar"
              className="btn-primary"
              disabled={!Boolean(incidenceData[incidencePhaseIndex].value)}
              onClick={() => { continueIncidenceForm(question) }}
            />
          </div>
        </div>
      </div>  
    )
  }

  const onRadiolistChange = (radioTitle: string, wasAlreadyChecked: boolean) => {
    const incidenceDataCopy = [ ...incidenceData ]

    if (!wasAlreadyChecked) {
      incidenceDataCopy[incidencePhaseIndex].value = radioTitle
    }
    
    if (wasAlreadyChecked) {
      incidenceDataCopy[incidencePhaseIndex].value = ""
    }

    setIncidenceData(incidenceDataCopy)
  }

  const checkIfOptionChecked = (checkOption: any) => {
    return incidenceData[incidencePhaseIndex].value.includes(checkOption.title)
  }

  const continueIncidenceForm = (currentQuestion: any) => {
    const { sub, type } = currentQuestion
    setIncidencePhaseIndex((prevIndex) => prevIndex + 1)
    setPrevQuestions(() => [...prevQuestions, currentQuestion])

    if (type === 'radio-list') {
      const radioVal = incidenceData[incidencePhaseIndex].value
      const selectedOption = sub.find((option: any) => option.title === radioVal)
      const goToContact = selectedOption.type === 'option'

      if (!goToContact) {
        const { title, description, helpText, type } = selectedOption

        setNewIncidenceData(selectedOption)
        setQuestion(selectedOption)
        return changeCurrentComponent(
          type,
          title || "",
          description || "",
          helpText,
        )
      }
    } else if (type === 'edit-order') {
      const nextSubQuestion = sub[0]

      if (nextSubQuestion) {
        const { title, description, helpText, type } = nextSubQuestion

        setNewIncidenceData(nextSubQuestion)
        setQuestion(nextSubQuestion)
        return changeCurrentComponent(
          type,
          title || "",
          description || "",
          helpText,
        )
      }
    }

    changeCurrentComponent(
      "contact-form",
      "¿Cómo quieres que nos contactemos?",
      "",
      "Elige como prefieres que nos comuniquemos contigo",
    )
  }

  const renderComment = () => {
    return (
      <div className="comment-type">
        <textarea 
          className="comment-type-textarea"
          id="comment" 
          name="comment" 
          rows={4} 
          cols={50} 
          value={incidenceData[incidencePhaseIndex]?.value}
          onChange={(e) => { onCommentChange(e) }}
          placeholder="Escribe aquí"
        />

        <div className="fixed-btn-section">
          <div className="fixed-btn-container">
            <ButtonComponent
              text="Continuar"
              className="btn-primary"
              disabled={!Boolean(incidenceData[incidencePhaseIndex].value)}
              onClick={() => { continueIncidenceForm(question) }}
            />
          </div>
        </div>
      </div>
    )
  }

  const onCommentChange = (e: any) => {
    const inputVal = e.target.value
    const incidenceDataCopy = [ ...incidenceData ]
    incidenceDataCopy[incidencePhaseIndex].value = inputVal

    setIncidenceData(incidenceDataCopy)
  }

  const renderRecoverCoupon = () => {
    const { discounts } = order 
    if (!Boolean(discounts?.length)) return <h5>¡La orden seleccionada no tiene cupones incluídos!</h5>
    
    const coupon = discounts[0]
    const { amount, minimumAmount, code, image, date_expires } = coupon

    const imageSrc = image?.url ? `${process.env.REACT_APP_BFF_IMAGE}coupons/${image?.url}` : guruLogo
    const expirationDate = date_expires ? parseToStandardFormat(date_expires) : 'XX/XX/XXXX';

    return (
      <div className="rec-coupon-type">
        <div className="rec-coupon-type-card">
          <div>
            <img src={imageSrc} alt="coupon-image" className="rec-coupon-type-image"/>
          </div>
          <div className="rec-coupon-type-info-section">
            <h5 className="no-margin">${amount} de descuento</h5>
            <p className="paragraph-3 rec-coupon-type-subtitle">Compras sobre ${minimumAmount}</p>
            <p className="paragraph-3 no-margin">Código: {code}  Vence {expirationDate}</p>
          </div>
        </div>

        <div className="fixed-btn-section">
          <div className="fixed-btn-container">
            <ButtonComponent
              text="Confirmar"
              className="btn-primary"
              onClick={() => { onRecoverCouponConfirm(code) }}
            />
          </div>
        </div>
      </div>
    )
  }

  const onRecoverCouponConfirm = (couponCode: string) => {

    const incidenceDataCopy = [ ...incidenceData ]
    incidenceDataCopy[incidencePhaseIndex].value = couponCode

    setIncidenceData(incidenceDataCopy)

    continueIncidenceForm(question)
  }

  const renderFullReviewOrder = () => {

    const { products, finalPrice } = order

    return (
      <div className="full-order-type">

        <div className="full-order-type-card">
          <div className="full-order-type-card-header">
            <h5 className="no-margin">Productos</h5>
          </div>

          <div className="full-order-type-card-body">
            {products.map((product: any, index: number) => {
              const { quantity, imageFullPath, name, infoPrice } = product
              const productName = name.length > 18 ? `${name.slice(0, 19)}...` : name

              const uomProduct = findPairToShowValue(returnOptionsToUOM(), product.infoPrice.uom)
              const unitOfMeasure = uomProduct === "unidad" ? "unid."  : uomProduct
              const unitsText = `${infoPrice.units} ${unitOfMeasure} ${product.packageType ? `por ${product.packageType}` : ''} `

              return (
                <div className="full-order-type-product" key={index}>
                  <div className="full-order-type-product-qty">
                    <h1>{ quantity }</h1>
                  </div>
                  <div>
                    <img src={imageFullPath || placeholderImage} alt="" className="full-order-type-product-img"/>
                  </div>
                  <div className="full-order-type-product-info">
                    <p className="paragraph-3 no-margin">{ productName }</p>
                    <p className='paragraph-3 no-margin'>{unitsText}</p>
                    {Boolean(infoPrice?.totalPrice) &&
                      <h3 className="full-order-type-product-price">
                        {formatCurrency(infoPrice.totalPrice)}
                      </h3>
                    }
                  </div>
                </div>
              )
            })}
          </div>

          <div className="full-order-type-card-footer-flex">
            <h6 className="no-margin">Total</h6>
            <h6 className="no-margin">{formatCurrency(finalPrice)}</h6>
          </div>
        </div>

        <div className="full-order-type-spacer" />

        {renderOrderSummary(order)}
        
        <div className="full-order-type-spacer" />

        <div className="fixed-btn-section">
          <div className="fixed-btn-container">
            <ButtonComponent
              text="Confirmar"
              className="btn-primary"
              onClick={() => onFullOrderReviewConfirm() }
            />
          </div>
        </div>
      </div>
    )
  }

  const onFullOrderReviewConfirm = () => {
    const incidenceDataCopy = [ ...incidenceData ]
    incidenceDataCopy[incidencePhaseIndex].value = ""

    setIncidenceData(incidenceDataCopy)

    continueIncidenceForm(question)
  }

  const renderEditOrder = () => {
    if (!modifiedProducts?.length) return ""
    const isEditContinueDisabled: boolean = isEditConfirmDisabled()

    return (
      <div className="edit-order-type">
        <div className="edit-order-type-card">
          <div className="edit-order-type-card-header">
            <h5 className="no-margin">Productos</h5>
          </div>

          <div className="edit-order-type-card-body">
            {modifiedProducts.map((product: any, index: number) => {
              const { quantity, imageFullPath, name, infoPrice } = product
              const productName = name.length > 18 ? `${name.slice(0, 19)}...` : name

              const uomProduct = findPairToShowValue(returnOptionsToUOM(), product.infoPrice.uom)

              const unitOfMeasure = uomProduct === "unidad" ? "unid."  : uomProduct
              const unitsText = `${infoPrice.units} ${unitOfMeasure} ${product.packageType ? `por ${product.packageType}` : ''} `

              return (
                <div className="edit-order-type-product" key={index}>

                  <div className="edit-order-type-product-qty">
                    <h1>{ quantity }</h1>
                  </div>
                  
                  <div>
                    <img src={imageFullPath || placeholderImage} alt="" className="edit-order-type-product-img"/>
                  </div>
                  
                  <div className="edit-order-type-product-info">
                    <p className="paragraph-3 no-margin">{productName}</p>
                    <p className='paragraph-3 no-margin'>{unitsText}</p>
                    {Boolean(infoPrice?.unitPrice) &&
                      <h3 className="edit-order-type-product-price">
                        {formatCurrency(infoPrice.unitPrice)}
                      </h3>
                    }
                  </div>

                  <div className="edit-order-type-buttons">
                    <div className="edit-order-type-buttons-updown">
                      <IonIcon 
                        onClick={() => onProductEdit(product, quantity + 1)}
                        className="edit-order-type-buttons-icon"
                        icon={add}                       />
                    </div>

                    <div className="edit-order-type-spacer" />

                    <div className="edit-order-type-buttons-updown">
                      <IonIcon 
                        onClick={() => onProductEdit(product, quantity + -1)}
                        className="edit-order-type-buttons-icon"
                        icon={remove}                       />
                    </div>
                  </div>
                
                </div>
              )
            })}
          </div>

          <div className="edit-order-type-card-footer-flex">
            <h6 className="no-margin">Total</h6>
            <h6 className="no-margin">{calcProductsTotal(modifiedProducts)}</h6>
          </div>
        </div>
      
        <div className="fixed-btn-section">
          <div className="fixed-btn-container">
            <ButtonComponent
              text="Continuar"
              className="btn-primary"
              disabled={isEditContinueDisabled}
              onClick={() => onEditOrderContinue() }
            />
          </div>
        </div>
      </div>
    )
  }

  const onProductEdit = (modifiedProduct: any, quantity: number) => {
    if (quantity < 0) return

    const newProductsArray: any[] = modifiedProducts.map((product: any) => {
      if (product.sku === modifiedProduct.sku && product.name === modifiedProduct.name) {
        product.quantity = quantity
      }
      return product
    })
    setModifiedProducts(newProductsArray)
  }
  
  const calcProductsTotal = (products: any[]) => {
    const total = products.reduce((accumulator, product) => {
      return accumulator + (product.price * product.quantity)
    }, 0)
    
    return formatCurrency(total)
  }

  const isEditConfirmDisabled = () => {
    const isEnabled = order.products.some((product: any, index: number) => (
      product.infoPrice.quantity !== modifiedProducts[index].quantity
    ))

    return !isEnabled
  }

  const onEditOrderContinue = () => {
    const incidenceDataCopy = [ ...incidenceData ]
    incidenceDataCopy[incidencePhaseIndex].value = modifiedProducts

    setIncidenceData(incidenceDataCopy)

    continueIncidenceForm(question)
  }

  const findPairToShowValue = (options:any, name: string) => options[name]

  const renderReviewEditedOrder = () => {
    const draftOrder = { ...order, products: modifiedProducts }
    const oldPrice = order.finalPrice
    draftOrder.finalPrice = calcProductsTotal(draftOrder.products)

    return (
      <div className="review-order-type">
        {renderOrderSummary(draftOrder, oldPrice)}

        <div className="review-order-type-back-btn">
          <ButtonComponent
            text="Volver a editar"
            className="btn-tertiary"
            onClick={() => headerGoBack() }
          />
        </div>

        <div className="fixed-btn-section">
          <div className="fixed-btn-container">
            <ButtonComponent
              text="Confirmar"
              className="btn-primary"
              onClick={() => continueIncidenceForm(question) }
            />
          </div>
        </div>
      </div>
    )
  }

  const renderOrderSummary = (order: any, oldPrice?: number) => {
    const { finalPrice, discounts } = order
    const infoVendor = order?.provider || {}
    const total = typeof finalPrice === 'number' ? formatCurrency(finalPrice) : finalPrice

    return (
      <div className="full-order-type-card">

        <div className="full-order-type-card-header">
          <h5 className="no-margin">Resumen</h5>
        </div>

        <div className="full-order-type-card-body">
          <h6 className="full-order-type-dark-title">{namePayTitle(order)}</h6>

          <div className="full-order-type-info-container">
            <p className="paragraph-2 no-margin">{infoVendor.name || "Proveedor"}</p>
            <p className="paragraph-2 no-margin">{total}</p>
          </div>

          <div className="full-order-type-info-container">
            {infoVendor?.deliveryData?.default?.days
              ? <p className="paragraph-2 no-margin"> Entrega {infoVendor.deliveryData.default.days} días</p>
              : <></>
            }
            {oldPrice 
              ? <p className="paragraph-2 no-margin old-gray-price">
                  {typeof oldPrice === 'number' ? formatCurrency(oldPrice) : oldPrice}
                </p> 
              : ''}
          </div>


          <h5 className="full-order-type-margined-title">Descuentos</h5>
        </div>

        <div className="full-order-type-card-footer">
          <div className="full-order-type-discounts">
            {discounts.length > 0 ? 
              order.discounts.map((discount: any) =>{
                return (
                  discount.discountType !== "pay-promo" && <p className='paragraph-2'>{ formatCurrency(discount.realDiscount)}</p>
                )
              })
            :  
              <div className="full-order-type-info-container"> 
                <p className="paragraph-2 no-margin">---</p>
                <p className="paragraph-2 no-margin">0</p>
              </div>
            }
          </div>

          <div className="full-order-type-info-container">
            <h5 className="no-margin">TOTAL</h5>
            <h5 className="no-margin">{total}</h5>
          </div>
        </div>

      </div>

    )
  }

  const renderEvidenceComponent = () => {
    const { products } = order
    const isConfirmDisabled: boolean = !isEvidenceReady()

    return (
      <div className="evidence-type">
        <div className="evidence-type-card">
          
          <div className="evidence-type-card-header">
            <h5 className="no-margin">Productos</h5>
          </div>

          {products.map((product: any, index: any) => {
            const { imageFullPath, name, infoPrice, sku } = product
            const productName = name.length > 18 ? `${name.slice(0, 19)}...` : name

            const uomProduct = findPairToShowValue(returnOptionsToUOM(), product.infoPrice.uom)
            const unitOfMeasure = uomProduct === "unidad" ? "unid."  : uomProduct
            const unitsText = `${infoPrice.units} ${unitOfMeasure} ${product.packageType ? `por ${product.packageType}` : ''} `

            const checked = evidenceData.productsWithIssues.some((productWithIssue: any) => productWithIssue.sku === sku)

            return (
              <div className="evidence-type-card-section" key={index}>

                <div className="evidence-type-product-checkbox-container">
                  <input 
                    type="checkbox"
                    id={title} 
                    value={title} 
                    checked={checked}
                    className="evidence-type-product-checkbox"
                    onChange={(e) => onEvidenceProductsChange(e.target.checked, product) }
                  />
                </div>
                <div>
                  <img src={imageFullPath || placeholderImage} alt="" className="evidence-type-product-img"/>
                </div>
                <div className="evidence-type-product-info">
                  <p className="paragraph-3 no-margin">{ productName }</p>
                  <p className='paragraph-3 no-margin'>{unitsText}</p>
                  {Boolean(infoPrice?.unitPrice) &&
                    <h3 className="evidence-type-product-price">
                      {formatCurrency(infoPrice?.unitPrice)}
                    </h3>
                  }
                </div>
                  
              </div>
            )
          })}

        </div>

        <div className="evidence-type-spacer" />
        
        <textarea 
          className="comment-type-textarea"
          id="comment" 
          name="comment" 
          rows={4} 
          cols={50} 
          value={evidenceData?.comment}
          onChange={(e) => onEvidenceChange('comment', e.target.value) }
          placeholder="Escribe aquí"
        />

        <div className="evidence-type-spacer" />

        <h4 className="evidence-type-photos-title">Agrega fotos de tu factura</h4>

        <ImageUpload onChange={(images) => onEvidenceImagesChange(images) }/>

        <div className="fixed-btn-section">
          <div className="fixed-btn-container">
            <ButtonComponent
              text="Enviar"
              className="btn-primary"
              disabled={isConfirmDisabled}
              onClick={() => onEvidenceConfirm() }
            />
          </div>
        </div>
      
      </div>
    )
  }

  const onEvidenceProductsChange = (checked: boolean, product: any) => {
    const { sku, name } = product
    const { productsWithIssues } = evidenceData
    let newProductsList = productsWithIssues
    
    if (checked) newProductsList.push(product)
    if (!checked) newProductsList = newProductsList.filter((productWithIssue: any) => (
      productWithIssue.sku !== sku && productWithIssue.name !== name
    ))

    onEvidenceChange('productsWithIssues', newProductsList)
  }

  const onEvidenceImagesChange = (images: string[]) => {
    if (images && Array.isArray(images)) onEvidenceChange('images', images)
  }

  const onEvidenceChange = (field: string, value: any) => {
    setEvidenceData((prevEvidence: any) => {
      const newEvidenceObj = { ...prevEvidence, [field]: value }
      return newEvidenceObj
    })
  }

  const isEvidenceReady = () => {
    const { productsWithIssues, comment, images } = evidenceData
    return Boolean(productsWithIssues?.length && comment && images?.length)
  }

  const onEvidenceConfirm = () => {
    const incidenceDataCopy = [ ...incidenceData ]
    incidenceDataCopy[incidencePhaseIndex].value = evidenceData

    setIncidenceData(incidenceDataCopy)
    continueIncidenceForm(question)
  }

  const renderContactForm = () => {
    const userInfo = getLocalStorageObject("@user")
    const countryInfo = getLocalStorageObject("countryName")
    if (!userInfo) return <></>

    const phonePrefix = countryInfo?.countryCode ? getCountryPhonePrefix(countryInfo?.countryCode) : ""
    const contactMethod = contactDataForm.contactType

    const contactOptions = [
      { value: "phone", text: "Teléfono", info: `${phonePrefix} ${userInfo.phone}`, icon: iconPhone, checked: contactMethod === "phone" },
      { value: "whatsapp", text: "Whatsapp", info: `${phonePrefix} ${userInfo.phone}`, icon: iconWhatsapp, checked: contactMethod === "whatsapp" },
      { value: "email", text: "Email", info: userInfo.email, icon: iconEmail, checked: contactMethod === "email" },
    ]

    return (
      <div className="contact-type">
        <div>
          {contactOptions.map(({ value, text, info, icon, checked }, i) => (
            <div
              key={i}
              className="contact-type-option-card" 
              style={{ border: checked ? "1px solid #1E3799" : "none" }} 
              onClick={() => { 
                setContactDataForm({
                  ...contactDataForm,
                  contact: checked ? "" : info,
                  contactType: checked ? "" : value,
                })
              }}
            >

              <div className="contact-type-info-section">
                <div>
                  <img src={icon} alt="icon-contact" className="contact-type-icon"/>
                </div>
                <div>
                  <p className="paragraph-2 no-margin">{text}</p>
                  <p className="paragraph-2 no-margin">{info}</p>
                </div>
              </div>

              <div className="contact-type-radio-container">
                <input
                  type="radio"
                  id={value}
                  value={value}
                  checked={checked}
                  onChange={() => {}}
                />
              </div>
            </div>
          ))}
        </div>
      
        <div className="contact-type-inferior-section">
          <div>
            <p className="paragraph-2">
              Si los medios actuales no funcionan, deja otro teléfono o email.
            </p>
          </div>
          <div>
            <ButtonComponent
              text="Otro teléfono o email"
              className="btn-tertiary"
              onClick={() => {
                setPrevQuestions(() => [...prevQuestions, { type: "contact-form" }])
                changeCurrentComponent(
                  "contact-other",
                  "",
                  "Puedes enviar un número de teléfono, un email o ambos. Tú eliges.",
                  "Elige como prefieres que nos comuniquemos contigo",
                )
              }}
            />
          </div>
        </div>
      
        <div className="fixed-btn-section">
          <div className="fixed-btn-container">
            <ButtonComponent
              text="Enviar"
              className={`btn-primary ${disableSubmit ? 'loading' : ''}`}
              disabled={!Boolean(contactDataForm.contact) || disableSubmit}
              onClick={submitIncidence}
            />
          </div>
        </div>
      </div>
    )
  }

  const renderContactOther = () => {
    const { otherEmail, otherPhone }: any = contactDataForm
    const isContactValid = !(newValidatePhone(otherPhone || "") || !!validateEmail(otherEmail || ""));

    return (
      <div className="contact-other-type">
        <div className="contact-other-type-field">
          <TextInput
            onChange={(e: any) => { changeContactForm("otherPhone", e.target.value) } } 
            placeholder={"Ej: 9 1234 5678"}
            label="Teléfono"
            error={"Escribe tu número con el código de área"}
            value={otherPhone || ""}
            type="tel"
            isValid={newValidatePhone(otherPhone || "")}
            toValidate={otherPhone || ""}
            maxLength={9}
            autoComplete="off"
            iconDefault="icn_phone" 
            iconValid="icn_phone-blue"
            iconInvalid="icn_phone-red"
          />
        </div>

        <div className="contact-other-type-field">
          <TextInput 
            onChange={(e: any) => { changeContactForm("otherEmail", e.target.value) }} 
            placeholder="Ej: contacto@email.com"
            label="Email"  
            isValid={!!validateEmail(otherEmail || "")}
            error={"Tu email debe ser válido para recibir mensajes"}
            value={otherEmail || ""}
            type="email"
            toValidate={otherEmail || ""}
            autoComplete="off"
            iconDefault="icn_email" 
            iconValid="icn_email-blue"
            iconInvalid="icn_email-red"
          />
        </div>

        <div className="fixed-btn-section">
          <div className="fixed-btn-container">
            <ButtonComponent
              text="Enviar"
              className={`btn-primary ${disableSubmit ? 'loading' : ''}`}
              disabled={isContactValid || disableSubmit}
              onClick={submitIncidence}
            />
          </div>
        </div>
      </div>
    )
  }

  const sendFeedback = (feedback: boolean) => {
    if (feedback) {
      setModalOpen(true)
      setModalData({
        modalTitle: "¡Gracias por responder!",
        text: "Nos alegra saber que somos de ayuda para ti.",
        buttonText: "Volver",
        buttonAction: () => { history.push('/order', order) },
        onDismiss: () => { history.push('/order', order) },
        buttonMargin: "15px",
      })
      return;
    }

    continueIncidenceForm(question)
    changeCurrentComponent(
      "contact-form",
      "¿Cómo quieres que nos contactemos?",
      "",
      "Elige como prefieres que nos comuniquemos contigo",
    )
  }

  const changeContactForm = (key: string, value: any) => setContactDataForm({...contactDataForm, [key]: value })

  const getCountryPhonePrefix = (countryCode: string) => {
    return currentApp()?.find(countryInfo => countryInfo.countryCode === countryCode)?.prefixNumber || ""
  }

  const changeCurrentComponent = (newComponent: string, title?: string, description?: string, guruHelpText?: string) => {
    setCurrentComponent(newComponent)
    if (title !== undefined) setTitle(title)
    if (description !== undefined) setDescription(description)
    if (guruHelpText !== undefined) setGuruHelpText(guruHelpText)
  }

  const submitIncidence = async () => {
    setDisableSubmit(true)
    const { productsWithIssues, comment, images } = evidenceData
    const { orderNumber, provider } = order

    let incidentInfo: IncidentInfo[] = [...incidenceData].filter(({ type }: IncidentInfo) => type !== 'evidence')    
    const userIncidentData: Incident = {
      orderId: order?._id,
      contactInfo: contactDataForm,
      incidentInfo,
      vendorName: provider?.name,
      orderNumber,
    }


    if (productsWithIssues?.length && comment && images.length) {
      userIncidentData.incidentEvidence = evidenceData
    }

    let modalData = {
      modalTitle: "¡Gracias!",
      text: "Recibimos tu solicitud y te contactaremos dentro de las próximas 48 horas hábiles.",
      buttonText: "Ok",
      buttonAction: () => { history.push('/order', order) },
      onDismiss: () => { history.push('/order', order) },
      buttonMargin: "-20px",
    }

    try {
      const incidentRes = await props.orders.createIncident(userIncidentData)
      if (!incidentRes) throw Error('Error al crear user incident.')

    } catch (error) {
      modalData = {
        ...modalData,
        modalTitle: "¡Ups!",
        text: "Ha ocurrido un problema al generar la solicitud, inténtalo nuevamente.",
      }

      console.error(error)
    }
    
    setModalData(modalData)
    setModalOpen(true)
  }

  const checkClearForm = () => {
    if (currentComponent === 'evidence') {
      setEvidenceData({ productsWithIssues: [], comment: "", images: [] })
    }
  }

  const headerGoBack = () => {
    if (prevQuestions.length) {
      const prevQuestion = prevQuestions[prevQuestions.length - 1]
      const { title, description, helpText, type } = prevQuestion
      setQuestion(prevQuestion)
      changeCurrentComponent(
        type,
        title || "",
        description || "",
        helpText,
      )
      const prevQuestionsCopy = [ ...prevQuestions ]
      prevQuestionsCopy.pop()
      setPrevQuestions(() => prevQuestionsCopy)
      
      if(currentComponent !== 'contact-other') {
        setIncidencePhaseIndex((prevIndex) => prevIndex - 1)
      }
      if (currentComponent !== 'contact-form' && currentComponent !== 'contact-other') {
        removeOneIncidenceData()
      }

      checkClearForm()

    } else {
      return history.goBack()
    }
  }

  if (!question || !order) return <></>

  const { orderNumber, provider } = order
  const isCentered = ["contact-form", "contact-other"].includes(currentComponent)
  const { modalTitle, text, buttonText, buttonAction, onDismiss, buttonMargin } = modalData

  return (
    <IonPage className="ds-content-page order-help-page" >
      <div className="header-wrapper">
        <HeaderWithoutToolbar 
          icon={arrowBack}
          text="Ayuda"
          onClick={headerGoBack}
        />
      </div>
      <IonContent >
        {orderNumber && provider
          ? <div className="order-info">
              <h4 className="order-info-title">{`${orderNumber} - ${provider?.name}`}</h4>
            </div>
          : ""
        }
        <div>
          <GuruHelper text={guruHelpText} marginTop="30px" marginBottom="30px" />
        </div>
        <div className="order-help-content">
          {Boolean(title) && 
            <h4 
              className="order-help-title" 
              style={{ textAlign: isCentered ? "center": "left" }}
            >
              {title}
            </h4>
          }
          {Boolean(description) && 
            <p 
              className="paragraph-2" 
              style={{ textAlign: isCentered ? "center": "left" }}
            >
              {description}
            </p>
          }

          {renderIncidenceComponent()}
        </div>
      </IonContent>
      <AlertModal
        isOpen={isModalOpen}
        label={modalTitle}
        text={text}
        buttonText={buttonText}
        buttonAction={buttonAction}
        onDismiss={onDismiss}
        buttonMargin={buttonMargin}
      />
      <style>
        {`
          .footer-menu-home {
            display: none !important;
          }
        `}
      </style>
    </IonPage>
  )
}

export default withRouter(OrderHelp)
