import React, { useRef, useEffect } from 'react';
import { getLogo } from '../../utils/basetypes'
import { ProviderCard } from './ProviderCard';
import { VisibilityTracker } from '../../hoc';
import { registerHomeSellerButtonEvent } from '../../pages/firebase/firebaseTags';
import './ProvidersCardsRenderer.scss'

interface ProvidersCardsRendererProps {
  providers: any
  source: string
  onProviderClick: (item:any, categoryId?:any, vendorId?:any, oneVendor?: boolean, vendorPosition?: any) => void
  providersRibbons?: any
  placementName?: string
}

enum SourceOptions {
  Home = "home",
  Category = "category",
}

export const ProvidersCardsRenderer: React.FC<ProvidersCardsRendererProps> = (props) => {
  const { providers, source, onProviderClick, providersRibbons, placementName } = props

  const isReadyRef = useRef(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      isReadyRef.current = true;
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const switchUseOfFunction = (item: any, index: number) => {
    switch (source) {
      case SourceOptions.Home:
        onProviderClick(item, null, null, false, index + 1) 
        break;
      case SourceOptions.Category:
        onProviderClick(item, index + 1)
        break;
      default:
        break;
    }
  }

  const checkProviderRibbon = (provider: any) => {
    if (!providersRibbons || !Object.keys(providersRibbons).length) return null
    const currentCommuneLocal = localStorage.getItem('currentOffice') 
    const currentCommune = currentCommuneLocal ? JSON.parse(currentCommuneLocal) : null
    const ribbon = providersRibbons[provider.id]?.ribbon
    const ribbonByComunes = providersRibbons[provider.id]?.ribbonByComunes

    let selectedRibbon = ribbon
    if (ribbonByComunes?.comunesIds.includes(currentCommune?.comuneId)) selectedRibbon = ribbonByComunes

    return selectedRibbon || null
  }

  const handleOnView = (provider) => {
    if (!isReadyRef.current) return;

    const placement = placementName ?? 'not-defined'

    registerHomeSellerButtonEvent({
        eventName: 'home_seller_button_view',
        sellerId: provider.id,
        sellerName: provider.name,
        placementName: placement,
    })
  }

  const handleOnClick = (provider: any, index: number) => {
    const placement = placementName ?? 'not-defined'

    registerHomeSellerButtonEvent({
        eventName: 'home_seller_button_click',
        sellerId: provider.id,
        sellerName: provider.name,
        placementName: placement,
    })
    switchUseOfFunction(provider, index)
  }

  const renderProvidersCards = (item: any, index: number) => {
    if(item.disponible !== true) return <></>

    const providerRibbon = checkProviderRibbon(item)

    if (item.name === "Gurú Despacho") return

    const imageUrl = `${process.env.REACT_APP_BFF_IMAGE}providers/${getLogo(item.logo, 'x2')}`

    return (
        <VisibilityTracker key={item?._id ?? index} onVisible={() => handleOnView(item)} threshold={0.5}>
            <ProviderCard 
                index={index} 
                provider={item} 
                imageUrl={imageUrl} 
                providerRibbon={providerRibbon} 
                onClick={() => handleOnClick(item, index)}
            />
        </VisibilityTracker>
    )
  }

  if(!providers) return <></>
  
  return (
    <div className="providers-cards-container ds-content-page">
      {providers.map((item:any, index: number) => renderProvidersCards(item, index) )}
    </div>
  )
};

