import React, { Fragment, useEffect, useState } from 'react'
import {
  IonContent,
  IonPage,
} from '@ionic/react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import track from 'react-tracking'
import arrowBack from './../../assets/icons/arrow-back-blue.svg'
import HeaderWithoutToolbar from '../../components/header-without-toolbar/HeaderWithoutToolbar'
import GuruHelper from '../../components/guru-helper/GuruHelper'
import ButtonComponent from '../../components/basic-button/ButtonComponent'
import TextInput from '../../components/input-form/TextInput'
import { validateEmail } from '../../utils/basetypes'
import { StaticContext } from 'react-router-dom'
import GuruAcceptModal from '../../components/modal-action-accept/ModalActionAccept'
import logo from './../../assets/logo.png'
import { countVendorsByComune, getComunes, requestComune } from '../../clients/extraRequest'
import SearchInput from '../../components/search-input/SearchInput'
import { registerUserInvited } from '../../clients/register'
import UserModel from './../../models/User'
import './UserInvited.scss'
import LoadingCoverUserGuest from '../../components/loading-cover/LoadingCoverGuestUser'
import { eventWithName, selectGuestComune } from '../firebase/firebaseTags'
import { countryCl, countryMx, countryPe } from '../../utils/countriesTexts'
import { capitalizeFirstChar } from '../../utils/strings'

interface IPathParams {} 

type IProps = RouteComponentProps<IPathParams, StaticContext> & {
  user: UserModel
}

const UserInvited: React.FC<IProps> = props => {
  const { history } = props
  const [userData, setUserData] = useState<any>({username:"", email:"", comune:""})
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [shouldShowInputs, setShouldShowInputs] = useState<boolean>(false)
  const [isValidComune, setIsValidComune] = useState<boolean>(false)
  const [comuneOptions, setComuneOptions] = useState<any>([])
  const [countryData, setCountryData] = useState<any>({})
  const [registerResultData, setRegisterResultData] = useState<any>({})
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isValidToShowButton, setIsValidToShowButton] = useState<boolean>(false)
  const loadingPhrase = ['Cargando proveedores', 'Cargando productos', 'Cargando categorías']

  const saveToFirebase = () => eventWithName('view_guest_user')

  useEffect(() => {
    getCountry()
    saveToFirebase()
  }, [])

  useEffect(() => {
    if(countryData.countryCode) getComunesData()
  }, [countryData])

  useEffect(() => {
    redirectRegister()
  }, [registerResultData])

  const getCountry = () => {
    const country = localStorage.getItem('countryName')
    const parsedCountry = country ? JSON.parse(country) : {}
    setCountryData(parsedCountry)
  }

  const redirectRegister = async () => {
    if(Object.keys(registerResultData).length !== 0) await redirectHomeAfterRegister()
  } 

  const getComunesData = async () => {
    const comuneOptions = await getComunes(countryData.countryCode,userData.comune)

    setComuneOptions(comuneOptions.map((comuneOption:any) =>  { 
      return {value: comuneOption.name, label: comuneOption.name, id: comuneOption._id }
    }))
  }

  const renderGuruHelper = () => (
    <GuruHelper 
      label=""
      text={`Entérate cuando estemos en tu ${countryData.countryCode === 'CL' ? 'comuna' : 'provincia'} dejando tus datos de contacto`}
    />
  )

  const handleChange = (from:string,e: any) => {
    const { value } = e.target;

    setUserData((prevState:any) => ({
      ...prevState,
      [from]: value
    }));
  };

  const handleChangeSelect = async (from:string, value: any) => {
    setIsValidComune(false)

    setUserData((prevState:any) => ({
      ...prevState,
      [from]: value.label,
      comuneId: value.id
    }));

    setIsValidToShowButton(true)
    setShouldShowInputs(false) 
    
  };

  const onConfirmationComune = async () => {
    const validateVendorsByComune = await countVendorsByComune(userData.comuneId)
    selectGuestComune(userData.comune)

    if (validateVendorsByComune.count < 1) { 
      setShouldShowInputs(true) 
      setIsValidComune(true)
      setIsValidToShowButton(false)
    } else {
      setShouldShowInputs(false)
      setIsValidComune(false)
    }


    if(validateVendorsByComune.count >= 1) {
      const lastToken = localStorage.getItem('@last_token_access')
      if(lastToken) {
        setIsLoading(true)
        setRegisterResultData(await registerUserInvited(userData.comune, countryData.countryCode, lastToken))
      } else {
        setIsLoading(true)
        setRegisterResultData(await registerUserInvited(userData.comune, countryData.countryCode))
      }
    }
  }

  const redirectHomeAfterRegister = async () => {
    const { user, history } = props
    
    try {
      await user.authenticateWith(registerResultData)
      const userData = {...registerResultData, 'welcomeGuru':false, 'isGuestUser': true}
      setTimeout(() => {
        history.push('/home', userData )
      }, 2490)
      
    } catch (error) {
      console.error(error)
    }
  }

  const onClickSendForm = async () => {
    await requestComune(userData.username, userData.email, userData.comune)
    setIsOpenModal(true)
  }

  const renderInputFields = () => (
    <Fragment>
      {renderGuruHelper()}
      <div className="container-input-invited-user">
        <div className="input-container-invited">
          <TextInput 
            iconDefault="nav_account" 
            iconValid="nav_account-blue"
            onChange={(e: any) => handleChange('username', e)} 
            placeholder="Ej: María"
            label="Nombre"  
            isValid={!!userData.username}
            error={"El nombre debe tener al menos un dígito"}
            value={userData.username}
            type="text"
            toValidate={userData.username}
            autoComplete="off"
          />
        </div>
        <div className="input-container-invited">
          <TextInput 
            iconDefault="icn_email" 
            iconValid="icn_email-blue"
            iconInvalid="icn_email-red"
            onChange={(e: any) => handleChange('email', e)} 
            placeholder="Ej: maria@email.com"
            label="Correo"  
            isValid={!!validateEmail(userData.email)}
            error={"Tu email debe ser válido para recibir mensajes"}
            value={userData.email}
            type="email"
            toValidate={userData.email}
            autoComplete="off"
          />
        </div>
        <div className="container-btn-user-invited">
          <ButtonComponent 
            className={`btn-primary ${isLoading ? 'loading' : ''}`}
            onClick={onClickSendForm}
            text="Enviar"
            disabled={!userData.email || !userData.username || !validateEmail(userData.email)}
          />
        </div>
      </div>
    </Fragment>
  ) 

  const onClickAcceptModal = () => history.push('/')
  
  const searchComune = () => {
    const inputPlaceholder = placeholderByCountry(countryData.countryCode)
    const territoryName = territoryByCountry(countryData.countryCode)

    return (
      <Fragment>
        <div className="container-search-comune-text">
          { !shouldShowInputs &&
            <>
              <p>Conoce las mejores ofertas en productos y proveedores que trabajan en tu {territoryName}.</p>
              <p>¿En qué {territoryName} te encuentras?</p>
            </>
          }
          <div className='input-comuna-container'>
            <SearchInput
              onChange={(valueSelected: any) => handleChangeSelect('comune', valueSelected)} 
              placeholder={inputPlaceholder}
              isValid={!isValidComune}
              error={`${capitalizeFirstChar(territoryName)} fuera del área de servicio`}
              value={userData.comune}
              type="text"
              toValidate={userData.comune}
              autoComplete="off"
              options={comuneOptions}
            />
          </div>

          {isValidToShowButton &&
            <div className="btn-confirmation-comune"> 
              <ButtonComponent 
                text="Confirmar"
                className="btn-primary"
                onClick={onConfirmationComune}
              />
            </div>
          }
        </div>
      </Fragment>
    )
  }

  const territoryByCountry = (countryCode: string) => {
    const territoriesNames: any = {
      [countryCl]: 'comuna',
      [countryPe]: 'provincia',
      [countryMx]: 'colonia',
    }

    return territoriesNames[countryCode] || 'comuna'
  }

  const placeholderByCountry = (countryCode: string) => {
    const placeholders: any = {
      [countryCl]: 'Ej: Santiago Centro ',
      [countryPe]: 'Ej: Huarorichí',
      [countryMx]: 'Ej: Del Carmen',
    }

    return placeholders[countryCode] || 'Ingresar texto'
  }

  const territoryName = territoryByCountry(countryData.countryCode)

  return (
    <IonPage className="user-invited-page" >
      <IonContent className="container-validate-comune" >
        <HeaderWithoutToolbar 
          icon={arrowBack}
          text={`Elige tu ${territoryName}`}
          onClick={history.goBack}
        />
        {searchComune()}
        {shouldShowInputs && renderInputFields()}
        <GuruAcceptModal
          className="btn-primary"
          textButton="Volver al inicio"
          label="¡Gracias por dejarnos tu contacto!"
          secondLabel={`Pronto estaremos en tu ${territoryName}`}
          onClickButton={onClickAcceptModal}
          disabled={false}
          isOpen={isOpenModal}
          imgToShow={logo}
        />
      </IonContent>
      <LoadingCoverUserGuest 
      isLoading={isLoading}
      title={"¡Bienvenido a Almacén Gurú!"}
      phrase={loadingPhrase}
      />
    </IonPage>
  )
}

export default track({ page: '/validate-comune' })(withRouter(UserInvited))
