import React from 'react'
import {
  IonIcon,
  IonModal,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonRippleEffect,
  IonText,
  IonRadio,
  IonSpinner,
} from '@ionic/react'
import { close } from 'ionicons/icons'
import './ProductModal.scss'
import { formatCurrency } from '../../utils/intl'
import track from 'react-tracking'
import { NumberUpDown } from '../number-updown/NumberUpDown'
import { Product, packageTypeAsText, uomTypeAsText } from '../../models/ProductModel'
import { totalPrice } from '../../clients/product'
import { carouselItem} from '../../pages/firebase/firebaseTags'

interface IProps {
  product: Product
  isNewProduct?: boolean
  isOpen: boolean
  initialQuantity?: number
  tracking?: any
  onAccept: (product: Product, quantity: number) => void
  onDismiss: () => void
}

interface IState {
  quantity: number
  total: any
  spinner: boolean
}

class ProductModal extends React.PureComponent<IProps, IState> {
  state: IState = {
    quantity: this.props.initialQuantity || 1,
    total: '',
    spinner: false,
  }

  async componentDidMount() {
    const { quantity } = this.state
    await this.calculatingTotal(quantity)
    // TODO: Por el momento queda comentado - carouselItem('view_content', this.props.product.id, this.props.product.name, this.props.product.category) 
  }

  private onAcceptModal = async () => {
    const { onAccept } = this.props
    await onAccept(this.props.product, this.state.quantity)
  }

  private onDismissModal = async () => {
    const { onDismiss } = this.props
    await onDismiss()
  }

  private setQuantity = async (quantity: number) => {
    this.setState({ quantity })

    this.setState({
      spinner: true,
    })
    await this.calculatingTotal(quantity)
  }

  private renderDiscountLadder = () => {
    const { product } = this.props
    const { quantity, total } = this.state
    const { discount, packageType } = product
    if (!discount || typeof discount === 'number') {
      return undefined
    }
    const fullFilled = discount.filter(({ quantity: stepQuantity }) => quantity >= stepQuantity)
    const matchIndex = fullFilled.length > 0 ? fullFilled.length - 1 : undefined

    return (
      <div className="discount-ladder">
        <IonText className="header" >Ver descuentos por volumen</IonText>
        <IonGrid >
          {total &&
            total.ladder.length > 0 &&
            total.ladder.map((prouct: any, index: any) => {
              const match = matchIndex === index
              return (
                <IonRow
                  key={index}
                  className={match ? 'discount-step discount-step-match' : 'discount-step'}
                  onClick={() => this.setQuantity(prouct.quantity)}                 >
                  <IonCol size="2" >
                    <IonRadio mode="md" value={match} color="primary"  />
                  </IonCol>
                  <IonCol size="6" className="discount-quantity" >
                    <IonText >{`${prouct.quantity} ${packageTypeAsText(packageType, prouct.quantity !== 1)}`}</IonText>
                  </IonCol>
                  <IonCol size="1" >
                    <IonText > x </IonText>
                  </IonCol>
                  <IonCol size="3" className="discount-price" >
                    <IonText >{formatCurrency(prouct.discount)}</IonText>
                    <IonText > cu</IonText>
                  </IonCol>
                </IonRow>
              )
            })}
        </IonGrid>
      </div>
    )
  }

  calculatingTotal = async (quantity: number) => {
    const { product } = this.props
    const result = await totalPrice(product.id, quantity)

    this.setState({
      total: result,
      spinner: false,
    })
  }

  render() {
    const { quantity, total, spinner } = this.state
    const { product, isNewProduct, isOpen } = this.props
    const { name, brand, units, packageType, uom, filename } = product
    const packageTypeName = packageTypeAsText(packageType)
    const unitOfMeasure = uomTypeAsText(uom, units > 1)
    const description = `${packageTypeName} de ${units} ${unitOfMeasure}`
    const notesMin = `Contenido total: ${units * quantity} ${unitOfMeasure}`
    const priceHeader = `Precio ${
      packageType === 'sbox' || packageType === 'xbox'
        ? packageTypeAsText(packageType).toLowerCase()
        : uomTypeAsText(uom).toLowerCase()
    }`

    return (
      <IonModal className="product-modal-dialog" backdropDismiss={false} isOpen={isOpen}>
        <div className="content">
          <div className="header">
            <IonIcon icon={close} onClick={this.onDismissModal}  />
          </div>
          <div className="body">
            <div>
              <div className="picture" style={{ backgroundImage: `url(${filename})` }}></div>
            </div>
            <div>
              <div className="title">{`${brand} · ${name}`}</div>
              <div className="description">{description}</div>
              <NumberUpDown
                value={quantity}
                valueToShow={
                  packageType === 'box' || 'pack' || 'piece' || 'bag' || 'sbox' ? quantity : quantity * units
                }
                min={1}
                max={5000}
                step={1}
                onChange={value => {
                  this.setQuantity(value)
                }}
              />
            </div>
          </div>
          <div className="notes">{notesMin}</div>
          <div className="footer">
            <IonGrid >
              <IonRow className="price" >
                <IonCol >{priceHeader}</IonCol>
                {total && total.unitPrice && total.unitPriceDiscount > 0 ? (
                  <IonCol >
                    {formatCurrency(total.unitPriceDiscount)}
                    <div className="old-price">{formatCurrency(total.unitPrice)}</div>
                  </IonCol>
                ) : (
                  total && total.unitPrice && total.unitPriceDiscount === 0 && <IonCol >{formatCurrency(total.unitPrice)}</IonCol>
                )}
              </IonRow>
              <IonRow className="total" >
                <IonCol >Total</IonCol>
                <IonCol >{spinner ? <IonSpinner name="crescent"  /> : total && formatCurrency(total.totalPrice)}</IonCol>
              </IonRow>
              {total && total.totalDiscount > 0 && (
                <IonRow className="save" >
                  <IonCol >Ahorras</IonCol>
                  <IonCol >{spinner ? <IonSpinner name="crescent"  /> : formatCurrency(total.totalDiscount)}</IonCol>
                </IonRow>
              )}
            </IonGrid>
            <IonButton strong={true} shape="round" onClick={this.onAcceptModal} expand="block" >
              <IonRippleEffect ></IonRippleEffect>
              {isNewProduct ? 'Añadir al carro' : 'Aceptar'}
            </IonButton>
          </div>
          {this.renderDiscountLadder()}
        </div>
      </IonModal>
    )
  }
}

export default track({})(ProductModal)
