import React from 'react'
import {
  IonPage,
} from '@ionic/react'
import User from '../../models/User'
import Settings from '../../models/Settings'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { ProductModel } from '../../models/ProductModel'
import { CartModel} from '../../models/CartModel'
import './Order.scss'
import { ProviderModel } from '../../models/ProviderModel'

import ToolBar  from '../../components/tool-bar/ToolBar'

type IPathParams = {
  id: string
}

type IProps = RouteComponentProps<IPathParams> & {
  readonly user: User
  readonly settings: Settings
  readonly productModel: ProductModel
  readonly cartModel: CartModel
  readonly providerModel: ProviderModel
}

interface State {
  order: any
}

class OrderInformation extends React.PureComponent<IProps, State> {
  state: State = {
    order: {},
  }

  componentDidMount() {
    this.setState({
      order: this.props.history.location.state,
    })
  }

  render() {
    const { history } = this.props

    return (
      <IonPage className="cart-page-order" >
					<ToolBar
            title={'Pedidos'}
            secondaryHeader={true}
						secondaryButtons={[{ type: 'back', onClick: history.goBack }]}/>
						Información
      </IonPage>
    )
  }
}

export default withRouter(OrderInformation)
