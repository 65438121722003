import { useContext, useEffect } from 'react'
import AppContext from '../../context/AppContext'
import { ProductCartData } from '../../interfaces/carts'
import { updateProductPriceInCart } from '../../services/bff_mobile/v2/carts/updateProductPriceInCart'

function useCheckQuantity(product: ProductCartData, paymentMethod: string) {
  const { cartData, handlerCartData } = useContext(AppContext)

  const updateQuantityProduct = async (newQuantity: number) => {
    const result = await updateProductPriceInCart(cartData._id, product._id, newQuantity)

    const { providerId, _id, quantity, totalPrice } = result

    const updatedCartData = cartData.data.map((data) => {
      if (data.paymentMethod !== paymentMethod) return data

      const updatedProviders = data.providers.map((provider) => {
        if (provider._id !== providerId) return provider

        const updatedProducts = provider.products.map((product) => {
          if (product._id !== _id) return product

          return {
            ...product,
            quantity,
            totalPrice,
          }
        })

        return {
          ...provider,
          products: updatedProducts,
        }
      })

      return {
        ...data,
        providers: updatedProviders,
      }
    })

    handlerCartData({
      ...cartData,
      data: updatedCartData,
    })
  }

  useEffect(() => {
    if (
      product.promotionalDynamicType !== 'no_promotion' &&
      product.promotionalDynamicDetail &&
      product.quantity > product.promotionalDynamicDetail
    )
      updateQuantityProduct(product.promotionalDynamicDetail)
    else if (product.stock && product.quantity > product.stock) updateQuantityProduct(product.stock)
    else if (product.quantity > product.quantityMax) updateQuantityProduct(product.quantityMax)
  }, [product]) 
}

export default useCheckQuantity
