import React, { useEffect, useState } from 'react';
import { Camera } from '@capacitor/camera';
import { CameraResultType } from '@capacitor/camera';
import { CameraSource } from '@capacitor/camera';

// Components
import { IonImg, IonIcon, IonModal } from '@ionic/react';

// Icons
import camera from "../../assets/icons/act_camera.svg"
import viewPhoto from '../../assets/icons/act_view.svg'
import closeIcon from '../../assets/icons/nav_close_white.svg'
import trashIcon from './../../assets/icons/act_delete_white.svg'

import './ImageUpload.scss'


interface UploadImageProps {
  onChange?: (photos: any) => void
}

const ImageUpload: React.FC<UploadImageProps> = ({ onChange }) => {
  const [photosEdit, setPhotosEdit] = useState<any>('')
  const [openLightbox, setOpenLightbox] = useState<boolean>(false)
  const [photoSelected, setPhotoSelected] = useState<any>('')

  useEffect(() => {
    if (onChange) onChange(photosEdit)
  }, [photosEdit])
  
  const onUploadPhoto = async  (edit?:boolean) => {
    let image:any = ''

    await Camera.getPhoto({
      quality: 50,
      allowEditing: false,
      resultType: CameraResultType.Base64,
      source: CameraSource.Prompt,
      width: 600,
      height: 600,
    }).then((imageData) => {
      image = imageData;
    }, (err) => {
      console.error(err)
    });

    if (image !== '' ){
      handlePhotosEditState (image, edit) 
    }
  }

  const handlePhotosEditState = async (image:any, edit?:boolean) => {
    setPhotosEdit((prevArr:any) => [...prevArr, image.base64String])
  }

  const expandImage = (photo:any , index: number, value?: boolean) => {
    setPhotoSelected({ photo, index, officeEdit: value })
    setOpenLightbox(true)
  }

  const lightBoxPhoto = () => {
    const image = `data:image/png;base64,${photoSelected.photo}`

    return (
      <IonModal className="modal-lightbox" isOpen={openLightbox} backdropDismiss={false}>
        <div className="container-lightbox">
          <div className="container-close" onClick={closeLightBoxPhoto}>
            <IonIcon className='icon-camera' icon={closeIcon}  />
          </div>
          <div className="container-img-lightbox">
            <img className='img-camera' id="img01" src={image} />
          </div>
          <div className="container-btn">
            <div className="icon-footer" onClick={editPhotoSelected}>
              <div className="container-icon"><IonIcon className='icon-camera' icon={camera}  /></div>
              <div>Cambiar</div>
            </div>
            <div className="icon-footer" onClick={() => deletePhotoSelected() }>
              <div className="container-icon"><IonIcon className='icon-camera' icon={trashIcon}  /></div>
              <div>Eliminar</div>
            </div>
          </div>
        </div>
      </IonModal>
    )
  }

  const deletePhotoSelected = (edit?: boolean) => {
    const newArrPhotos = photosEdit.filter((photo:any, index:number) => index !== photoSelected.index )
    setPhotosEdit(newArrPhotos)
    !edit && closeLightBoxPhoto()
  }

  const editPhotoSelected = async () =>  {
    onUploadPhoto(true)
    deletePhotoSelected(true)
    closeLightBoxPhoto()
  }

  const closeLightBoxPhoto = () => setOpenLightbox(false)

  return (
    <div className="upload-container">
      <div className="upload-section-wrapper">

        {photosEdit?.length > 0  && 
          photosEdit.map((photo:any, index:any) =>
          <div 
            className='image' 
            key={index}
            onClick={() => expandImage(photo, index) } 
          >
            <div
              className='img-camera'
              style={{ backgroundImage: `url(data:image/png;base64,${photo})` }}
            >
              <img src={viewPhoto} alt="eye-icon" className="eye-icon"/>
            </div>
          </div> 
          )
        }

        <div className="no-image" onClick={() => onUploadPhoto()}>
          <img src={camera} alt="camera-icon" className="camera-icon"/>
        </div>
      
        {openLightbox && lightBoxPhoto()}
      </div>
    </div>
  )
};

export default ImageUpload;




