import { useContext, useEffect } from 'react'
import AppContext from '../../context/AppContext'
import { getCouponsActivated } from '../../services/bff_mobile/v1/coupons/getCouponsActivated'

function useGetCouponsActivated() {
  const { handlerCouponsActivated } = useContext(AppContext)

  useEffect(() => {
    const action = async () => {
      try {
        handlerCouponsActivated(await getCouponsActivated())
      } catch (error) {
        handlerCouponsActivated([])
      }
    }

    action()
  }, []) 
}

export default useGetCouponsActivated
