import React from 'react'
import { IonPage, IonContent, IonProgressBar, IonHeader } from '@ionic/react'
import { orderBy } from 'lodash'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import cartToolbarIcon from '../../assets/icons/icon_carrito.svg'
import dispatchToolbarIcon from '../../assets/icons/icono_despacho.svg'
import { PromotionModel } from '../../models/PromotionModel'
import { ProductModel } from '../../models/ProductModel'
import Office from '../../models/Office'
import { CartModel } from '../../models/CartModel'
import { ProviderModel } from '../../models/ProviderModel'
import Category, { CategoryWithProducts } from '../../models/Category'
import  ToolBar  from '../../components/tool-bar/ToolBar'
import { StaticContext } from 'react-router-dom'
import GlobalComponents from './../../components'
import User from '../../models/User'
import Settings from '../../models/Settings'
import { navigateToCart } from '../../utils/navigation'

interface IPathParams { }

interface RouteState {
  singleBrandId?: string
  nameBrand?: string
  title?: string
  id?: string
  brandId?: string
  goBack?: boolean
}

type Props = RouteComponentProps<IPathParams, StaticContext, RouteState> & {
  user: User
  settings: Settings
  offices: Office
  categories: Category
  promotion: PromotionModel
  productModel: ProductModel
  cartModel: CartModel
  providerModel: ProviderModel
}

interface State {
  loading: any
  categories: CategoryWithProducts[]
  office: any
  offices: any
  latestUpdate: Date
}

class HomeBrand extends React.PureComponent<Props, State> {
  state: State = {
    loading: true,
    categories: [],
    office: {},
    offices: [],
    latestUpdate: new Date(2000, 1, 1),
  }

  async componentDidMount() {
    const { providerModel, history } = this.props


    await providerModel.loadProviders()
    this.products()
    if (history.location.pathname.indexOf('/home') < 0) {
      history.replace('/home')
    }
  }

  async products() {
    const singleBrandId = this.props.location.state ? this.props.location.state.singleBrandId : undefined

    const { categories } = this.props
    const products = (
      await categories.products(singleBrandId ? 1000 : 20, {
        where: {
          main: true,
        },
      }, null ,singleBrandId )
    ).filter((c: any) => c.products.length > 0)
    await this.setState({
      loading: false,
      categories: orderBy(products, ['order'], ['asc']),
      latestUpdate: new Date(),
    })

    await categories.brand()
  }


  private goToCart = () => {
    navigateToCart(this.props.history, this.props.location.state || this.props.history.location.state);
  }

  private goToOrders = () => {
    this.props?.history?.push('/orders')
  }

  private goToCategories = () => {
    this.props?.history?.push('/categories')
  }

  private goToHome = () => {
    this.props?.history?.push('/home')
  }

  private renderToolbar = () => {
    const { office, offices } = this.state
    const { history, cartModel } = this.props
    const productCount = cartModel.getCart().length
    const cartLocalStorage =  localStorage.getItem('@cart')
    let cart:any = ''
    if(cartLocalStorage){
      cart = JSON.parse(cartLocalStorage).products
    }
 
    cart = cart!=='' && cart.filter((item:any) => {return item.paid === false}) 
    const nameBrand = this.props.location.state ? this.props.location.state.nameBrand : undefined

    return nameBrand ? (
      <ToolBar
        title={nameBrand}
        primaryButtons={[
          {
            icon: cartToolbarIcon,
            key: 'cart',
            onClick: this.goToCart,
            badge: cart.length,
          },
          {
            icon: dispatchToolbarIcon,
            key: 'orders',
            onClick: this.goToOrders,
          },
        ]}
        secondaryButtons={[{ type: 'back', onClick: history.goBack }]}
      />
    ) : (
        <ToolBar
          title=""
          office={office}
          offices={offices}
          primaryButtons={[
            {
              icon: cartToolbarIcon,
              key: 'cart',
              onClick: this.goToCart,
              badge: productCount,
            },
            {
              icon: dispatchToolbarIcon,
              key: 'orders',
              onClick: this.goToOrders,
            },
          ]}
          secondaryButtons={[{ type: 'back', onClick: history.goBack }]}
        />
      )
  }


  private renderProducts = () => {
    const { categories, loading } = this.state
    const singleBrandId = this.props.location.state ? this.props.location.state.singleBrandId : undefined
    
    if (!categories || categories.length === 0) {
      return null; // Devuelve null en lugar de un array vacío
    }
  
    if (loading) {
      return <IonProgressBar type="indeterminate" {...({} as any)} />;
    }
  
    if (categories.length === 1) {
      categories.forEach((category: any) => {
        if (category.products && category.products.length) {
          this.props.history.replace(`/plp/${category._id}`, {
            title: category.name,
            id: category._id,
            brandId: singleBrandId,
            goBack: true
          });
        }
      });
      return null; // Devuelve null ya que estamos redirigiendo
    }
  
    return categories.map(
      (category: any, index: number) =>
        category.products &&
        category.products.length && (
          <GlobalComponents.ProductSlider
            key={category._id}
            products={category.products}
            placementName={`home-brand-category-product-slider-${category.name}`}
            info={{
              title: category.name,
              id: category._id,
              brandId: `${singleBrandId}`
            }}
            onMoreInfoClick={this.onMoreInfoClick}
            onGoToCart={this.goToCart}
            {...this.props}
            categoryPosition={index + 1}
            fromSlider={false}
          />
        )
    );
  }

  private onMoreInfoClick = (info: { id: string }) => {
    const singleBrandId = this.props.location.state ? this.props.location.state.singleBrandId : undefined
    this.props?.history?.push(`/plp/${info.id}`, { ...info, singleBrandId })
  }

  render() {
    return (
      <IonPage className="home-page" >
        <IonHeader >{this.renderToolbar()}</IonHeader>
        <IonContent className='products-brand' >
          {this.renderProducts()}
        </IonContent>
      </IonPage>
    )
  }
}

export default withRouter(HomeBrand)