import React, { useState, useEffect } from 'react'
import { IonBadge, IonContent, IonPage } from '@ionic/react'
import { RouteComponentProps, withRouter, useParams } from 'react-router-dom'
import { StaticContext } from 'react-router-dom'
import track from 'react-tracking'

//COMPONENTS
import GlobalComponents from '../../components'

//MODELS
import User from '../../models/User'
import Category from '../../models/Category'
import Settings from '../../models/Settings'
import { CartModel } from '../../models/CartModel'
import { Product, ProductModel } from '../../models/ProductModel'
import { PromotionModel } from '../../models/PromotionModel'
import { HashtagModel } from '../../models/HashtagModel'
import Office from '../../models/Office'
import { ProviderModel } from '../../models/ProviderModel'

//ICONOS
import cartToolbarIcon from '../../assets/icons/nav_cart_blue.svg'
import searchIcon from '../../assets/icons/act_search.svg'

//UTILS
import { EventEmitter } from '../../utils/events'
import { getCartLength } from '../../utils/cart'

//ESTILOS
import '../provider-category/ProviderCategory.scss'
import './/HashtagProviderView.scss'
import { navigateToCart } from '../../utils/navigation'

//TIPOS
type IPathParams = {
  id: string
  hashtagId: string
}
interface RouteState {
  title?: string
  pathname?: string
  data?: any
}
type IProps = RouteComponentProps<IPathParams, StaticContext, RouteState> & {
  user: User
  settings: Settings
  categories: Category
  productModel: ProductModel
  tracking?: any
  cartModel: CartModel
  promotion: PromotionModel
  offices: Office
  providerModel: ProviderModel
  hashtagModel: HashtagModel
}

const mapProductFromSuggested = (product: any) => ({
  ...product,
  provider: product.providerId,
  filename: product.imageFullPath ?? product.filename,
  vendorActiveInComune: product.active
})

const HashtagProviderView: React.FC<IProps> = props => {
  const { history, location, hashtagModel } = props
  const { title } = location.state
  const [loading, setLoading] = useState<boolean>(true)
  const { id: providerId, hashtagId } = useParams<IPathParams>()
  const [toolbarTitle] = useState<string>(title ?? '')
  const [cart, setCart] = useState<any>([])
  const [cartLengthData, setCartLengthData] = useState<any>({ cartLength: 0, prevLength: 0 })
  const [animateAtc, setAnimateAtc] = useState<string>('')
  const [products, setProducts] = useState<Product[]>([])

  useEffect(() => {
    getProducts()
  }, []) 

  useEffect(() => {
    const cartLocalStorage = localStorage.getItem('@cart')

    if (cartLocalStorage) setCart(JSON.parse(cartLocalStorage).products)
  }, [])

  useEffect(() => {
    const cartLocal = localStorage.getItem('@cart')
    const cart = cartLocal ? JSON.parse(cartLocal) : null

    if (cart) {
      const updatedCartLength: any = cart.products?.length || null
      setCartLengthData({ cartLength: updatedCartLength, prevLength: updatedCartLength })
    }

    getCart()

    EventEmitter.subscribe('UpdateCartCount', ({ cartUpdateAction }: any) => {
      setCartLengthData((prevState: any) => {
        let newCartLength = prevState.cartLength
        if (cartUpdateAction === 'add') newCartLength = newCartLength + 1
        if (cartUpdateAction === 'subtract') newCartLength = newCartLength - 1
        const didAdd = newCartLength > prevState.cartLength
        if (didAdd) {
          setAnimateAtc('badge-anim-in')
          setTimeout(() => { setAnimateAtc('badge-anim-out') }, 1);
        }
        return {
          cartLength: newCartLength,
          prevLength: prevState.cartLength
        }
      })
    })

    return () => {
      EventEmitter.unsubscribe('UpdateCartCount')
    }
  }, [])

  const getCart = async () => {
    const cartLength: any = await getCartLength()
    setCartLengthData({ cartLength, prevLength: cartLength })
  }

  const getProducts = async () => {
    const _products = await hashtagModel.getProductsByVendors(hashtagId, providerId)
    _products.map((product: Product) => {
      // @ts-ignore
      product.filename = product.imageFullPath
      return product
    })
    setProducts(_products)
    setLoading(false)
  }

  const openSearch = () => {
    const pathname = window.location.pathname
    history.push(`/vendor/${providerId}/search`, { pathname, data: location.state })
  }

  const goToCart = () => navigateToCart(history, props.location.state || props.history.location.state);

  return (
    <IonPage className="hashtag-provider-page" >
      <div className='relative-header-wrapper'>
        {cartLengthData.cartLength > 0 && <IonBadge className={`cart-badge-btn ${animateAtc}`} >{cartLengthData.cartLength}</IonBadge>}
        <GlobalComponents.ToolBar
          title={toolbarTitle}
          secondaryButtons={[{ type: 'back', onClick: history.goBack }]}
          secondaryHeader={true}
          boxShadow={true}
          primaryButtons={[
            {
              icon: searchIcon,
              key: 'search',
              onClick: openSearch,
            },
            {
              icon: cartToolbarIcon,
              key: 'cart',
              onClick: goToCart,
              badge: cart.length,
              animateAtc,
            },
          ]}
        />
      </div>
      <IonContent >
      <div className="container-category-provider">
        <div className="product-slider-secundary">
          <div key={providerId} className="container-subCategory">
            <div className="body">
              <div id={providerId}>
                {!loading &&
                  <>
                    {
                      products.length > 0 &&
                      products.map((product: Product, index: number) => {
                        return (<div className="container-card" key={product.id}>
                          <div key={product.id}>
                            <GlobalComponents.ProductCard
                              key={product.id}
                              product={mapProductFromSuggested(product)}
                              settings={props.settings}
                              cartModel={props.cartModel}
                              onGoToCart={goToCart}
                              history={props.history}
                            ></GlobalComponents.ProductCard>
                          </div>
                        </div>)
                      }
                      )}
                    <div className="container-height"></div>
                  </>
                }
                {loading &&
                  <div className="hashtag-loading-container">
                    <GlobalComponents.LoadingComponent height="100px"/>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      </IonContent>
    </IonPage>
  )
}

export default track({ page: 'HashtagProviderView' })(withRouter(HashtagProviderView))
