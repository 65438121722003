import { Device } from '@capacitor/device';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';

export const appShouldUpdate = async (versionAndroid: number, versionIos: number) => {
    if(!versionAndroid || !versionIos) {
        throw new Error("DAMN")
    }
    let shouldUpdate = false

    let info = await Device.getInfo();
    const appInfoCapacitor = await App.getInfo().catch(() => null);

    if (Capacitor.getPlatform() !== 'web') {
        info = Object.assign(info, appInfoCapacitor, { appBuild: appInfoCapacitor?.build });
        if ( info.platform === 'android') {
            shouldUpdate = appInfoCapacitor.build < versionAndroid
        }

        if (info.platform === 'ios') {
            shouldUpdate = appInfoCapacitor.build < versionIos
        }
    }

    return shouldUpdate
    
}