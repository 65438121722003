import { useContext, useEffect, useState } from 'react'
import AppContext from '../../context/AppContext'
import { PaymentMethodCartData } from '../../interfaces/carts'
import { calculateProductsTotalPrice, calculateTotalDiscountsByProvider } from '../../utils/functions/calculatePrices'

function useCheckProvidersMinimumPrice(paymentMethodData: PaymentMethodCartData) {
  const { cartData } = useContext(AppContext)
  const [disabledButton, setDisabledButton] = useState<boolean>(false)

  const checkProvidersMinimumPrice = (): boolean => {
    for (const provider of paymentMethodData.providers) {
      const total: number =
        calculateProductsTotalPrice(provider.products) -
        calculateTotalDiscountsByProvider(cartData.discounts, provider._id)

      if (total < provider.minimum) return true
    }

    return false
  }

  useEffect(() => {
    setDisabledButton(checkProvidersMinimumPrice())
  }, [cartData]) 

  return { disabledButton }
}

export default useCheckProvidersMinimumPrice
