import React, { useEffect } from 'react';
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';

export const DevicePermissions: React.FC = () => {
    const checkPushNotificationPermission = async (): Promise<void> => {
        if (Capacitor.getPlatform() !== 'android') return;
  
        try {
          const result = await PushNotifications.checkPermissions();
          
          if (result.receive === 'prompt') {
            await PushNotifications.requestPermissions();
          }

          if (result.receive === 'denied') {
            alert('Si quiere recibir notificaciones debe activarlas manualmente en los ajustes de su celular');
          }
        } catch (error) {
          console.error('Error checking push notification permissions:', error);
        }
    };

    useEffect(() => {
        checkPushNotificationPermission();
    }, []);

  // This component doesn't render anything visible
  return null;
};

