import React, { useContext } from 'react'
import './index.scss'
import { IonIcon } from '@ionic/react'
import { arrowBackOutline, searchOutline } from 'ionicons/icons'
import AppContext from '../../context/AppContext'
import { useHistory } from 'react-router-dom'
import GuruDespachoLogo from '../../assets/guru_despacho_home_ff.png'
import iconStar from './../../assets/icons/icn_star_yellow.svg'
import NavCart from '../../assets/icons/nav_cart_blue.svg'

interface Props {
  redirectTo: string
}

function NavbarHomeProviders({ redirectTo }: Props) {
  const { platform } = useContext(AppContext)
  const history = useHistory()

  return (
    <header className={`home-provider-header ${platform === 'ios' ? 'mobile' : 'web'}`}>
      <div>
        <IonIcon icon={arrowBackOutline} onClick={() => history.push(`/${redirectTo}`)} ></IonIcon>
        <img className="guru-despacho-logo" src={GuruDespachoLogo} alt="Gurú Despacho" />
        <div className="star-container">
          <img src={iconStar} alt="Icono boton" />
          <span>4,6</span>
        </div>
      </div>

      <div>
        <IonIcon icon={searchOutline} size="large" ></IonIcon>
        <i onClick={() => history.push('/cart2')}>
          <img src={NavCart} alt="carro" />
        </i>
      </div>
    </header>
  )
}

export default NavbarHomeProviders
