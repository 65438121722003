import { IonButton, IonButtons, IonHeader, IonModal, IonTitle, IonToolbar, IonContent } from '@ionic/react'
import React, { Dispatch, SetStateAction } from 'react'
import { ProviderCartData } from '../../../../interfaces/carts'
import { CouponActivated } from '../../../../interfaces/coupons'
import CouponItem from '../coupon_item'
import './index.scss'

interface Props {
  openModal: boolean
  setOpenModal: Dispatch<SetStateAction<boolean>>
  couponsActivated: CouponActivated[]
  provider: ProviderCartData
}

function CouponsActivatedModal({ openModal, setOpenModal, couponsActivated, provider }: Props) {
  return (
    <IonModal isOpen={openModal}>
      <IonHeader >
        <IonToolbar >
          <IonTitle >Cupones</IonTitle>
          <IonButtons slot="end" >
            <IonButton onClick={() => setOpenModal(false)} >Cerrar</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent scrollY >
        <div className="coupons-modal__content">
          <p>
            Al aplicar cualquier cupón estás <span>aceptando los términos y condiciones</span>
          </p>

          {couponsActivated.map((coupon) => (
            <CouponItem key={coupon.couponId} couponActivated={coupon} provider={provider} />
          ))}
        </div>
      </IonContent>
    </IonModal>
  )
}

export default CouponsActivatedModal
