import { IonPage } from '@ionic/react'
import React from 'react'
import { withRouter } from 'react-router-dom'

import './DeleteAccount.scss'

const DeleteAccount = () => {
  return (
    <IonPage>
      <div className='delete-page'>
        <div className="delete-wrapper">
          <h3 className='title'>Cómo Borrar tu Cuenta en Almacén Gurú</h3>
          <div className='general-text'>Para eliminar tu cuenta de manera permanente en Almacén Gurú, sigue estos sencillos pasos:</div>
          <div className='points'>Requisitos Previos</div>
          <div className='general-text'>Antes de eliminar tu cuenta, asegúrate de que la información personal en la app esté actualizada (nombre, apellido, RUT, dirección, teléfono de contacto, y correo electrónico). Esto es necesario para verificar que estás eliminando la cuenta correcta y para facilitar cualquier comunicación posterior.</div>
          <div className='points'>Paso 1: Abre la Aplicación</div>
          <div className='general-text'>Inicia la app de Almacén Gurú en tu dispositivo móvil o accede a Almacén Gurú a través de un navegador web.</div>
          <div className='points'>Paso 2: Inicia Sesión</div>
          <div className='general-text'>Ingresa con tus <b>tus credenciales personales</b>. Si no recuerdas tu usuario o contraseña, puedes recuperarlos desde la misma aplicación.</div>
          <div className='points'>Paso 3: Ve a tu Perfil</div>
          <div className='general-text'>Toca el ícono de <b>Cuenta</b>, ubicado en la barra de navegación inferior.</div>
          <div className='points'>Paso 4: Eliminar Cuenta</div>
          <div className='general-text'>Desplázate hasta el final de la sección de tu perfil y selecciona la opción <b>"Eliminar cuenta"</b>.</div>
          <div className='points'>Paso 5: Confirma la Eliminación</div>
          <div className='general-text'>Aparecerá una ventana de confirmación. Lee con atención la información sobre las implicaciones de eliminar tu cuenta y confirma la acción.</div>
          <div className='points'>Paso 6: Recibe la Notificación de Confirmación</div>
          <div className='general-text'>Recibirás una notificación confirmando tu solicitud de borrado de cuenta y los datos asociados.</div>
          <div className='general-text'><b>Nota:</b> Al eliminar tu cuenta, perderás acceso a toda tu información y configuraciones en Almacén Gurú. Sin embargo, si decides volver a usar la plataforma, podrás crear una nueva cuenta en cualquier momento.</div>
        </div>
      </div>
    </IonPage>
  )
}

export default withRouter(DeleteAccount)