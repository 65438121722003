import React from 'react'
import {
  IonContent,
  IonButton,
  IonPage,
  IonItem,
  IonLoading,
  IonInput,
  IonIcon,
  IonToast,
  IonRippleEffect,
  IonButtons,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
} from '@ionic/react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import track from 'react-tracking'
import UserModel from '../../models/User'
import SettingsModel from '../../models/Settings'
import { produce } from 'immer'
import './LoginConfirmSms.scss'
import { confirmSms, requestSms } from '../../clients/validateSms'
import { newValidatePhone, validateEmail } from '../../utils/basetypes'
import { validateRut, validarRuc, isRfcValid } from '../../utils/rut'
import moment from 'moment'
//ICONS
import eraseVerify from './../../assets/icons/erase-verify.svg'
import arrowBack from './../../assets/icons/arrow-back-red.svg'
import { logError, loginOrRegisterSuccessful } from '../firebase/firebaseTags'

import { currentApp } from '../../utils/currentCountry'
import { countryCl, countryMx, countryPe } from '../../utils/countriesTexts'
import { getLocalStorageObject } from '../../utils/localstorageData'

type IPathParams = {}

type Props = RouteComponentProps<IPathParams> & {
  user: UserModel
  settings: SettingsModel
  tracking?: any
}

interface Form {
  code1: any
  code2: any
  code3: any
  code4: any
  email: string
  number: string
  user_contac: string
  username: string

}

interface State<T> {
  data: { [name in keyof T]: T[name] }
  validation: { [name in keyof T]: boolean }
  isFormValid: boolean
  loading: boolean
  showError: boolean
  messageError: string
  haxSms: string
  time: number
  start: number
  newSend: boolean
  skip: boolean
  form: any
  valueForm: string
  country: any
}

class LoginConfirmSms extends React.PureComponent<Props, State<Form>> {
  state: State<Form> = {
    data: {
      code1: null,
      code2: null,
      code3: null,
      code4: null,
      email: '',
      number: '',
      username: '',
      user_contac: '',
    },
    validation: {
      code1: false,
      code2: false,
      code3: false,
      code4: false,
      email: false,
      number: false,
      username: false,
      user_contac: false,
    },
    isFormValid: false,
    loading: false,
    showError: false,
    messageError: '',
    haxSms: '',
    time: 0,
    start: 0,
    newSend: false,
    skip: false,
    form:{},
    valueForm:"",
    country:""
  }

  async componentDidMount() {
    if (this.props.history.location.state) {
      const { user, haxSms }: any = this.props.history.location.state
      await this.setState({form:{countryInfo: {}, "phone":user.phone}})
      await this.onChangeInput('number', user.phone || '')
      await this.onChangeInput('email', user.email || '')
      await this.onChangeInput('user_contac', user.userContac || '')
      await this.onChangeInput('username', user.username || '')
      await this.setState({
        haxSms,
      })
    }
    this.startTimer()
    
    const countryName = localStorage.getItem('countryName') 
    const country = countryName ? JSON.parse(countryName) :  ""
    const countryState = currentApp().find((countryValidator:any) => countryValidator.country === country.countryCode) 

    this.setState({
      country: countryState
    })
  
  }

  startTimer() {
    this.setState({
      time: this.state.time,
      start: Date.now() + 120000,
      newSend: false,
    })

    const timeSend = setInterval(() => {
      this.setState({
        time: Date.now(),
      })
      if (this.state.start <= this.state.time) {
        this.setState({
          newSend: true,
          skip: true,
        })
        clearTimeout(timeSend)
      }
    }, 1000)
  }

  skip = async () => {
    const user = {
      email: this.state.data.email,
      phone: this.state.data.number,
      userContac: this.state.data.user_contac,
      username: this.state.data.username,
    }
    await this.props.user.authenticateWith(this.props.history.location.state)
    await this.props.user.editCacheUser(user)

    this.props.history.replace('/home', this.props.history.location.state)
  }

  onButtonClick = async () => {
    const {
      data: { code1, code2, code3, code4 }
    } = this.state
    const codeSms = String(code1 + code2 + code3 + code4)
    try {
      this.setState({
        loading: true,
      })
      setTimeout(() => {
        this.setState({
          loading: false,
        })
      }, 3000)
      const user = {
        email: this.state.data.email,
        phone: this.state.data.number,
        userContac: this.state.data.user_contac,
        username: this.state.data.username,
      }

      const smsResult: any = await confirmSms(codeSms, this.state.haxSms, user)
      this.setState({
        loading: false,
      })
      if (smsResult && smsResult.validSms) {
          if(this.validateCountryChile()){
            await this.props.user.authenticateWith(this.props.history.location.state)
            await this.props.user.editCacheUser(user)
            
          }
          loginOrRegisterSuccessful('sign_up', 'mail')
          const loginData:any = this.props.history.location.state
          const userData=  {...loginData,'welcomeGuru':false}
          if(loginData?.fromLogin) this.validateCountryChile() && this.props?.history?.push('/home', userData )
        
          this.reditrectTo(this.props.history.location.state, user)
     
      } 
      else {
        await this.setState({
          loading: false,
          showError: true,
          messageError: 'Código SMS Invalido',
        })
        logError('sign_up_error', 'Codigo SMS invalido')
      }
    } catch (error) {
      if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
      await this.setState({
        loading: false,
        showError: true,
        messageError: 'Error de servicio',
      })
      logError('sign_up_error', 'Error de servicio')
      throw error
    }
  }

  validateCountryChile = () => this.state.country?.country === countryCl

  reditrectTo = (state:any, user:any) =>{
    const { country } = this.state

    if(country.country === countryPe) return this.props?.history?.push('/branch-office', {state, user})
    if(process.env.REACT_APP_GURU_PRO === 'true')  return this.props.history.replace('/register-optional', state)
    return this.props.history.replace('/home', this.props.history.location.state)
    
  }

  onKeyboard = async (value: any) => {
    const {
      data: { code1, code2, code3, code4 },
    } = this.state
    let type = ''
    let validation: any = false
    if (!code1) {
      if (value === 'delete') {
        value = null
        validation = false
      }
      validation = true
      type = 'code1'
    } else if (code1 && !code2) {
      if (value === 'delete') {
        value = null
        type = 'code1'
        validation = false
      } else {
        validation = true
        type = 'code2'
      }
    } else if (code1 && code1 && !code3) {
      if (value === 'delete') {
        value = null
        type = 'code2'
        validation = false
      } else {
        validation = true
        type = 'code3'
      }
    } else if (code1 && code2 && code3 && !code4) {
      if (value === 'delete') {
        value = null
        type = 'code3'
        validation = false
      } else {
        validation = true
        type = 'code4'
      }
    } else if (code1 && code2 && code3 && code4) {
      if (value === 'delete') {
        value = null
        type = 'code4'
        validation = false
      }
    }

    await this.setState(
      produce(this.state, draft => {
        switch (type) {
          case 'code1':
            draft.data[type] = value
            draft.validation[type] = validation
            break
          case 'code2':
            draft.data[type] = value
            draft.validation[type] = validation
            break
          case 'code3':
            draft.data[type] = value
            draft.validation[type] = validation
            break
          case 'code4':
            draft.data[type] = value
            draft.validation[type] = validation
            break
          default:
            break;
        }
        let allAreTrue = true
        ;(Object.keys(draft.validation) as Array<keyof typeof draft.validation>).forEach(key => {
          const field = draft.validation[key]
      
          if (field === false) {
            allAreTrue = false
          }
        })
        draft.isFormValid = allAreTrue
      }),
    )
  
    if (this.state.isFormValid) {
      await this.onButtonClick()
    }
  }

  onChangeInput = (type: keyof Form, value: Form[keyof Form]) => {
    this.setState(
      produce(this.state, draft => {
        switch (type) {
          case 'number':
            draft.data[type] = value
            draft.validation[type] = newValidatePhone(value)
            break
          case 'email':
            draft.data[type] = value
            draft.validation[type] = validateEmail(value)
            break
          case 'user_contac':
            draft.data[type] = value
            draft.validation[type] = value.length > 0
            break
          case 'username':
            const validation = this.validateDni(value)
            draft.data[type] = validation || value
            draft.validation[type] = validation != null
            break
          default:
            break;
        }
        let allAreTrue = true
        ;(Object.keys(draft.validation) as Array<keyof typeof draft.validation>).forEach(key => {
          const field = draft.validation[key]
          if (field === false) {
            allAreTrue = false
          }
        })
        draft.isFormValid = allAreTrue
      }),
    )
  }

  validateDni = (dni:any) =>{
    const countryData = getLocalStorageObject('countryName')
    const countryCode = countryData?.countryCode || countryCl

    const countryValidators = [
      {
        countryCode: countryCl,
        validator: validateRut,
      },
      {
        countryCode: countryPe,
        validator: validarRuc
      },
      {
        countryCode: countryMx,
        validator: isRfcValid
      }
    ]
    
    const countryValidatorMatch = countryValidators.find((countryValidator:any) => countryValidator.countryCode === countryCode)

    if (countryValidatorMatch) return countryValidatorMatch.validator(dni)
    
    return false
  }

  resendButtonClick = async () => {
    const {
      data: { number, username },
    } = this.state
    const smsResult = await requestSms(number, username)
    if (smsResult) {
      this.setState({
        haxSms: smsResult.haxSms,
      })
    }
    this.startTimer()
  }

  hideToast = () => {
    this.setState({
      showError: false,
      messageError: '',
    })
  }

  hideLoading = () => {
    this.setState({
      loading: false,
      messageError: '',
    })
  }

  onChangeInputPhone = (field: string, value: any) => {
    const { form } = this.state
    const o: any = {}
    o[field] = value

    this.setState({
      form: Object.assign(form, o),
      valueForm: value,
    })
  }

  saveUser = async () =>{
    const {
      data: { username }, valueForm
    } = this.state
    const smsResult = await requestSms(valueForm, username)
    if (smsResult) {
      this.setState({
        haxSms: smsResult.haxSms,
      })
    }
  }

  render() {
    const {
      data: { code1, code2, code3, code4 },form, valueForm
    } = this.state
    let { loading, time, start, country } = this.state
    const phoneUser:any = this.props.history.location.state

    return (
      <IonPage className="confirm-page" >
        <IonContent >
          <div className="wrapper">
            <div className="header">
              <IonButtons slot="secondary" >
                <IonIcon
                  slot="icon-only"
                  onClick={() => {
                    this.props.history.goBack()
                  } }
                  icon={arrowBack}                 />
              </IonButtons>
            </div>
          </div>
          <IonToast
            isOpen={this.state.showError}
            onDidDismiss={() => this.hideToast()}
            message={this.state.messageError}
            duration={5000}
          />
          <IonLoading
            mode="md"
            className="close-session"
            isOpen={loading}
            onDidDismiss={() => this.hideLoading()}
            message={'Validando teléfono....'}
            duration={5000}
            translucent={true}
          ></IonLoading>
          {/* <IonImg src={logo} /> */}
          <div className="welcome-title">
            <div className="title">
              <b>CÓDIGO DE VERIFICACIÓN</b>
            </div>
            <div className="title-2">
              <em>Por favor, ingresa el código recibido.</em>
            </div>
          </div>
          <div className="sub-title">Código</div>
          <form>
            <div className="form">
              <IonGrid >
                <IonRow >
                  <IonCol size="3" >
                    <IonItem mode="ios" className={'code-valid'} >
                      <IonInput
                        value={code1}
                        minlength={1}
                        maxlength={1}
                        required={true}
                        disabled={true}
                        type="text"                       ></IonInput>
                    </IonItem>
                  </IonCol>
                  <IonCol size="3" >
                    <IonItem mode="ios" className={'code-valid'} >
                      <IonInput
                        value={code2}
                        minlength={1}
                        maxlength={1}
                        required={true}
                        disabled={true}
                        type="text"                       ></IonInput>
                    </IonItem>
                  </IonCol>
                  <IonCol size="3" >
                    <IonItem mode="ios" className={'code-valid'} >
                      <IonInput
                        value={code3}
                        minlength={1}
                        maxlength={1}
                        required={true}
                        disabled={true}
                        type="text"                       ></IonInput>
                    </IonItem>
                  </IonCol>
                  <IonCol size="3" >
                    <IonItem mode="ios" className={'code-valid'} >
                      <IonInput
                        value={code4}
                        minlength={1}
                        maxlength={1}
                        required={true}
                        disabled={true}
                        type="text"                       ></IonInput>
                    </IonItem>
                  </IonCol>
                </IonRow>
              </IonGrid>

              {this.state.newSend ? (
                <IonLabel className="resend-sms" >
                  <IonButton strong={true} shape="round" onClick={this.resendButtonClick} expand="block" >
                    <IonRippleEffect ></IonRippleEffect>
                    REENVIAR MENSAJE
                  </IonButton>
                </IonLabel>
              ) : (
                <IonLabel className="resend-sms" >
                  <IonRippleEffect ></IonRippleEffect>
                  Volver a reenviar mensaje en {moment(new Date(start - time)).format('mm:ss')}
                </IonLabel>
              )}
             {phoneUser && phoneUser.phone === true && 
              <div className="validate-phone">
                <IonLabel position="floating" >Ingresar número de teléfono</IonLabel>
                <IonInput
                    value={form.phone}
                    onIonChange={e => this.onChangeInputPhone('phone', e.detail.value || '')}
                    required={true}
                    autocomplete="off"
                    type="tel"
                    inputmode="tel"
                    autoFocus
                    maxlength={8}                 >
                  <span className="phone-prefix">{country.prefixNumber}</span>
                </IonInput>
                <div className="btn-save">
                  <IonButton
                      disabled={!newValidatePhone(valueForm)}
                      strong={true}
                      shape="round"
                      expand="block"
                      onClick={() => this.saveUser()}                     >
                      {'Enviar código'}
                    </IonButton>
                </div>
              </div>
              }

              <IonGrid className="grid-confirm-sms" >
                <IonRow >
                  <IonCol size="4" >
                    <IonButton mode="md" strong={true} onClick={() => this.onKeyboard('1')} expand="block" className="numeric-keyboard" >
                      <IonRippleEffect ></IonRippleEffect>1
                    </IonButton>
                  </IonCol>
                  <IonCol size="4" >
                    <IonButton mode="md" strong={true} onClick={() => this.onKeyboard('2')} expand="block" className="numeric-keyboard" >
                      <IonRippleEffect ></IonRippleEffect>2
                    </IonButton>
                  </IonCol>
                  <IonCol size="4" >
                    <IonButton mode="md" strong={true} onClick={() => this.onKeyboard('3')} expand="block" className="numeric-keyboard" >
                      <IonRippleEffect ></IonRippleEffect>3
                    </IonButton>
                  </IonCol>
                </IonRow>
                <IonRow >
                  <IonCol size="4" >
                    <IonButton mode="md" strong={true} onClick={() => this.onKeyboard('4')} expand="block" className="numeric-keyboard" >
                      <IonRippleEffect ></IonRippleEffect>4
                    </IonButton>
                  </IonCol>
                  <IonCol size="4" >
                    <IonButton mode="md" strong={true} onClick={() => this.onKeyboard('5')} expand="block" className="numeric-keyboard" >
                      <IonRippleEffect ></IonRippleEffect>5
                    </IonButton>
                  </IonCol>
                  <IonCol size="4" >
                    <IonButton mode="md" strong={true} onClick={() => this.onKeyboard('6')} expand="block" className="numeric-keyboard" >
                      <IonRippleEffect ></IonRippleEffect>6
                    </IonButton>
                  </IonCol>
                </IonRow>
                <IonRow >
                  <IonCol size="4" >
                    <IonButton mode="md" strong={true} onClick={() => this.onKeyboard('7')} expand="block" className="numeric-keyboard" >
                      <IonRippleEffect ></IonRippleEffect>7
                    </IonButton>
                  </IonCol>
                  <IonCol size="4" >
                    <IonButton mode="md" strong={true} onClick={() => this.onKeyboard('8')} expand="block" className="numeric-keyboard" >
                      <IonRippleEffect ></IonRippleEffect>8
                    </IonButton>
                  </IonCol>
                  <IonCol size="4" >
                    <IonButton mode="md" strong={true} onClick={() => this.onKeyboard('9')} expand="block" className="numeric-keyboard" >
                      <IonRippleEffect ></IonRippleEffect>9
                    </IonButton>
                  </IonCol>
                </IonRow>
                <IonRow >
                  <IonCol size="4" ></IonCol>
                  <IonCol size="4" >
                    <IonButton mode="md" strong={true} onClick={() => this.onKeyboard('0')} expand="block" className="numeric-keyboard" >
                      <IonRippleEffect ></IonRippleEffect>0
                    </IonButton>
                  </IonCol>
                  <IonCol size="4" >
                    <IonButton mode="md" strong={true} onClick={() => this.onKeyboard('delete')} expand="block" className="numeric-keyboard-delete" >
                      <IonRippleEffect ></IonRippleEffect>
                      <IonIcon slot="icon-only" icon={eraseVerify}  />
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>

              <a className="report-problem" href="/contact">
                <IonRippleEffect ></IonRippleEffect>
                REPORTAR PROBLEMA
              </a>
            </div>
          </form>
        </IonContent>
      </IonPage>
    )
  }
}

export default track({ page: 'LoginConfirmSms' })(withRouter(LoginConfirmSms))
