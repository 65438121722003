import { useContext, useEffect, useState } from 'react'
import AppContext from '../../context/AppContext'
import { calculateProductsTotalPrice, calculateTotalDiscountsByProvider } from '../../utils/functions/calculatePrices'

function useCalculateCartTotalPrice() {
  const { cartData } = useContext(AppContext)
  const [totalPrice, setTotalPrice] = useState<number>(0)

  const calculateTotalPrice = (): number => {
    let total: number = 0
    cartData.data.forEach((data) => {
      let totalPaymentMethodPrice: number = 0

      data.providers.forEach((provider) => {
        const totalProductsPrice = calculateProductsTotalPrice(provider.products)

        const totalProviderDiscounts = calculateTotalDiscountsByProvider(cartData.discounts, provider._id)

        totalPaymentMethodPrice += totalProductsPrice - totalProviderDiscounts
      })

      total += totalPaymentMethodPrice
    })

    return total
  }

  useEffect(() => {
    setTotalPrice(calculateTotalPrice())
  }, [cartData]) 

  return { totalPrice }
}

export default useCalculateCartTotalPrice
