import React from 'react'
import { IonPage, IonMenuButton, IonContent } from '@ionic/react'
import User from '../../models/User'
import Settings from '../../models/Settings'

type IProps = {
  user: User
  settings: Settings
}

export default class Categories extends React.PureComponent<IProps, {}> {
  render() {
    return (
      <IonPage >
        <IonContent >
          Categories
          <IonMenuButton >wdqwdqwd</IonMenuButton>
        </IonContent>
      </IonPage>
    )
  }
}
