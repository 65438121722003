import { useContext, useEffect, useState } from 'react'
import AppContext from '../../context/AppContext'
import { ProviderCartData } from '../../interfaces/carts'

function useCalculateProviderCardTotalPrice(provider: ProviderCartData) {
  const { cartData } = useContext(AppContext)
  const [totalPrice, setTotalPrice] = useState<number>(0)

  const calculateTotalPrice = (): number => {
    const totalProductsPrice = provider.products.reduce((acc, product) => acc + product.totalPrice, 0)

    const totalDiscounts = cartData.discounts.reduce(
      (acc, discount) => (discount.providerId === provider._id ? acc + Number(discount.amount) : acc),
      0,
    )

    return totalProductsPrice - totalDiscounts
  }

  useEffect(() => {
    setTotalPrice(calculateTotalPrice())
  }, [cartData]) 

  return { totalPrice }
}

export default useCalculateProviderCardTotalPrice
