import React from 'react'
import { IonIcon, IonModal, IonButton,IonInput, IonItem,IonAvatar, IonLabel, 
	IonTextarea,IonSpinner, IonContent} from '@ionic/react'
import './Form.scss'
import  problem from './../../assets/icn-pop_problem.png'
import icn_user_name from './../../assets/icons/icn_user-name.svg'
import icn_phone  from './../../assets/icons/icn_phone.svg'
import icn_email  from './../../assets/icons/icn_email.svg'
import { produce } from 'immer'
import { validatePhone, validateEmail } from '../../utils/basetypes'
import { checkmarkCircle } from 'ionicons/icons'
import { sendForm } from '../../clients/validateSms'
import closeMenuIcon from './../../assets/icons/icono_cerrar.svg'


interface FormAPP{
	username: string
	email: string
	phone: string
	message:string
}

interface IProps {
 cartModel: any
	user:any
}

interface State<T> {
  data: { [name in keyof T]: T[name] }
	validation: { [name in keyof T]: boolean }
	isFormValid: boolean
	spinner: boolean
	showAlert: boolean
	showModal:boolean
	showForm:any
}

export default class Form extends React.PureComponent<IProps, State<FormAPP>> {
  state: State<FormAPP>= {
		data: {
      username: '',
      email: '',
			phone: '',
			message:''
		},
    validation: {
      username: false,
      email: false,
      phone: false,
			message:false
		},
		isFormValid:false,
		spinner:false,
		showAlert:false,
		showModal:true,
		showForm:''
	}
	
	componentDidMount (){
		const form = localStorage.getItem('formLock') ? localStorage.getItem('formLock') : ''
		if(form){
			this.setState({
				showAlert:true,
				showForm:true,
				showModal:false,
			})
		}
	}

	onChangeInput = (type: keyof FormAPP, value: FormAPP[keyof FormAPP]) => {

		this.setState(
      produce(this.state, draft => {
        switch (type) {
          case 'username':
						draft.data[type] = value
						draft.validation[type] = value.length > 0
            break
          case 'phone':
            draft.data[type] = value
            draft.validation[type] = validatePhone(value)
            break
          case 'email':
            draft.data[type] = value
            draft.validation[type] = validateEmail(value)
            break
          case 'message':
            draft.data[type] = value
            draft.validation[type] = value.length > 0
            break
				}
				
        let allAreTrue = true
          ; (Object.keys(draft.validation) as Array<keyof typeof draft.validation>).forEach(key => {
            const field = draft.validation[key]
            if (field === false) {
              allAreTrue = false
            }
          })
        draft.isFormValid = allAreTrue
      }),
    )
	}
	
	applyFor = async () =>{
    const {
      data: { username, phone, email, message}
    } = this.state

    this.setState({
      spinner:true
    })

	 const emailInternal=  process.env.REACT_APP_CONTACT_FORM_EMAIL
	 const subject="[Usuario - Bloqueado] - Usuario Bloqueado"
    const result: any = await sendForm( username, phone, email, emailInternal, message, subject)

    if(result && result.status === 200){
			localStorage.setItem('formLock', JSON.stringify(true))
			this.setState({
				isFormValid:false,
				spinner:false,
				showAlert:true,
				showModal:false,
        data: {
					username:'',
					phone:'',
					email:'',
					message:''
        },
        validation: {
					username: false,
					email: false,
					phone: false,
					message:false
				},
				
      })
    }
	}
	
	signOut = async () => {
		const { user, cartModel } = this.props
    
    await cartModel.clearCart()
		await user.signOut()
	}

  render() {
		const {
			data: {  phone, email, username, message },
			spinner, showAlert, showModal,showForm
		} = this.state

    return (
			<IonContent >
				{!showForm && <IonModal className="modal-user-lock1" backdropDismiss={false}  isOpen={showModal}>
				<div className="content">
					<div className="header">
						<IonIcon src={closeMenuIcon} onClick={e => this.signOut()}  />
					</div>
						<div className="body">
							<div className='body-img' style={{ backgroundImage: `url(${problem})` }}  ></div>
						</div>
						<div className='container-info-productModal'>
              <div className='container-title-modal'>Lo Sentimos</div>
							<div className='container-subtitle-modal'>ACCESO RESTRINGIDO</div>
							<div className='container-text-modal'>Déjanos tus datos y nos pondremos</div>
							<div className='container-text-modal'>en contacto contigo a la brevedad</div>
          	</div> 
						<div className='container-input' >
						<IonItem mode="md" className={this.state.validation.username ? 'item-valid' : ''} >
							<IonAvatar className="avatar" slot="start" >
									<img className="img-avatar" src={icn_user_name } alt="icono username"/>
							</IonAvatar>
							<IonInput
								placeholder={'Nombre'}
								value={username}
								onIonChange={e => this.onChangeInput('username', e.detail.value || '')}
								type='text' 							>
							</IonInput>
							<IonIcon size="small" icon={checkmarkCircle} slot="end"  />
						</IonItem>
						<IonItem slot="start" mode="md" className={this.state.validation.phone ? 'item-valid' : ''} >
							<IonAvatar className="avatar"  >
								<img className="img-avatar" src={icn_phone} alt="icono telefono"/>
							</IonAvatar>
							<IonLabel >+56 9</IonLabel>
							<IonInput
								value={phone}
								onIonChange={e => this.onChangeInput('phone', e.detail.value || '')}
								type="tel"
								required={true}
								maxlength={8} 							>
							</IonInput>
							<IonIcon size="small" icon={checkmarkCircle} slot="end"  />
						</IonItem>
						<IonItem slot="start" mode="md" className={this.state.validation.email ? 'item-valid' : ''} > 
							<IonAvatar className="avatar" slot="start" >
								<img className="img-avatar" src={icn_email} alt="Email"/>
							</IonAvatar>
							<IonInput
								value={email}
								onIonChange={e => this.onChangeInput('email', e.detail.value || '')}
								type="email"
								placeholder={"Email"} 							>
							</IonInput>
							<IonIcon size="small" icon={checkmarkCircle} slot="end"  />
						</IonItem>
						<div className="container-textarea"> 
							<div className='message'>Mensaje</div>
							<IonTextarea 
								value={message}
								onIonChange={e => this.onChangeInput('message', e.detail.value || '')}
								placeholder={"Escribe algo aquí..."} 							>
							</IonTextarea>
						</div>
          </div>
					<div className='footer'>
						<IonButton 
							expand="block"
							disabled={!this.state.isFormValid}
							onClick={this.applyFor} 							>
							ENVIAR
						{spinner &&
              <IonSpinner 
								name="crescent"               />}
							</IonButton>
					</div>
				</div>
    	</IonModal>}
			<IonModal className="modal-restricted-access1" backdropDismiss={false}  isOpen={showAlert}>
				<div className="content-restricted-access">
					<div className="header">
						<IonIcon src={closeMenuIcon} onClick={e => this.signOut()}  />
					</div>
					<div className='container-title-restricted-access'>Gracias</div> 	
					<div className='subTitle-restricted-access'>Tus datos han sido recibidos, pronto
								nos comunicaremos contigo.</div>
					<div className='footer-restricted-access'>ACCESO RESTRINGIDO</div>
				</div>
			</IonModal>
			</IonContent>
    )
  }
}
