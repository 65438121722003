import { GuruSlider, GuruSlide } from '../../../../components'
import React from 'react'
import BrandIcon from '../../../../components/brand-icon/BrandIcon'
import { REACT_APP_BFF_IMAGE } from '../../../../config'
import './index.scss'

interface Category {
  _id: string
  name: string
  logo: string
}

const categories: Category[] = [
  {
    _id: '5d689399754b990022d3dfb3',
    name: 'Despensa',
    logo: 'icn_despensa.svg',
  },
  {
    _id: '5d689399754b990022d3dfb3',
    name: 'Despensa',
    logo: 'icn_despensa.svg',
  },
  {
    _id: '5d689399754b990022d3dfb3',
    name: 'Despensa',
    logo: 'icn_despensa.svg',
  },
  {
    _id: '5d689399754b990022d3dfb3',
    name: 'Despensa',
    logo: 'icn_despensa.svg',
  },
  {
    _id: '5d689399754b990022d3dfb3',
    name: 'Despensa',
    logo: 'icn_despensa.svg',
  },
  {
    _id: '5d689399754b990022d3dfb3',
    name: 'Despensa',
    logo: 'icn_despensa.svg',
  },
  {
    _id: '5d689399754b990022d3dfb3',
    name: 'Despensa',
    logo: 'icn_despensa.svg',
  },

  {
    _id: '5d689399754b990022d3dfb3',
    name: 'Despensa',
    logo: 'icn_despensa.svg',
  },
  {
    _id: '5d689399754b990022d3dfb3',
    name: 'Despensa',
    logo: 'icn_despensa.svg',
  },
  {
    _id: '5d689399754b990022d3dfb3',
    name: 'Despensa',
    logo: 'icn_despensa.svg',
  },
  {
    _id: '5d689399754b990022d3dfb3',
    name: 'Despensa',
    logo: 'icn_despensa.svg',
  },

  {
    _id: '5d689399754b990022d3dfb3',
    name: 'Despensa',
    logo: 'icn_despensa.svg',
  },
  {
    _id: '5d689399754b990022d3dfb3',
    name: 'Despensa',
    logo: 'icn_despensa.svg',
  },
]

function CategoriesHomeFulfillment() {
  return (
    <div className="categories-home-fulfillment">
      <h3>Categorías</h3>

      <div className="slides">
        <GuruSlider 
          pager={false} 
          scrollbar={false} 
          autoHeight={true}
          slidesPerView='auto'
        >
          {categories.map((category, index) => (
            <GuruSlide key={index} className="slide-home-brand">
              <BrandIcon urlLogo={`${REACT_APP_BFF_IMAGE}categories/${category.logo}`} handleAction={() => {}} />
              <span>{category.name}</span>
            </GuruSlide>
          ))}
        </GuruSlider>
      </div>
    </div>
  )
}

export default CategoriesHomeFulfillment
