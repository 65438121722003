const deepLinkRoutes = [
  {
    routeContains: ['provider', 'category'],
    route: '/home',
    paramsAt: [1, 3],
    paramNames: ['vendorId', 'categoryId'],
  },
  {
    routeContains: ['provider', 'category', 'subcategory'],
    route: '/home',
    paramsAt: [1, 3, 5],
    paramNames: ['vendorId', 'categoryId', 'subcategoryId'],
  },
  {
    routeContains: ['vendor', 'product'],
    route: '/vendor/param/product/param',
    paramsAt: [1, 3],
    paramNames: ['vendorId', 'productId'],
    additionalParams: { isOpen: true },
    dataRequestParamIndex: 3,
    dataRequestFunctionName: 'getProduct',
    dataRequestParamName: 'product'
  },
  {
    routeContains: ['home-providers'],
    route: '/home-providers/param',
    paramsAt: [1],
    additionalParams: {},
    paramNames: ['providerId'],
  },
  {
    routeContains: ['category'],
    route: '/category',
    paramsAt: [1],
    additionalParams: {},
    paramNames: ['categoryId'],
  },
  {
    routeContains: ['order'],
    route: '/orders',
    paramsAt: [1],
    additionalParams: {},
    paramNames: ['orderId'],
  },
  {
    routeContains: ['profile'],
    route: '/profile',
    paramsAt: [1],
    additionalParams: {},
    paramNames: ['deeplinkGuruPro'] 
  },
  {
    routeContains: ['vendor', 'brand'],
    route: '/vendor/param/brand/param',
    paramsAt: [1, 3],
    paramNames: ['vendorId', 'brandId'],
  },
];
const simpleDeeplinks = [
  { text: 'hashtag', routesLength: 2 }
]

export const getDeepLinkRouteData = async (url:any, dependencies:any[]) => {
  const homeRoute = { route: "/home", params: {}};
  const cleanRoute = url.replace(`${process.env.REACT_APP_DEEPLINKS}/`, '');
  const routeParts = cleanRoute.split('/');

  if (routeParts.length === 0) return homeRoute;
  if (routeParts.length === 1) return { route: `/${routeParts[0]}`, params: {}};

  const simpleDeeplinkMatch = simpleDeeplinks.find(({ text, routesLength }: any) => (
    routeParts.includes(text) && routeParts.length === routesLength
  ))
  if (simpleDeeplinkMatch) return { route: `/${cleanRoute}` };

  //* Se encarga de validar si en la ruta contiene los parametros necesarios para una ruta especial. (Ejemplo: Si app.almacenguru.cl/vendor/param/product/param)
  //* En el arreglo de rutas deberia coincidir con los dos parametros en routeContains.
  const routeMatch = deepLinkRoutes.find((deepLinkRoute) => {
    const validLength = deepLinkRoute.routeContains.length >= Math.ceil(routeParts.length / 2);

    return deepLinkRoute.routeContains.every((param:any) => routeParts.includes(param)) && validLength
  });

  if (!routeMatch) return homeRoute;
  let finalRoute = routeMatch.route;
  let finalParams:any = {};
  //* Se encarga de sustituir los param de la ruta, por los valores que estan en paramsAt
  for (let i=0; i < routeMatch.paramsAt.length; i++) {
    finalRoute = finalRoute.replace('param', routeParts[routeMatch.paramsAt[i]]);
    finalParams[routeMatch.paramNames[i]] = routeParts[routeMatch.paramsAt[i]];
  }
  //* Valida si necesita una funcion para sacar los parametos a enviar
  if (routeMatch.dataRequestFunctionName) {
    const dependencyMatch = dependencies.find((dependency) => dependency.name === routeMatch.dataRequestFunctionName);
    if (dependencyMatch) {
      const response = await dependencyMatch.get(routeParts[routeMatch.dataRequestParamIndex])
      finalParams[routeMatch.dataRequestParamName] = response;
    }
  }

  return { route: finalRoute, params: { ...finalParams, ...routeMatch.additionalParams, from:'fromDeeplink' }};
  
}