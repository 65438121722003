import React, { ReactElement } from 'react'
import { ContextProvider } from './AppContext'
import CartProvider from './providers/CartProvider'
import CountryInfoProvider from './providers/CountryInfoProvider'
import CouponsActivatedProvider from './providers/CouponsActivatedProvider'
import PlatformProvider from './providers/PlatformProvider'

interface Props {
  children: ReactElement
}

function AppProvider({ children }: Props) {
  const platformProvider = PlatformProvider()
  const cartProvider = CartProvider()
  const couponsActivatedProvider = CouponsActivatedProvider()
  const countryInfoProvider = CountryInfoProvider()

  

  return (
    <ContextProvider
      value={{
        ...platformProvider,
        ...cartProvider,
        ...couponsActivatedProvider,
        ...countryInfoProvider,
      }}
    >
      {children}
    </ContextProvider>
  )
}

export default AppProvider
