import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { GuruSlider, GuruSlide } from '../../components';
import { VisibilityTracker } from '../../hoc';
import { registerPromotionBannerSliderItemEvent } from '../firebase/firebaseTags';
import { useGetSessionId } from '../../hooks/useGetSessionId';
import { manageBannerClick } from '../../utils/deeplinks';

import './BannersProvider.scss';

interface Banner {
  _id: string;
  name: string;
  type: string;
  providerId: string;
  providerName: string;
  image: {
    url: string;
  };
}

interface BannerProviderProps {
  slidesPerView?: number;
  banners: Banner[];
  nameProvider: string;
  placementName?: string;
}

const BannerProvider: React.FC<BannerProviderProps> = ({
  banners,
  nameProvider,
  placementName,
}) => {
  const history = useHistory();
  const isOnePromo = banners.length === 1;

  const onBannerClick = useCallback(async (bannerPromotion: Banner) => {
    registerPromotionBannerSliderItemEvent({
        eventName: `${placementName}_carousel_ad_banner_click`,
        bannerId: bannerPromotion._id,
        bannerType: bannerPromotion.type,
        bannerName: bannerPromotion.name,
        vendorId: bannerPromotion.providerId,
        vendorName: bannerPromotion?.providerName ?? nameProvider,
        placementName: placementName,
    })

    // trackBannersActions(bannerClick, bannerType, nameProvider, _id, 'vendor', name);
    // await counterEvent('banner-provider', bannerPromotion.type, userId, bannerPromotion._id, 'click');

    manageBannerClick(bannerPromotion, history);
  }, [nameProvider, history]);

  const onBannerSlideItemView = useCallback((bannerPromotion: Banner) => {
    registerPromotionBannerSliderItemEvent({
      eventName: `${placementName}_carousel_ad_banner_view`,
      bannerId: bannerPromotion._id,
      bannerType: bannerPromotion.type,
      bannerName: bannerPromotion.name,
      vendorId: bannerPromotion.providerId,
      vendorName: bannerPromotion.providerName,
      placementName,
    });
  }, [placementName]);

  return (
    <div className="banners-provider-slider">
      <GuruSlider 
        pager={false}
        allowTouchMove={!isOnePromo}
        slidesPerView={isOnePromo ? 1.1 : 1.2}
        slidesOffsetBefore={19}
        slidesOffsetAfter={19}
        spaceBetween={19}
        autoplay={
          isOnePromo ? false : {
            delay: 2500 + (Math.random() * 2000),
            disableOnInteraction: true,
          }
        }
      >
        {banners.map((banner: Banner, index: number) => (
          <VisibilityTracker key={banner._id} onVisible={() => onBannerSlideItemView(banner)} threshold={0.3}>
            <GuruSlide 
              className={`slide-${index} banners-provider-slide`}
              dataProviderName={nameProvider}
              dataBannerId={banner._id}
              dataBannerName={banner.name}
            >
              <img 
                src={`${process.env.REACT_APP_BFF_IMAGE}${banner?.image?.url}`} 
                alt="Banner Imagen" 
                onClick={() => onBannerClick(banner)}  
                className="promotion-img-provider"
              />
            </GuruSlide>
          </VisibilityTracker>
        ))}
      </GuruSlider>
    </div>
  );
};

export default BannerProvider;