import React from 'react'
import {
  IonContent,
  IonPage,
} from '@ionic/react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import track from 'react-tracking'
import UserModel from '../../models/User'
import SettingsModel from '../../models/Settings'
import { loginv2 } from '../../clients/login'
import { recoveryPaswordUser } from '../../clients/validateSms'
//ICONS
import HeaderWithoutToolbar from '../../components/header-without-toolbar/HeaderWithoutToolbar'
import GuruHelper from '../../components/guru-helper/GuruHelper'
import arrowBack from './../../assets/icons/arrow-back-blue.svg'
import ButtonComponent from '../../components/basic-button/ButtonComponent'
import TextInput from '../../components/input-form/TextInput'
import GuruAcceptModal from '../../components/modal-action-accept/ModalActionAccept'
import ContactSupport from '../../components/contact-support/ContactSupport'

import './RecoveryPasswordConfirm.scss'

type IPathParams = {}

type Props = RouteComponentProps<IPathParams> & {
  user: UserModel
  settings: SettingsModel
  tracking?: any
}

interface Form {
  password: string,
  confirm_password: string
}

interface State<T> {
  data: any
  validation: { [name in keyof T]: boolean }
  isFormValid: boolean
  spinner: boolean
  isOpen: boolean
}

class RecoveryPasswordConfirm extends React.PureComponent<Props, State<Form>> {
  state: State<Form> = {
    data: {
      password: '',
      confirm_password: ''
    },
    validation: {
      password: false,
      confirm_password: false
    },
    isFormValid: false,
    spinner: false,
    isOpen: false
  }

  onChangePassword = (event:any) => {
    this.setState({
      data: {
        ...this.state.data,
        password: event.target.value,
      }
    })
  }

  onChangeConfirmPassword = (event:any) => {
    this.setState({
      data: {
        ...this.state.data,
        confirm_password: event.target.value,
      }
    })
  }

  onUpdateBtnClicked = async () =>{
    const { data: {
      confirm_password
    } } = this.state

    this.setState({spinner:true})
    const infoUser: any = this.props.history.location.state
    const result:any = await recoveryPaswordUser(confirm_password, infoUser.token.access_token)

   if(result && result.passwordChanged){
     try {
      let loginResult: any = await loginv2(infoUser.userName, confirm_password)
      this.setState({spinner:true})
      await this.props.user.authenticateWith(loginResult)
      await this.props.user.editCacheUser(loginResult.user)
      loginResult = {...loginResult,'recoveryPassword':true}
      localStorage.setItem("recoveryPassword", JSON.stringify(true))
      this.setState({isOpen:false})
      this.props?.history?.push('/home', Object.assign(loginResult, infoUser.userName,))
     } catch (error) {
       console.log(error)
     }
    }
  }

  render() {
    const { data, isOpen } = this.state
    const { password, confirm_password } = data
    const { history } = this.props
    return (
      <IonPage className="confirm-page" >
        <IonContent className="container-recovery-password-confirm" >
          <HeaderWithoutToolbar 
            icon={arrowBack}
            text="Recupera tu acceso"
            onClick={history.goBack}
          />
          <GuruHelper 
            label="Crea tu nueva contraseña"
            text="Ten en cuenta que sea una contraseña segura y fácil de recordar."
          />
          <div className="form-container">
            <TextInput 
              iconDefault="icn_password" 
              iconInvalid="icn_password-red"
              iconValid="icn_password-blue"
              onChange={this.onChangePassword} 
              placeholder="Ingresa tu contraseña"
              label="Contraseña"
              isValid={password.length > 3}
              error={"La contraseña debe tener de 4 a 12 caracteres"}
              value={password}
              toValidate={password}
              type="password"
              maxLength={12}
              autoComplete="off"
            />

            <TextInput 
              iconDefault="icn_password" 
              iconInvalid="icn_password-red"
              iconValid="icn_password-blue"
              onChange={this.onChangeConfirmPassword} 
              placeholder="Repite tu contraseña"
              label="Confirmar Contraseña"
              isValid={confirm_password.length && password === confirm_password}
              error={"Recuerda que las contraseñas deben coincidir"}
              value={confirm_password}
              toValidate={confirm_password}
              type="password"
              maxLength={12}
              autoComplete="off"
            />
          </div>
          <div className="container-btn-validate">
            <ButtonComponent 
              onClick={() => this.setState({isOpen:true})}
              text="Enviar"
              disabled={!password.length || !confirm_password || password !== confirm_password}
              className="btn-primary"
            />
          </div>
        </IonContent>
        <GuruAcceptModal
          className="btn-primary"
          textButton="Ingresa"
          label="¡Súper! Ya puedes ingresar"
          onClickButton={this.onUpdateBtnClicked}
          disabled={false}
          isOpen={isOpen}
        />
        <div className="container-help" >
          <ContactSupport />
        </div>
      </IonPage>
    )
  }
}

export default track({ page: 'RecoveryPasswordConfirm' })(withRouter(RecoveryPasswordConfirm))

