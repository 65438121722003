import Axios from 'axios'

export interface Category {
  _id: string
  active: boolean
  categoryId: string
  main: boolean
  name: string
  order: number
  tags: string []
}

export interface CategoryWithProductsIn {
  _id: string
  active: boolean
  main: boolean
  name: string
  order: number
  tags: string []
  products?: ProductIn[]
}

export interface LadderStep {
  quantity: number
  discount: number
}

export interface ProductIn {
  UOM: string
  _id: string
  sku: string
  name: string
  description: string
  category: string
  subcategory: string
  units: number
  providerId: string
  active: true
  price: number
  offerPrice?: number
  filename?: string
  brand: string
  package: string
  ladder?: LadderStep[]
  showPrice: any
  vendorActiveInComune: boolean
  infoPrice: any
  paid?:boolean
  imageFullPath?: string
  quantityMin:number
  quantityMax:number
  categoryId: string
  categoryTree: any
}

export const find = async (skip: any, limit: any) => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/categories`, {
      params: {
        filter: JSON.stringify({
          skip,
          limit,
        }),
      },
    })

    return result.data
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const withProducts = async (limit: any, filter: any, providerId: any = null, brandId: any = null) => {
  try {
    var result
    if (brandId && brandId !== undefined) {
      result = await Axios.get<CategoryWithProductsIn[]>(
        `${process.env.REACT_APP_BFF_NODE}/v1/brands/with_products/${brandId}`,
        {
          params: {
            filter: JSON.stringify(filter),
            limitProducts: limit,
            providerId: providerId,
          },
        },
      )
    } else {
      result = await Axios.get<CategoryWithProductsIn[]>(
        `${process.env.REACT_APP_BFF_NODE}/v1/categories/with_products`,
        {
          params: {
            filter: JSON.stringify(filter),
            limitProducts: limit,
            providerId: providerId,
          },
        },
      )
    }
    const data = result.data.sort((c1, c2) => c1.order - c2.order)
    return data
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const getById = async (id: any, brandId: any = null) => {
  try {
    var result
    if (brandId && brandId !== undefined) {
      result = await Axios.get<CategoryWithProductsIn[]>(
        `${process.env.REACT_APP_BFF_NODE}/v1/categories/${id}/${brandId}`,
        {
          params: {
            filter: JSON.stringify({}),
          },
        },
      )
    } else {
      result = await Axios.get<CategoryWithProductsIn[]>(`${process.env.REACT_APP_BFF_NODE}/v1/categories/${id}`, {
        params: {
          filter: JSON.stringify({}),
        },
      })
    }

    const categories = result.data.map((category: any) => {
      if (category.id === 0) {
        category.order = -1
      }
      return category
    })
    const data = categories.sort((c1: any, c2: any) => c1.order - c2.order)

    return data
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const search = async (query: String, categoryId?: String) => {
  try {
    const result = await Axios.get<CategoryWithProductsIn[]>(`${process.env.REACT_APP_BFF_NODE}/v2/vendors/search`, {
      params: {
        query,
        categoryId,
      },
    })
    const categories = result.data.map((category: any) => {
      if (category.id === 0) {
        category.score = -1
      } else {
        category.score = category.products.reduce(function(acc: number, product: any) {
          return acc + product.score
        }, 0)
      }
      return category
    })
    const data = categories.sort((c1: any, c2: any) => c2.score - c1.score)
    const index = categories.findIndex((category:any) => category._id === process.env.REACT_APP_DESPACHO)
    if (index > 0) {
      const temp = categories[0]
      categories[0] = categories[index]
      categories[index] = temp
    }
    return data
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const count = async () => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/categories/count`)
    return result.data.count
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const brand = async () => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/categories/showCategoriesBrands`)
    return result.data
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const providersBrand = async (idProviders: any) => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/vendors/${idProviders}/brands`)
    return result.data
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const offersProviders = async (idProviders: any) => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v2/vendors/offersByVendor/${idProviders}?page=1&limit=12`)
    return result.data
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const providerTopCarousel = async (providerId:any, page?:number) => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/vendors/outstandingProductsByVendorAndCategory/${providerId}?page=${page || 1}&limit=12`)
    return result.data
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const filterByBrandsCategories = async (idProviders: any) => {
  try {
    const result = await Axios.get<any>(
      `${process.env.REACT_APP_BFF_NODE}/v1/vendors/productsByVendorAndBrand/${idProviders}/0`,
    )
    return result.data
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>>', error, error.message)
    throw error
  }
}

export const categoriesProvider = async (idProviders: any, typeCategory: any) => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/vendors/${idProviders}/categories`, {
      params: {
        category: typeCategory,
      },
    })
    return result.data
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>> ', error, error.message)
    throw error
  }
}

export const categoriesProductProvider = async (idCategories: any, providerId: any, page: number) => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/categories/${idCategories}/products`, {
      params: {
        limit: 9,
        page: page,
        detail: true,
      },
      headers: {
        providerid: providerId,
      },
    })
    return result.data
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>> ', error, error.message)
    throw error
  }
}

export const brandProvider = async (id: any) => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/vendors/${id}/brands`)
    return result.data
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>> ', error, error.message)
  }
}

export const pagesProviderCategory = async (idProviders: any) => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/vendors/${idProviders}/categories`)
    return result.data
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>> ', error, error.message)
  }
}

export const providerOffers = async (categoryId: string, singleProviderId: string, userName: string) => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/vendors/${singleProviderId}/offers`, {
      params: {
        category: categoryId,
      },
      headers: {
        username: userName,
      },
    })
    return result.data
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>> ', error, error.message)
  }
}

export const tagsCatregory = async (nameTags: string, page: number, providerId: string) => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/tags/${nameTags}/products`, {
      params: {
        limit: 10,
        page: page,
        detail: true,
      },
      headers: {
        providerid: providerId,
      },
    })
    return result.data
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>> ', error, error.message)
  }
}

export const findCategoriesAllProviders = async (id: string) => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/categories/findCategoriesProviders/${id}`)
    return result.data
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>> ', error, error.message)
  }
}

export const brandProvidersSlider = async (vendorId: any) => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/brands/showcase?providerId=${vendorId}`)
    return result.data
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>> ', error, error.message)
    return null;
  }
}

export const categoryTreeOfVendor = async (brandId:string,vendorId: string) => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/brands/${brandId}/categories?providerId=${vendorId}`)
    return result.data
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>> ', error, error.message)
  }
}

const validateIfExistsProperty = (page:number, limit:number = 12 , providerId?:string, brandId?:string, categoryId?:string, onlyOffers:boolean = false) => {
  const provider = providerId ? `providerId=${providerId}&` : '';
  const category = categoryId ? `categoryId=${categoryId}` : '';
  const brand = brandId ? `brandId=${brandId}&` : '';
  const offers = onlyOffers ? `&onlyOffers=true` : '';
  const limitPage = `limit=${limit}&`
  const pageProducts = `page=${page}&`
  
  return `${process.env.REACT_APP_BFF_NODE}/v3/products?${pageProducts}${limitPage}${provider}${brand}${category}${offers}`
}

export const getProducts = async (page:number,providerId?:string, brandId?:string, categoryId?:string, onlyOffers:boolean = false) => {
  try {
    const result = await Axios.get<any>(validateIfExistsProperty(page, 12, providerId, brandId, categoryId, onlyOffers))
    return result.data
  } catch (error) {
    if (error instanceof Error) console.log('Error: =>>>>>>> ', error, error.message)
  }
}

export const vendorsWithOffers = async (categoryId?:string) => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/vendors/offers`, {
      params: {
        categoryId
      }
    })
    return result.data
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>> ', error, error.message)
  }
}

export const categoriesWithOffers = async () => {
  try {
    const result = await Axios.get<any>(`${process.env.REACT_APP_BFF_NODE}/v1/categories/offers`)
    return result.data
  } catch (error) {
    if (error instanceof Error) console.error('Error: =>>>>>>> ', error, error.message)
  }
}
