import React from 'react'
import ErrorList from './ErrorList'
import { 
  IoEyeOutline, 
  IoEyeOffOutline, 
  IoCheckmarkCircleOutline, 
  IoAlertCircleOutline,
  IoHelpCircleOutline
} from 'react-icons/io5'
import './TextInput.scss'

interface TextInputProps {
  iconValid?: string
  iconInvalid?: string
  iconDefault?: string
  isValid?: boolean
  placeholder?: string
  label?: string
  error?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onFocus?: () => void
  value: string
  toValidate?: string | number | undefined
  maxLength?: number
  type: string
  autoComplete?: string
  required?: boolean
  isEmptyValidStyle?: boolean
  informativeText?: string
  showEyeIcon?: boolean
  showPassword?: boolean
  handleShowPassword?: () => void
}

const inputValidation = (
  isValid: boolean | undefined,
  value: string | number | undefined,
  toValidate: string | number | undefined,
  isEmptyValidStyle?: boolean | undefined,
) => {
  const styleValidate = isEmptyValidStyle !== undefined ? isEmptyValidStyle : true
  const inputIsValid = value && isValid
  const inputIsEmpty = toValidate === ''

  if (!styleValidate && inputIsEmpty) return 'invalid'
  if (inputIsEmpty || inputIsValid) return 'valid'
  return 'invalid'
}

const getIconComponent = (iconName: string) => {
  switch (iconName) {
    case 'checkmarkCircleOutline':
      return IoCheckmarkCircleOutline
    case 'alertCircleOutline':
      return IoAlertCircleOutline
    case 'helpCircleOutline':
      return IoHelpCircleOutline
    default:
      return null
  }
}

const TextInput: React.FC<TextInputProps> = ({
  isValid,
  placeholder,
  label,
  error,
  onChange,
  onFocus,
  iconValid = 'checkmarkCircleOutline',
  iconDefault = 'helpCircleOutline',
  iconInvalid = 'alertCircleOutline',
  value,
  toValidate,
  maxLength,
  type,
  autoComplete,
  required,
  isEmptyValidStyle,
  informativeText,
  showEyeIcon,
  handleShowPassword,
  showPassword,
}) => {
  const renderInfoText = () => {
    if (!informativeText) return null

    return (
      <div className="informative-text-container">
        <p>{informativeText}</p>
      </div>
    )
  }

  const getIconName = () => {
    if (toValidate === '') return iconDefault
    if (value && isValid) return iconValid
    return iconInvalid
  }

  const IconComponent = getIconComponent(getIconName())

  return (
    <div className="container-input">
      <div className="container-label-icon">
        {IconComponent && (
          <div className="icon-label">
            <IconComponent />
          </div>
        )}
        <label className={inputValidation(isValid, value, toValidate, isEmptyValidStyle)}>{label}</label>
      </div>
      <div className="container-input-text">
        <input
          onClick={onFocus}
          onChange={onChange}
          type={type}
          maxLength={maxLength || 100}
          value={value}
          className={`form-input-${inputValidation(isValid, value, toValidate, isEmptyValidStyle)}`}
          placeholder={placeholder}
          autoComplete={autoComplete || 'on'}
          required={required || false}
        />
        <div className="container-alert-icon">
          {showEyeIcon && (
            <div onClick={handleShowPassword} className="eye-icon">
              {showPassword ? <IoEyeOutline /> : <IoEyeOffOutline />}
            </div>
          )}
        </div>
      </div>
      {renderInfoText()}
      {inputValidation(isValid, value, toValidate, isEmptyValidStyle) === 'invalid' && error && (
        <ErrorList rule={error} />
      )}
    </div>
  )
}

export default TextInput