import React, { useState, useEffect } from 'react'
import { IonPage } from '@ionic/react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { StaticContext } from 'react-router-dom'
import track from 'react-tracking'
import { GuruBottomSheetModal } from '../../components'
import './ShippingGuru.scss'
import moment from 'moment'
import ButtonComponent from '../../components/basic-button/ButtonComponent'

interface IPathParams {}

interface RouteState {}

type IProps = RouteComponentProps<IPathParams, StaticContext> & {
  showGuruShipping: boolean
  closeGuruShipping: () => void
  arrProvidersShipping: any[]
  getArrShippingGuru: (arr: any[]) => void
  arSelectedProviderDate: any[]
  providersWithShippingGuru: number
}

const ShippingGuru: React.FC<IProps> = props => {
  const { 
    showGuruShipping, 
    closeGuruShipping, 
    arrProvidersShipping, 
    getArrShippingGuru, 
    arSelectedProviderDate, 
    providersWithShippingGuru 
  } = props

  const [arrProviderSelected, setArrProviderSelected] = useState<any[]>([])

  useEffect(() => {
    if (arSelectedProviderDate.length) setArrProviderSelected(arSelectedProviderDate)
  }, [arSelectedProviderDate])

  const clickDay = (id: string, date: string, day: string) => {
    setArrProviderSelected(prevArr => {
      const existProvider = prevArr.some(provider => provider._id === id && provider.date === date)
      
      if (!existProvider) {
        const providerIdDistinct = prevArr.filter(provider => provider._id !== id)
        return [...providerIdDistinct, { _id: id, date, day }]
      } else {
        return prevArr.filter(provider => !(provider._id === id && provider.date === date))
      }
    })
  }

  const handleConfirm = () => {
    getArrShippingGuru(arrProviderSelected)
    closeGuruShipping()
  }

  return (
    <IonPage>
      <GuruBottomSheetModal
        isOpen={showGuruShipping}
        onClose={closeGuruShipping}
        customHeight={90}
      >
        <div className="shipping-guru-content">
          <div className="title-text">Elige el día</div>
          <div className="sheet-text paragraph-2">
            ¿Cuándo te gustaría recibir tu pedido?
          </div>
          <div className='time-Shipping'>
            <div className='time-title'>Horario de despacho</div>
            <div className='time'>10:00 a 18:00</div>
          </div>
          <div className='container-provider'>
            {arrProvidersShipping.map(provider => (
              <div className='provider' key={provider.name}>
                <div className='title-provider'>{provider.name}</div>
                <div className='container-day'>
                  {provider?.nextDays.slice(0, 5).map(day => {
                    const isActive = day.active
                    const existProviderAndDate = arrProviderSelected.some(exist => 
                      exist._id === provider.providerId && exist.date === day.date
                    )
                    
                    return (
                      <div
                        key={day.date}
                        onClick={() => isActive && clickDay(provider.providerId, day.date, day.day)}
                        className={`day-provider ${isActive ? '' : 'inactive'} ${existProviderAndDate ? 'selected' : ''}`}
                      >
                        <div>{day.day.slice(0, 3)}</div>
                        <div>{moment(day.date).format('DD')}</div>
                      </div>
                    )
                  })}
                </div>
              </div>
            ))}
          </div>
          <div className='container-button'>
            <ButtonComponent 
              className="btn-primary"
              onClick={handleConfirm}
              text="Confirmar"
              disabled={arrProviderSelected.length !== providersWithShippingGuru}
            />
          </div>
          <div className='info-footer'>
            Las fechas podrían ser modificadas según la necesidad del operador logístico, pero tranquilo <strong>SIEMPRE</strong> te informaremos antes.
          </div>
        </div>
      </GuruBottomSheetModal>
    </IonPage>
  )
}

export default track({ page: 'ShippingGuru' })(withRouter(ShippingGuru))