import React  from 'react'
import { RouteComponentProps } from 'react-router-dom'
import {
  IonImg,
} from '@ionic/react'
import arrowBack from './../../assets/icons/arrow-back-blue.svg'
import { close } from 'ionicons/icons'
import IconButtonComponent from '../../shared/button-icon/ButtonIcon'

import './ImgLoginComponent.scss'

interface IPathParams {}

type Props = RouteComponentProps<IPathParams> & {
  img: any
  text: string
  arrowBackOnclik: boolean
  from?: string
  closeRedirectLink?: any
  closeLinkLocationState?: any
}

interface State {
  from: string
}
export default class ImgLoginComponent extends React.PureComponent<Props, State> {
  state: State = {
    from: this.props.from || ''
  }

  validateFromSurvey = (fromValue:string) => fromValue === 'survey-nps'

  onCloseClick = () => {
    const { closeRedirectLink, closeLinkLocationState, history } = this.props
    if (closeRedirectLink && closeLinkLocationState) return history.push(closeRedirectLink, closeLinkLocationState)
    if (closeRedirectLink) return history.push(closeRedirectLink)
    history.push('/orders')
  }

  render() {
    const { img, text, arrowBackOnclik, history } = this.props
    const { from } = this.state
    const hasBorder = this.validateFromSurvey(from) ? '' : 'has-border'
    const spaceBetweenImage = this.validateFromSurvey(from) ? 'diferent-margin' : ''
    const imageSize = this.validateFromSurvey(from) ? 'img-survey' : ''

    return (
      <div className="go-back">
        <div className="header">
            <div className="back-btn">
              {arrowBackOnclik && <IconButtonComponent iconButton={arrowBack} onClickAction={history.goBack}/>}
            </div>
            <div className="close-btn">
              {this.validateFromSurvey(from) && <IconButtonComponent iconButton={close} onClickAction={this.onCloseClick}/>}
            </div>
        </div>
        <div className={`container-img ${hasBorder}`}> 
          <div>
            <IonImg className={`img-validate-user ${spaceBetweenImage} ${imageSize}`} src={img}  /> 
            <div className="title-validate-user">{text}</div>
          </div>
        </div>
      </div>
    )
  }
}


