import React, { useState, useEffect } from 'react'
import {
  IonPage,
  IonContent,
} from '@ionic/react'
import User from '../../models/User'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { StaticContext } from 'react-router-dom'

//Img

//Models
import { ProviderModel } from '../../models/ProviderModel'
import Office from '../../models/Office'
import { CartModel} from '../../models/CartModel'
import Settings from '../../models/Settings'

//Components
import ToolBar from '../../components/tool-bar/ToolBar'
import GlobalComponents from '../../components'

//Events
import { searchHomeProviderEvent, viewSearchResultsEvent } from '../../pages/firebase/firebaseTags'
import GuruWhiteScreen from '../../components/guru-white-screen/GuruWhiteScreen'
import LoadingComponent from '../../components/loading-component/LoadingComponent'

import './SearchProductsHomeProvider.scss'
import { navigateToCart } from '../../utils/navigation'


interface IPathParams {}


type IProps = RouteComponentProps<IPathParams, StaticContext> & {
  readonly user: User
  readonly offices: Office
  readonly cartModel: CartModel
  readonly providerModel: ProviderModel
  readonly settings: Settings
  isPaddingTop?: boolean
}

const mapProductFromSuggested = (product: any) => ({
  ...product, 
  provider: product.providerId,
  filename: product.imageFullPath ?? product.filename,
  vendorActiveInComune: product.active
})

const SearchProductsHomeProvider: React.FC<IProps> = props => {
  const [isFetching ] = useState<boolean>(true)
  const [resultProductSearch,  setResultProductSearch] = useState<any>([])
  const [querySearch,  setQuerySearch] = useState<any>('')
  const [noProduct,  setNoProduct] = useState<boolean>(false)
  const [loadingProduct,  setLoadingProduct] = useState<boolean>(false)
  const [hasSearched, setHasSearched] = useState<boolean>(false)
  const [providerName, setProviderName] = useState<string>('')

  useEffect(() => {
    getProvider()
    if(!isFetching) get()
  }, []) 

  const get = async () => {
    if(routeSplit()[4]){
      setLoadingProduct(true)
      const result:any = await props.providerModel.serchProductProvider(routeSplit()[2], routeSplit()[4])

      result && setLoadingProduct(false)
      setQuerySearch(routeSplit()[4])
      !result.data.length && setNoProduct(true)
      setResultProductSearch(result.data)
    }
  }

  const getProvider = async () => {
    try {
      const vendorId = routeSplit()[2]
      if (vendorId) {
        const providersString = localStorage.getItem('@providers')
        const providers = JSON.parse(providersString || '')
        const providerName = providers[vendorId].name
        if (providerName) setProviderName(providerName)
      }
    } catch(err) {
      console.error(err)
    }
  }
    
  const onChangeSearch = async (product:any) => {
    const vendorId = routeSplit()[2]
    searchHomeProviderEvent(product, 'home_vendor', vendorId, providerName)
    setHasSearched(true)
    setQuerySearch('')
    setResultProductSearch([])
    setLoadingProduct(true)

    const { location }: any = props.history
    if (location.state?.pathname) {
      props?.history?.push(`/vendor/${vendorId}/search/${product}`, location.state)
    } else {
      props?.history?.push(`/vendor/${vendorId}/search/${product}`)
    }


    const result:any = await props.providerModel.serchProductProvider(routeSplit()[2], product)

    result && setLoadingProduct(false)
    const productsFound = !Boolean(result.data.length)
    setNoProduct(productsFound)
    setResultProductSearch(result.data)
    viewSearchResultsEvent(product, result.data, 'home_vendor')
  }
   
  const renderToolbar = () => {
    return (
        <ToolBar
          title={''}
          showSearchBar={true}
          secondaryButtons={[{ type: 'back', onClick: goBack }]}
          secondaryHeader={true}
          boxShadow={true}
          searchBar={(
            <GlobalComponents.SearchBar 
            isBackgroundTransparent={true} 
            routeProvider={true}
            {...props} 
            searchText={querySearch} 
            onSearch={onChangeSearch} />)}
        />
      )
  }

  const showProductsSearch = () =>{
    const products =  resultProductSearch && resultProductSearch.length > 0 &&  resultProductSearch.find((item:any)=>{ return item})

     return(    
      products && products.products.map((product:any)=>{
      return (
        <GlobalComponents.ProductCard
          key={product.sku}
          settings={props.settings}
          product={mapProductFromSuggested(product)}
          cartModel={props.cartModel}
          onGoToCart={goToCart}
          history={props.history}
          />
      )}) 
     )
  }

  const loading = () => (
    <LoadingComponent />
  )

  const initialImage = () => ( 
    <GuruWhiteScreen 
      text="Puedes buscar por productos, marcas y proveedores"
    />
  )

  const notProduct = () => (
    <GuruWhiteScreen 
      text="¡Ups! Parece que no hay productos relacionados a tu búsqueda"
    />
  )

  const goBack = () => {
    const { location }: any = props.history

    if (location.state?.pathname) {
      const { data, pathname }: any = location.state
      props?.history?.push(pathname, data)

    } else {
      const providersLocalStorage = localStorage.getItem("@providers") 
      let providers:any = {}
  
      if(providersLocalStorage) providers = JSON.parse(providersLocalStorage)
      
      const provider:any  = Object.values(providers).find((item:any) => item.id ===  routeSplit()[2])
  
      props?.history?.push(`/home-providers/${routeSplit()[2]}`, {
        singleProviderId: provider.id,
        nameProvider: provider.alias,
        minumun: provider.minimun,
        banners: provider.banners,
        categoryId: provider.categoryId,
        vendorId: provider.vendorId,
      })
    }
  }

  const goToCart = () => navigateToCart(props.history, props.location.state || props.history.location.state);

  const routeSplit = () =>  props.history.location.pathname.split("/")

  return (
    <IonPage className="plp-page-search" >
      {renderToolbar()}
      <IonContent >
        <div>
          <div className="container-card-search-products">
            {showProductsSearch()}
          </div>
        </div>
        {!hasSearched && initialImage()}
        {loadingProduct && loading()}
        {!loadingProduct && noProduct && notProduct()}
      </IonContent>
    </IonPage>
  )
}

export default withRouter(SearchProductsHomeProvider)
