import React, { useState, useEffect } from 'react'
import { IonModal, IonContent } from '@ionic/react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { StaticContext } from 'react-router-dom'

//MODELS
import User from '../../models/User'
import { CartModel } from '../../models/CartModel'
import Office from '../../models/Office'
import { ProviderModel } from '../../models/ProviderModel'

//COMPONENTS
import ToolBar from '../../components/tool-bar/ToolBar'
import PaymentCards from './PaymentCards'
import ButtonComponent from '../../components/basic-button/ButtonComponent'

import './Payment.scss'

interface IPathParams {}

type IProps = RouteComponentProps<IPathParams, StaticContext> & {
  readonly user: User
  readonly offices: Office
  readonly cartModel: CartModel
  readonly providerModel: ProviderModel
  showModadalPaymentMethod: boolean
  closePaymentModal: any
  showPaymentType: any[]
  selectedPaymentOrderConfirmation: any
  cardsOneClick: any[]
  cardsBancoEstado: any[]
  isWebModal: boolean
  selectPayment: any
  totalShippingCost: number
}

const PaymentModal: React.FC<any> = (props) => {
  const [disabledBtn, setDisabledBtn] = useState<boolean>(true)
  const [selectPayment, setSelectPayment] = useState<any>('')
  const [spinner, setSpinner] = useState<boolean>(false)

  useEffect(() => {
    get()
  }, []) 

  const get = async () => {
    if (props.selectPayment) setDisabledBtn(false)
  }

  const goBack = async () => props.closePaymentModal()

  const onClickBtn = () => {
    setSpinner(true)
    goBack()
    props.selectedPaymentOrderConfirmation(selectPayment)
  }

  const selectedPayment = (paymentName: any) => {
    paymentName && setDisabledBtn(false)
    setSelectPayment(paymentName)
  }

  return (
    <IonModal className="container-payment-modal" backdropDismiss={false} isOpen={props.showModadalPaymentMethod}>
      <ToolBar
        title="Método de pago"
        secondaryButtons={[{ type: 'back', onClick: goBack }]}
        secondaryHeader={true}
        textCenter={true}
        boxShadow={true}
      />
      <IonContent >
        <PaymentCards
          showPaymentTypeCards={props.showPaymentType}
          selectedPayment={selectedPayment}
          oneClickCards={props.cardsOneClick}
          bancoEstadoCards={props.cardsBancoEstado}
          isWebModalCards={props.isWebModal}
          selectPaymentRadio={props.selectPayment}
          shippingCost={props.totalShippingCost}
          {...props}
        />
      </IonContent>
      <div className="confirmation-payment">
        <ButtonComponent
          className={`btn-primary  ${spinner ? 'loading' : ''}`}
          disabled={disabledBtn}
          onClick={() => onClickBtn()}
          text={'Confirmar'}
        />
      </div>
    </IonModal>
  )
}

export default withRouter(PaymentModal)
