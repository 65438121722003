import React from 'react'
import {
  IonAlert,
  IonPage,
  IonContent,
  IonButton,
  IonImg,
  IonRippleEffect,
  IonSpinner
} from '@ionic/react'
import { GuruSlider, GuruSlide } from '../../components'
import { uniq } from 'lodash'
import User from '../../models/User'
import Settings from '../../models/Settings'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { ProductModel, Product } from '../../models/ProductModel'
import { CouponsActivatedModel } from '../../models/CouponsActivatedModel'
import { CartModel } from '../../models/CartModel'
import { Coupon } from '../../models/Coupon'
import { groupBy } from '../../utils/arrays'
import { ProviderModel } from '../../models/ProviderModel'
import Office from '../../models/Office'
import SurveyModel from '../../models/Survey'
import { formatCurrency } from '../../utils/intl'
import { getSurveyByKey } from '../../utils/survey'
import { ordersPaidEvents } from '../../utils/firebaseEvents'
import { eventForCart, eventWithName } from '../firebase/firebaseTags'

import withoutProductsIcon from './../../assets/icons/empty_carro.png'
import Garbage from './../../assets/icons/icon_garbage.svg'
import ToolBar from '../../components/tool-bar/ToolBar'
import { getCartClient } from '../../clients/cart'
import CartController from '../cart-controller/CartController'
import GuruPro from '../guru-pro/GuruPro'
import OrderConfirmedModal from '../../components/order-confirmed-modal/OderConfirmedModal'
import { StaticContext } from 'react-router-dom'

import './Cart.scss'

import { Device } from '@capacitor/device';
import { navigateToCart } from '../../utils/navigation'
interface PastRouteinfo {
  route?: string
  paymentFormMethod?: string
  cartId?: string
}
interface RouteState {
  pastRouteInfo?: PastRouteinfo
  targetType: string
  targetId: string
  key?: string
}

interface IPathParams {
  statePay?: string
}

type IProps = RouteComponentProps<IPathParams, StaticContext ,RouteState> & {
  user: User
  readonly settings: Settings
  readonly productModel: ProductModel
  readonly offices: Office
  readonly cartModel: CartModel
  readonly providerModel: ProviderModel
  readonly couponsActivatedModel: CouponsActivatedModel
  readonly coupons: Coupon
  surveyModel?: SurveyModel
}

interface State {
  modalOpen?: { readonly providerId: string; readonly sku: string }
  isProviderAlertOpen: boolean
  products: {
    [key: string]: {
      [key: string]: Product | 'Loading'
    }
  }
  productsPostPago: {
    [key: string]: {
      [key: string]: Product | 'Loading'
    }
  }
  productsPrePago: {
    [key: string]: {
      [key: string]: Product | 'Loading'
    }
  }
  productsCredit: {
    [key: string]: {
      [key: string]: Product | 'Loading'
    }
  }
  cuponsActive: any
  couponCount: number
  msg: any
  skuToRemove: any
  couponReferal: any
  alert: boolean
  resultReferal: any
  priceTotal: any
  notProviderReferal: boolean
  providerReferal: any
  metodoPago: any
  productsGroupedPrepago: any
  productsGroupedPostPago: any
  productsGroupedCredit: any
  cartTotal: number
  deviceInfo: any
  statusOrder: any
  userInfo: any
  showGuruProModal: boolean
  success: boolean
}

class Cart extends React.PureComponent<IProps, State> {
  state: State = {
    products: {},
    productsPostPago: {},
    productsPrePago: {},
    productsCredit: {},
    isProviderAlertOpen: false,
    cuponsActive: [],
    couponCount: 0,
    msg: { msg: null, active: false },
    skuToRemove: [],
    couponReferal: '',
    resultReferal: '',
    alert: false,
    priceTotal: {},
    notProviderReferal: false,
    providerReferal: '',
    metodoPago: [],
    productsGroupedPrepago: {},
    productsGroupedPostPago: {},
    productsGroupedCredit: {},
    cartTotal: 0,
    deviceInfo: {},
    statusOrder: {},
    userInfo: {},
    showGuruProModal: false,
    success: false,
    
  }

  componentDidMount = async () => {
    const { cartModel, coupons } = this.props
    const user: any = (await this.props.user.getUser()) || {}
    const { priceTotal } = this.state
    const deviceInfo = await Device.getInfo()

    this.setState({ deviceInfo, userInfo: user })
    if (this.props.match.params) {
      const statePay = this.props.match.params.statePay || undefined
      this.detailOfPay(statePay)
    }

    localStorage.setItem("totalPre", JSON.stringify(0))
    localStorage.setItem("totalPost", JSON.stringify(0))
    localStorage.setItem("totalCredit", JSON.stringify(0))

    try {
      const cartId: string = (await cartModel.getId()) as string
      const response = await getCartClient(cartId)
      const referalCupon = await coupons.couponReferalCart(user.id, 'referal')
      if (response.data && response.data.cart) {
        localStorage.setItem('@cart', JSON.stringify(response.data.cart))
        await this.props.cartModel.updateCart(response.data.cart.products)
      }

      const cart = response.data && response.data.cart && response.data.cart.products && response.data.cart.products.filter((item: any) =>
        item.paid === false

      )
      if (cart && cart.length === 0) {
        cartModel.clearCart()
      }

      if (Object.keys(referalCupon).length > 0) {
        this.setState({
          couponReferal: referalCupon,
        })
      }
    } catch (error) {
      await cartModel.fixNotFoundCart()
    }

    const priceTotalFuntion = localStorage.getItem('@cart')
    if (priceTotalFuntion) {
      this.setState({
        priceTotal: {
          ...priceTotal,
          priceTotal: JSON.parse(priceTotalFuntion).products.filter((item: any) => item.infoPrice),
        },
      })
    }
    const providerReferal = await cartModel.verifyProviderReferal('referal')

    if (providerReferal.status === 200) {
      this.setState({
        providerReferal: providerReferal.respuesta.find((id: any) => {
          return id.providers
        }),
      })
    }

    this.deleteAllDiscounts('-1')
    await this.getProduct()

    //LISSSST000000
    const cartId: string = await cartModel.getId() as string
    if (cartId) {
      this.getDiscounts(cartId)
    }

    const couponCount = Number(await coupons.count())

    if (couponCount) {
      this.setState({
        couponCount,
      })
    }
    this.loadDefaulPayments()
    this.getCartProducts()
  }

  //LISTOOOOOO
  detailOfPay = async (statePay?: any) => {
    if (statePay) {
      if (statePay === '2') {
        this.setState({ statusOrder: { open: true, paid: false, subHeader: 'Medio de pago agregado', header: '' } })
      }
      if (statePay === '1') {
        ordersPaidEvents()
        this.setState({ statusOrder: { open: true, paid: true, subHeader: 'Realizado', header: '' }, success: true })
      }
      if (statePay === '0') {
        this.setState({
          statusOrder: {
            open: true,
            paid: false,
            subHeader: 'Tarjeta esta registrada en el sistema',
            header: 'Duplicado',
          }
        })
      }
      if (statePay === '-1') {
        this.setState({ statusOrder: { open: true, paid: false, subHeader: 'Volver a intentar', header: '' } })
      }
    }
  }

  onCloseSuccessModal = () => {
    const { userInfo } = this.state
    const surveyData = getSurveyByKey('satisfaction-survey')

    if (userInfo.porcentajeDeAvance < 100 && process.env.REACT_APP_GURU_PRO === 'true') {
      this.setState({ showGuruProModal: true })
    } else {
      this.setState({ showGuruProModal: false })
      if(surveyData) { 
        this.goToSurvey()
      } else {
        this.goToOrders()
      }
    }
  }

  private getProduct = async () => {
    const { cartModel, productModel } = this.props
    let produtsToRemove: any[] = []
    const cartProducts = cartModel.getCart()

    if (cartProducts.length === 0) {
      return
    }
    let arrayPostPago: any = []
    let arrayPrePago: any = []
    let arrayCredit: any = []

    arrayPostPago = cartProducts.filter((item: any) => { return (item.paymentForm === 'post-pago' && item) })
    arrayPrePago = cartProducts.filter((item: any) => { return (item.paymentForm === 'pre-pago' && item) })
    arrayCredit = cartProducts.filter((item: any) => { return (item.paymentForm === 'credito' && item) })

    await Promise.all(
      arrayPostPago.map(async (item: any) => {
        let providerId = item.providerId
        let sku = item.sku
        this.setState(
          ({ productsPostPago }) => ({
            productsPostPago: { ...productsPostPago, [providerId]: { ...productsPostPago[providerId], [sku]: 'Loading' } },
          }),
          async () => {
            await productModel.getByProviderAndSku(providerId, sku).then(product => {
              if (product) {
                if (product.price > 0) {
                  this.setState(({ productsPostPago }) => {
                    return { productsPostPago: { ...productsPostPago, [providerId]: { ...productsPostPago[providerId], [sku]: product } } }
                  })
                } else {
                  produtsToRemove.push(['<li>' + product.brand + ' - ' + product.name + '</li>'])
                  cartModel.removeProduct(providerId, sku, product)
                }
              }
            })
            this.setState({
              skuToRemove: produtsToRemove,
            })
          },
        )
      }),
    )

    await Promise.all(
      arrayCredit.map(async (item: any) => {
        let providerId = item.providerId
        let sku = item.sku
        this.setState(
          ({ productsCredit }) => ({
            productsCredit: { ...productsCredit, [providerId]: { ...productsCredit[providerId], [sku]: 'Loading' } },
          }),
          async () => {
            await productModel.getByProviderAndSku(providerId, sku).then(product => {
              if (product) {
                if (product.price > 0) {
                  this.setState(({ productsCredit }) => {
                    return { productsCredit: { ...productsCredit, [providerId]: { ...productsCredit[providerId], [sku]: product } } }
                  })
                } else {
                  produtsToRemove.push(['<li>' + product.brand + ' - ' + product.name + '</li>'])
                  cartModel.removeProduct(providerId, sku, product)
                }
              }
            })
            this.setState({
              skuToRemove: produtsToRemove,
            })
          },
        )
      }),
    )

    await Promise.all(
      arrayPrePago.map(async (item: any) => {
        let providerId = item.providerId
        let sku = item.sku
        this.setState(
          ({ productsPrePago }) => ({
            productsPrePago: { ...productsPrePago, [providerId]: { ...productsPrePago[providerId], [sku]: 'Loading' } },
          }),
          async () => {
            await productModel.getByProviderAndSku(providerId, sku).then(products => {
              if (products) {
                if (products.price > 0) {
                  this.setState(({ productsPrePago }) => {
                    return { productsPrePago: { ...productsPrePago, [providerId]: { ...productsPrePago[providerId], [sku]: products } } }
                  })
                } else {
                  produtsToRemove.push(['<li>' + products.brand + ' - ' + products.name + '</li>'])
                  cartModel.removeProduct(providerId, sku, products)
                }
              }
            })
            this.setState({
              skuToRemove: produtsToRemove,
            })
          },
        )
      }),
    )
  }

  deleteAllDiscounts = async (id: string) => {
    await this.props.cartModel.removeDiscount(id)
  }

  //Listppppppp
  getDiscounts = async (id: string) => {
    const cartLocalStorage = localStorage.getItem('@cart')
    let cart: any = ''
    if (cartLocalStorage) {
      cart = JSON.parse(cartLocalStorage).products
    }

    cart && cart.products && cart.products.map(async (product: any) => {
      await this.props.cartModel.setProduct(product)
    })

    this.deleteAllDiscounts('-1')

    const data = await this.props.couponsActivatedModel.getDiscounts(id)
    // Object(data).map(async (discountDetail: any) => {
    //   await this.props.cartModel.setDiscounts({
    //     code: discountDetail.code,
    //     amount: discountDetail.amount,
    //     discountType: discountDetail.discountType,
    //     couponId: discountDetail.couponId,
    //     couponActivateId: discountDetail.couponActivateId,
    //     provider: discountDetail.provider,
    //     realDiscount: discountDetail.realDiscount,
    //     minimumAmount: discountDetail.minimumAmount,
    //     applyDiscount: discountDetail.applyDiscount,
    //     couponType: discountDetail.couponType,
    //   })
    // })

    this.setState({
      cuponsActive: data,
    })
  }

  goToDeliveries = () => {
    this.props?.history?.push('/deliveries')
  }

  goToOrders = () => {
    this.props?.history?.push('/orders')
  }

  redirectToSurvey = (surveyKey:string) => {
    const { history } = this.props
    history.push('/survey-nps', {
      targetType: 'order-transaction',
      targetId: '',
      key: surveyKey
    }) 
  }

  goToSurvey = async () => { 
    const {surveyModel} = this.props
    const surveyData = getSurveyByKey('satisfaction-survey')

    const answerData = await surveyModel?.additionalInfoSurvey(surveyData.surveyId)
    if(answerData?.shouldShow) {
      this.redirectToSurvey(surveyData.value)
    } else {
      this.goToOrders()
    }
  }

  goToCoupons = () => this.props.history.replace('/coupons')
  
  deleteCart = async () => {
    const msg = {
      active: true,
      msg: '¿Estás seguro de vaciar el carro?',
      header: 'vaciar carro',
    }
    this.setState({
      msg,
    })

    localStorage.removeItem('totalPre')
    localStorage.removeItem('totalPost')
  }

  handleActivateCouponReferal = async (providerId: string, couponReferal: any) => {
    const { coupons } = this.props
    const user: any = (await this.props.user.getUser()) || {}
    const result = await coupons.activateCouponReferal(couponReferal._id, user.id, providerId)
    
    if (result.status === 400) {
      this.setState({
        resultReferal: result,
        alert: true,
      })
    } else {
      window.location.reload()
      this.setState({
        productsGroupedPrepago: {},
        productsGroupedPostPago: {}
      })
      localStorage.removeItem('totalPre')
      localStorage.removeItem('totalPost')
    }
  }

  handleDesactivate = async (providerId: string) => {
    const { coupons } = this.props
    const user: any = (await this.props.user.getUser()) || {}
    await coupons.desactivateCouponReferal(providerId, user.id)
    window.location.reload()
    this.setState({
      productsGroupedPrepago: {},
      productsGroupedPostPago: {}
    })
    localStorage.removeItem('totalPre')
    localStorage.removeItem('totalPost')
  }

  loadDefaulPayments = async () => {
    const { cartModel, providerModel } = this.props
    const { deviceInfo } = this.state
    const productsInCart = cartModel.getCart()
    const productsGrouped: any = groupBy(productsInCart, (p: any) => p.providerId)

    Object.keys(productsGrouped).forEach((r: any) => {
      const { metodoPago } = this.state
      let provider: any = providerModel.getById(r)

      const cartLocalStorage = localStorage.getItem('@cart')
      let cart: any = ''
      if (cartLocalStorage) {
        cart = JSON.parse(cartLocalStorage).products
      }

      if (cart && cart.length > 0) {
        for(let i = 0; i < cart.length; i++){ 
          if (cart[i].providerId === provider.id) {
            provider = { ...provider, 'paymentFormDefault': cart[i].paymentForm }
          }
        }
      }
        
      if (deviceInfo.platform === 'web') {
        provider.paymentMethods = provider.paymentMethods.filter((x: any) => x.name !== 'Banco Estado')
      }

      this.setState({
        metodoPago: [
          ...metodoPago,
          {
            type: provider.paymentFormDefault,
            providerId: r,
          },
        ],
      })
    })
  }

  setSelected = (event: any, providerId: any) => {
    const { metodoPago } = this.state
    const providerMethod = metodoPago.findIndex((r: any) => providerId === r.providerId)

    if (providerMethod > -1) {
      metodoPago[providerMethod].type = event.detail.value
      metodoPago[providerMethod].showIcon = event.detail.value
      eventWithName('change_payment')
      this.setState({
        metodoPago: metodoPago,
      })
      this.forceUpdate()
    }
  }

  //Listaaaaaa
  getCartProducts = () => {
    let arrPre: any = {}
    let arrPost: any = {}
    let arrCredit: any = {}

    const cartLocalStorage = localStorage.getItem('@cart')
    
    let cart: any = ''
    if (cartLocalStorage) {
      cart = JSON.parse(cartLocalStorage).products
      cart = cart.filter((item: any) => { return item.paid === false })
    }

    if (cart && cart.length > 0 && cart !== '') {
      for(let i = 0; i < cart.length; i++){
        if (cart[i].paymentForm === 'pre-pago' && !cart[i].paid) {
          let providerProducts: any = []
          providerProducts = arrPre[cart[i].providerId] ? arrPre[cart[i].providerId] : []
          providerProducts.push(cart[i])
          arrPre = { ...arrPre, [cart[i].providerId]: providerProducts }
          this.setState({
            productsGroupedPrepago: arrPre
          })
        } else if (cart[i].paymentForm === 'post-pago' && !cart[i].paid) {
          let providerProducts: any = []
          providerProducts = arrPost[cart[i].providerId] ? arrPost[cart[i].providerId] : []
          providerProducts.push(cart[i])
          arrPost = { ...arrPost, [cart[i].providerId]: providerProducts }
          this.setState({
            productsGroupedPostPago: arrPost
          })
        } 
        else if (cart[i].paymentForm === 'credito' && !cart[i].paid) {
          let providerProducts: any = []
          providerProducts = arrCredit[cart[i].providerId] ? arrCredit[cart[i].providerId] : []
          providerProducts.push(cart[i])
          arrCredit = { ...arrCredit, [cart[i].providerId]: providerProducts }
          this.setState({
            productsGroupedCredit: arrCredit
          })
        }
      }
    } else {
      this.setState({
        productsGroupedPrepago: {},
        productsGroupedPostPago: {},
        productsGroupedCredit: {},
      })
    }
  }

  removerProducts = () => {
    this.setState({
      productsGroupedPrepago: {},
      productsGroupedPostPago: {}
    })
    localStorage.removeItem('totalPre')
    localStorage.removeItem('totalPost')
    this.getCartProducts()
  }

  onProductMethodFormChangeHandler = async (event: any, providerId: string) => {
    let method = event ? event.detail.value : ''
    const cartLocalStorage = localStorage.getItem('@cart')

    let cart: any = ''

    if (cartLocalStorage) {
      cart = JSON.parse(cartLocalStorage)
      let cartCurrent: any = ''
      cartCurrent = cart.products.map((item: any) => {
        if (item.providerId === providerId) {
          return { ...item, 'paymentForm': method }
        } else {
          return item
        }
      })
      cart = { ...cart, products: cartCurrent }
      await this.props.cartModel.saveCart(cart)
      this.setSelected(event, providerId)
      this.setState({
        productsGroupedPrepago: {},
        productsGroupedPostPago: {},
        productsGroupedCredit: {},
        productsPostPago: {},
        productsPrePago: {},
        productsCredit: {},
      })
      this.getCartProducts()
      this.getProduct()
    }
    localStorage.removeItem('totalPre')
    localStorage.removeItem('totalPost')
    localStorage.removeItem('totalCredit')
  }

  //////LISTOOOOOOOOO
  cartDelete = () => {
    const { cartModel } = this.props
    cartModel.clearCart()
    localStorage.removeItem('totalPre')
    localStorage.removeItem('totalPost')
    localStorage.removeItem('totalCredit')
    localStorage.removeItem('@cart')

    this.setState({
      productsGroupedPrepago: {},
      productsGroupedPostPago: {},
      productsGroupedCredit: {},
      productsPostPago: {},
      productsPrePago: {},
      productsCredit: {},
      cartTotal: 0
    })
  }

  totalCart = (total: any, method: string) => {

    if (method === 'pre-pago') {
      localStorage.setItem('totalPre', JSON.stringify(total))
    } else if (method === 'post-pago') {
      localStorage.setItem('totalPost', JSON.stringify(total))
    } else if (method === 'credito') {
      localStorage.setItem('totalCredit', JSON.stringify(total))
    }

    const totalPrePago = localStorage.getItem('totalPre')
    const totalPostPago = localStorage.getItem('totalPost')
    const totalCredit = localStorage.getItem('totalCredit')

    let pre = 0
    let post = 0
    let credit = 0

    if (totalPrePago) {
      pre = JSON.parse(totalPrePago)
    }
    if (totalPostPago) {
      post = JSON.parse(totalPostPago)
    }
    if (totalCredit) {
      credit = JSON.parse(totalCredit)
    }
    this.setState({
      cartTotal: pre + post + credit
    })
  }

  dismissAlertOrder = () => {
    const { props } = this
    this.setState({ statusOrder: { paid: false, open: false } })
    navigateToCart(props.history, props.location.state || props.history.location.state);
  }

  goToHome = () => this.props?.history?.push('/home')

  shouldRenderLoading = () => {
    const { 
      productsGroupedPrepago, 
      productsGroupedPostPago, 
      productsGroupedCredit 
    } = this.state

    if(
      !Object.keys(productsGroupedPrepago).length &&
      !Object.keys(productsGroupedPostPago).length &&
      !Object.keys(productsGroupedCredit).length
    ) { 
      return (
        <div className="loading-offers">
          <p className="loading">Cargando carro</p>
          <IonSpinner className="spinner-mid" name="crescent"  />
        </div>
      )
    }
    return ''
  }
  
  render() {
    const { cartModel } = this.props
    const {
      cuponsActive,
      couponCount,
      msg,
      skuToRemove,
      couponReferal,
      resultReferal,
      alert,
      providerReferal,
      metodoPago,
      productsPostPago,
      productsPrePago,
      productsCredit,
      productsGroupedPrepago,
      productsGroupedPostPago,
      productsGroupedCredit,
      cartTotal,
      deviceInfo,
      userInfo,
      statusOrder,
      showGuruProModal,
    } = this.state
    const cartLocalStorage = localStorage.getItem('@cart')
    let cart: any = []
    if (cartLocalStorage) {
      cart = JSON.parse(cartLocalStorage).products
    }
    cart = cart !== '' && cart.filter((item: any) => { return item.paid === false })
    cart = uniq(cart.map((r: any) => { return r.providerId }))
    const pastRouteInfo: PastRouteinfo = { ...this.props.history.location?.state?.pastRouteInfo, cartId: cartModel.getId()}

    const length = cart.length
    
    return (
      <IonPage className="cart-page-class" >
        {
          statusOrder.paid &&
          userInfo &&
          <React.Fragment>
            {showGuruProModal && userInfo.porcentajeDeAvance < 100 && <GuruPro orderConfirmation />}
            {!showGuruProModal && <OrderConfirmedModal onClick={this.onCloseSuccessModal} />}
          </React.Fragment>
        }
        {
          !statusOrder.paid && 
          <React.Fragment>
            <ToolBar
              title="Carro de compra"
              secondaryHeader={true}
              secondaryButtons={[
                {
                  type: 'close',
                  onClick: this.goToHome,
                },
              ]}
              primaryButtons={[
                {
                  icon: Garbage,
                  key: 'clearCart',
                  onClick: this.deleteCart
                },
              ]}
              textCenter={true}
            />
            {/* Without items */}
            {alert &&
              <IonAlert
                isOpen={alert}
                header={'Ups'}
                message={resultReferal.respuesta}
                buttons={['OK']} />}
            {((userInfo && (!statusOrder.paid || userInfo.porcentajeDeAvance >= 100)) ||
              (statusOrder.paid && userInfo.porcentajeDeAvance === 100)) && statusOrder.subHeader !== 'Realizado' && (
                <IonAlert
                  isOpen={statusOrder.open}
                  onDidDismiss={() => (statusOrder.paid ? this.goToOrders() : this.dismissAlertOrder())}
                  header={statusOrder.header}
                  subHeader={statusOrder.subHeader}
                  buttons={[
                    {
                      text: 'Continuar',
                      handler: () => (statusOrder.paid ? this.goToOrders() : this.dismissAlertOrder()),
                    },
                  ]}
                />
              )}  
            {alert && <IonAlert isOpen={alert} header={'Ups'} message={resultReferal.respuesta} buttons={['OK']} />}
            {length === 0 && (
              <IonContent >
                <div className="without-products-card">
                  <IonImg src={withoutProductsIcon}  />
                  <div className="message">Tu carro se encuentra vacío.</div>
                  <div className="text">¡Encuentra los productos que necesitas!</div>
                  <IonButton 
                    strong={true} 
                    shape="round" 
                    onClick={() => this.props?.history?.push('/home')} 
                    
                  >
                    <IonRippleEffect  />
                    {'Ir a comprar'}
                  </IonButton>
                </div>
              </IonContent>
            )}
            {/* With items */}
            {length > 0 && (
              <IonContent >
                <GuruSlider pager={true}>
                  <GuruSlide>
                    <IonAlert
                      mode="md"
                      isOpen={msg.active}
                      onDidDismiss={() =>
                        this.setState({
                          msg: { active: false, msg: null },
                        })
                      }
                      header={msg.header}
                      message={msg.msg}
                      buttons={[
                        {
                          text: 'Si',
                          handler: () => this.cartDelete(),
                        },
                        {
                          text: 'No',
                          handler: () =>
                            this.setState({
                              msg: { active: false, msg: null },
                            }),
                        },
                      ]}
                    />
                  </GuruSlide>
                </GuruSlider>
                <GuruSlider pager={true}>
                  <GuruSlide>
                    <IonAlert
                      isOpen={skuToRemove.length > 0}
                      onDidDismiss={() => window.location.reload()}
                      header="Productos sin Stock"
                      message={skuToRemove}
                      buttons={[
                        {
                          text: 'Presione para Continuar',
                          handler: () => window.location.reload(),
                        },
                      ]}
                    />
                  </GuruSlide>
                </GuruSlider>
                <div className="container-total-cart">
                  <div className="length-cart">Cantidad de carros: {length}</div>
                  <div className="total-cart">Total: {formatCurrency(cartTotal)}  </div>
                </div>
                <div className="separator"></div>
                {Object.keys(productsGroupedPrepago).length === 0 && Object.keys(productsGroupedPostPago).length === 0 &&
                  <div className="loading-offers">
                    <p className="loading">Cargando carro</p>
                    <IonSpinner className="spinner-mid" name="crescent"  />
                  </div>
                }
              
                {Object.keys(productsGroupedPrepago).length > 0 &&
                  <CartController
                    TitlePaymentMethod={"Pago anticipado"} //LISTO
                    getCarProducts={this.removerProducts} //LISTO
                    productsGrouped={productsGroupedPrepago} //LISTO
                    paymentForm={"pre-pago"} //LISTO
                    products={productsPrePago}
                    couponReferal={couponReferal}  //LISTO
                    providerReferal={providerReferal}
                    onProductMethodFormChangeHandler={this.onProductMethodFormChangeHandler} //LISTO
                    metodoPago={metodoPago}
                    totalCart={this.totalCart}
                    cuponsActiveCart={cuponsActive} //LISTO
                    couponCount={couponCount} //NO SE USA
                    handleActivateCouponReferal={this.handleActivateCouponReferal}
                    handleDesactivate={this.handleDesactivate}
                    getDiscounts={this.getDiscounts} //LISTO
                    length={length}  //LISTO
                    deviceInfo={deviceInfo} //NO SE USA
                    pastRouteInfo={pastRouteInfo} //LISTO
                    {...this.props} />
                }
                {Object.keys(productsGroupedPrepago).length > 0 && Object.keys(productsGroupedPostPago).length > 0 &&
                  <div className="separator"></div>
                }
                {Object.keys(productsGroupedPostPago).length > 0 &&
                  <CartController
                    TitlePaymentMethod="Pago contra entrega"
                    paymentForm="post-pago"
                    getCarProducts={this.removerProducts}
                    productsGrouped={productsGroupedPostPago}
                    products={productsPostPago}
                    couponReferal={couponReferal}
                    providerReferal={providerReferal}
                    onProductMethodFormChangeHandler={this.onProductMethodFormChangeHandler}
                    metodoPago={metodoPago}
                    totalCart={this.totalCart}
                    cuponsActiveCart={cuponsActive}
                    couponCount={couponCount}
                    handleActivateCouponReferal={this.handleActivateCouponReferal}
                    handleDesactivate={this.handleDesactivate}
                    getDiscounts={this.getDiscounts}
                    length={length}
                    deviceInfo={deviceInfo}
                    pastRouteInfo={pastRouteInfo}
                    {...this.props} />
                }
                 //LISTOOOOOO
                {((userInfo && (!statusOrder.paid || userInfo.porcentajeDeAvance >= 100)) ||
                  (statusOrder.paid && userInfo.porcentajeDeAvance === 100)) && statusOrder.subHeader !== 'Realizado' && (
                    <IonAlert
                      isOpen={statusOrder.open}
                      onDidDismiss={() => (statusOrder.paid ? this.goToOrders() : this.dismissAlertOrder())}
                      header={statusOrder.header}
                      subHeader={statusOrder.subHeader}
                      buttons={[
                        {
                          text: 'Continuar',
                          handler: () => (statusOrder.paid ? this.goToOrders() : this.dismissAlertOrder()),
                        },
                      ]}
                    />
                  )}
              </IonContent>
            )}
          </React.Fragment>
        }
        <style>
          {
            `
              .footer-menu-home {
                display: none !important;
              }
            `
          }
        </style>
      </IonPage>
    )
  }
}

export default withRouter(Cart)
