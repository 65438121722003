import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import {
  IonPage,
  IonContent,
  IonChip,
  IonImg,
  IonLabel,
  IonIcon,
} from '@ionic/react'
import { search } from 'ionicons/icons'
import FAQImage from './../../assets/faq.jpeg'
import ToolBar from '../../components/tool-bar/ToolBar'
import { helpQuestion } from '../firebase/firebaseTags'
import faqsModel from '../../models/Faqs'
import contactFormModel from '../../models/ContactForm'
import ContactSections from './contactSection'
import backSmallArrow from '../../assets/icons/nav_left.svg'
import arrowDown from '../../assets/icons/act_open.svg';
import arrowUp from '../../assets/icons/act_close.svg';
import { getCountryCode } from '../../utils/countriesTexts'

// Eventos
import { sendMetric } from '../firebase/firebaseTags'

import './Faq.scss'
import ButtonComponent from '../../components/basic-button/ButtonComponent'

type IPathParams = {}

type Props = RouteComponentProps<IPathParams> & {
  showFaqs: faqsModel
  showContactDetails: contactFormModel
}

interface State {
  active: String
  toggle: String
  searchText: string
  bandera: boolean
  ordersFaqs: any[]
  providersFaqs: any[]
  warehouseFaqs: any[]
  placeImage: String
  contactForm: any
  selectedSection: String
}
class Faq extends React.PureComponent<Props, State> {
  state: State = {
    active: '',
    toggle: '',
    searchText: '',
    bandera: false,
    placeImage: 'pedido-0',
    ordersFaqs: [
      {
        type: '',
        title: '',
        answer: ''
      }
    ],
    providersFaqs: [
      {
        type: '',
        title: '',
        answer: ''
      }
    ],
    warehouseFaqs: [
      {
        type: '',
        title: '',
        answer: ''
      }
    ],
    contactForm: '',
    selectedSection: ''
  }

  componentDidMount = async () => {
    const getFaqs = await this.props.showFaqs.getFaqs()
    let ordersFaqs = this.prepareFaqData('order', getFaqs[0]);
    let providersFaqs = this.prepareFaqData('provider', getFaqs[0]);
    let warehouseFaqs = this.prepareFaqData('warehouse', getFaqs[0]);
    const countryCode = getCountryCode()

    const contactForm = await this.props.showContactDetails.getContactDetails(countryCode)

    this.setState({
      ordersFaqs,
      providersFaqs,
      warehouseFaqs,
      contactForm,
    });
  }

  prepareFaqData = (groupIndex: string, faqArray: any) => {
    let numberTypeOfValues = -1;
    let result = [];

    for (var key in faqArray[groupIndex]) {
      numberTypeOfValues++;
      if (groupIndex === "order") {
        result.push({
          type: 'pedido-' + numberTypeOfValues,
          title: key,
          answer: faqArray[groupIndex][key]
        });
      }
      if (groupIndex === "provider") {
        result.push({
          type: 'proveedor-' + numberTypeOfValues,
          title: key,
          answer: faqArray[groupIndex][key]
        });
      }
      if (groupIndex === "warehouse") {
        result.push({
          type: 'almacen-' + numberTypeOfValues,
          title: key,
          answer: faqArray[groupIndex][key]
        });
      }
    };
    return result;
  }

  changeSection = (section: string) => {
    this.setState({
      active: section
    })
  }

  toggleItem = (question: string) => {
    const result: any = this.state.toggle === question ? '' : question
    this.setState({
      toggle: result
    })
  }

  toggleContactSection = (value: String) => {
    const result: any = this.state.selectedSection === value ? '' : value
    this.setState({
      selectedSection: result
    })
  }

  onChange = (event: any) => {
    this.setState({
      searchText: event.target.value,
      bandera: true
    })
  }

  renderQuestionsCards = (faqsArr: any[]) => {
    const { toggle } = this.state

    return faqsArr.map((faq, index) => (
      <div className='question-card' key={index} onClick={() => helpQuestion(faq.title)}>
        <div className='question-card-header' onClick={(e: any) => { this.toggleItem(faq.type) }}>
          <h6 className='no-margin'>{faq.title}</h6>
          <IonIcon className="icon" slot="end" icon={toggle === faq.type ? arrowUp : arrowDown}  />
        </div>

        {toggle === faq.type && (
          <div className='question-card-body'>
            <p className='paragraph-2 no-margin'>{faq.answer}</p>
            {this.state.toggle === 'pedido-0' &&
              <IonImg src={FAQImage}  />
            }
          </div>
        )}
      </div>
    ))
  }

  render() {
    const { active, toggle, ordersFaqs, providersFaqs, warehouseFaqs, contactForm, selectedSection } = this.state
    const { history } = this.props

    return (
      <IonPage className="faq1-page ds-content-page" >
        <ToolBar
          title="Perfil"
          secondaryButtons={[{
            type: 'basicIcon',
            key: 'back',
            icon: backSmallArrow,
            iconSize: '7px',
            onClick: history.goBack
          }]}
          secondaryHeader={true}
        />
        <IonContent >
          <div className="search-bar-faq">
            <div>
              <input
                placeholder='Buscar'
                onChange={this.onChange}
                value={this.state.searchText}
              />
              <IonIcon icon={search} ></IonIcon>
            </div>
          </div>
          <div className="filter-btn">
            <div className='questions-button-wrapper'>
              <ButtonComponent 
                text='Pedido'
                className="btn-tertiary"
                onClick={() => {
                  this.changeSection('pedido')
                  sendMetric('view_help_order')
                }}
              />
            </div>
            <div className='questions-button-wrapper'>
              <ButtonComponent 
                text='Información de tu almacén'
                className="btn-tertiary"
                onClick={() => {
                  this.changeSection('almacen')
                  sendMetric('view_help_info')
                }}
              />
            </div>
            <div className='questions-button-wrapper'>
              <ButtonComponent 
                text='Proveedores'
                className="btn-tertiary"
                onClick={() => {
                  this.changeSection('proveedor')
                  sendMetric('view_help_vendors')
                }}
              />
            </div>
            <div className='questions-button-wrapper'>
              <ButtonComponent 
                text='Contacto'
                className="btn-tertiary"
                onClick={() => {
                  this.changeSection('contacto')
                  sendMetric('view_help_contact')
                }}
              />
            </div>
            <div className='questions-button-wrapper'>
              <ButtonComponent 
                text='Todos'
                className="btn-tertiary"
                onClick={() => {
                  this.changeSection('todos')
                  sendMetric('view_help_all')
                }}
              />
            </div>
            {/* <IonChip className={active === 'pedido' ? 'active' : ''} onClick={(e: any) => this.changeSection('pedido')} >
              <IonLabel onClick={() => sendMetric('view_help_order')}>Pedido</IonLabel>
            </IonChip>
            <IonChip className={active === 'almacen' ? 'active' : ''} onClick={(e: any) => this.changeSection('almacen')}>
              <IonLabel onClick={() => sendMetric('view_help_info')}>Información de tu almacén</IonLabel>
            </IonChip>
            <IonChip className={active === 'proveedor' ? 'active' : ''} onClick={(e: any) => this.changeSection('proveedor')}>
              <IonLabel onClick={() => sendMetric('view_help_vendors')}>Proveedores</IonLabel>
            </IonChip>
            <IonChip className={active === 'contacto' ? 'active' : ''} onClick={(e: any) => this.changeSection('contacto')}>
              <IonLabel onClick={() => sendMetric('view_help_contact')}>Contacto</IonLabel>
            </IonChip>
            <IonChip className={active === 'todos' ? 'active' : ''} onClick={(e: any) => this.changeSection('todos')}>
              <IonLabel onClick={() => sendMetric('view_help_all')}>Todos</IonLabel>
            </IonChip> */}
          </div>
          {active === '' &&
            <div>
              <div className="questions">
                <div className='header-questions'>
                  <h3>
                    Pedido
                  </h3>
                </div>
                {this.renderQuestionsCards(ordersFaqs)}
              </div>
              <div className="questions">
                <div className='header-questions'>
                  <h3>
                    Proveedores
                  </h3>
                </div>
                {this.renderQuestionsCards(providersFaqs)}
              </div>
              <div className="questions">
                <div className='header-questions'>
                  <h3>
                    Información de tu almacén
                  </h3>
                </div>
                {this.renderQuestionsCards(warehouseFaqs)}
              </div>
              <ContactSections
                contactForm={contactForm}
                selectedSection={selectedSection}
                toggleContactSection={(value: String) => this.toggleContactSection(value)}
              />
            </div>}
          {active === 'todos' &&
            <div>
              <div className="questions">
                <div className='header-questions'>
                  <h3>
                    Pedido
                  </h3>
                </div>
                {this.renderQuestionsCards(ordersFaqs)}
              </div>
              <div className="questions">
                <div className='header-questions'>
                  <h3>
                    Proveedores
                  </h3>
                </div>
                {this.renderQuestionsCards(providersFaqs)}
                </div>
              <div className="questions">
                <div className='header-questions'>
                  <h3>
                    Información de tu almacén
                  </h3>
                </div>
                {this.renderQuestionsCards(warehouseFaqs)}
              </div>

              <ContactSections
                contactForm={contactForm}
                selectedSection={selectedSection}
                toggleContactSection={(value: String) => this.toggleContactSection(value)}
              />
            </div>}
          {active === 'pedido' && (
            <div className="questions">
              <div className='header-questions'>
                <h3>
                  Pedido
                </h3>
              </div>
              {this.renderQuestionsCards(ordersFaqs)}
            </div>)}
          {active === 'proveedor' && (
            <div className="questions">
              <div className='header-questions'>
                <h3>
                  Proveedores
                </h3>
              </div>
              {this.renderQuestionsCards(providersFaqs)}
            </div>)}
          {active === 'almacen' && (
            <div className="questions">
              <div className='header-questions'>
                <h3>
                  Información de tu almacén
                </h3>
              </div>
              {this.renderQuestionsCards(warehouseFaqs)}
            </div>)}
          {active === 'contacto' && (
            <ContactSections
              contactForm={contactForm}
              selectedSection={selectedSection}
              toggleContactSection={(value: String) => this.toggleContactSection(value)}
            />
          )}

        </IonContent>
        <style>
          {`
          .footer-menu-home {
            display: none !important;
          }
        `}
        </style>
      </IonPage>
    )
  }
}
export default withRouter(Faq)