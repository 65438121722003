import { useState, useEffect, useCallback } from "react";
import { useJsApiLoader } from '@react-google-maps/api';
import { MapLocationCoordinates } from "../models/MapLocationCoordinates";
import { countryPe, countryCl, countryMx } from "../utils/countriesTexts";
import { LocationInfo } from "../models/LocationInfo";
import { isFunction } from "../utils/variables";
import { getAddressByCoordinates } from "../clients/offices";

const initCoordinates = (countryCode: string): MapLocationCoordinates => {
  const defaultCoordinates = { lat: -33.4499798, lng: -70.63649529999999 };
  if (!countryCode) return defaultCoordinates;

  const countriesInitialCoordinates = new Map<string, MapLocationCoordinates>([
    [countryCl, { lat: -33.4499798, lng: -70.63649529999999 }],
    [countryPe, { lat: -12.046309403159286, lng: -77.04273781991358 }],
    [countryMx, { lat: 19.44339875966158, lng: -99.13274818675974 }],
  ]);

  return countriesInitialCoordinates.get(countryCode) || defaultCoordinates;
};

export const useGeolocalization = (userCountryCode: string) => {

  const [addressInput, setAddressInput] = useState<string | null>(
    userCountryCode === 'CL'
      ? 'Raulí, Santiago, Región Metropolitana'
      : userCountryCode === 'PE'
      ? 'Plaza Dos de Mayo, Lima 15082, Perú'
      : userCountryCode === 'MX'
      ? 'López Rayón 18, Peralvillo, Morelos, Cuauhtémoc, 06200 Ciudad de México, CDMX, México'
      : 'Raulí, Santiago, Región Metropolitana'
  );
  const [coordinates, setCoordinates] = useState<MapLocationCoordinates>(initCoordinates(userCountryCode));
  const [locationInfo, setLocationInfo] = useState<LocationInfo>({} as LocationInfo);
  const [loadingGeolocation, setLoadingGeolocation] = useState<boolean>(false);
  const [geolocationDisabled, setGeolocationDisabled] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const processGoogleLocationData = useCallback((locationData: any) => {
    setAddressInput(null);
    const {
      geometry: {
        location: { lat, lng },
      },
      address_components: addressSections,
      formatted_address: formattedAddress,
    } = locationData;

    const getLocationSectionByType = (sections: any[], type: string) => 
      sections.find((section) => section.types?.length && section.types?.includes(type));

    const getShortAddress = () => {
      const street = getLocationSectionByType(addressSections, 'route')?.long_name;
      const streetNumber = getLocationSectionByType(addressSections, 'street_number')?.long_name;
      if (!street || !streetNumber) return '';
      return `${street} ${streetNumber}`;
    };

    const getCommuneName = () => {
      const sectionNameByCountry: Record<string, string> = {
        [countryCl]: 'administrative_area_level_3',
        [countryPe]: 'locality',
        [countryMx]: 'sublocality_level_1',
      };
      const commune = getLocationSectionByType(addressSections, sectionNameByCountry[userCountryCode]);
      return commune?.long_name || commune?.short_name || '';
    };

    const getProvinceName = () => {
      const sectionNameByCountry: Record<string, string> = {
        [countryCl]: '',
        [countryPe]: 'administrative_area_level_2',
        [countryMx]: 'administrative_area_level_2',
      };
      const province = getLocationSectionByType(addressSections, sectionNameByCountry[userCountryCode]);
      return province?.long_name || province?.short_name || '';
    };

    const getRegionName = () => {
      const sectionNameByCountry: Record<string, string> = {
        [countryCl]: 'administrative_area_level_1',
        [countryPe]: 'administrative_area_level_1',
        [countryMx]: 'administrative_area_level_1',
      };
      const region = getLocationSectionByType(addressSections, sectionNameByCountry[userCountryCode]);
      return region?.long_name || region?.short_name || '';
    };

    const getPostalCode = () =>
      getLocationSectionByType(addressSections, 'postal_code')?.long_name || '';

    const latitude = isFunction(lat) ? lat() : lat;
    const longitude = isFunction(lng) ? lng() : lng;

    setAddressInput(formattedAddress);
    setLocationInfo({
      latitude,
      longitude,
      address: getShortAddress(),
      postalCode: getPostalCode(),
      zones: {
        commune: getCommuneName(),
        province: getProvinceName(),
        region: getRegionName(),
      },
    });
    setCoordinates({ lat: latitude, lng: longitude });
  }, [userCountryCode]);

  const findGeolocationSuccess = useCallback(async (position: GeolocationPosition) => {

    try {
      const { latitude, longitude } = position.coords;
      const newCoords: MapLocationCoordinates = { lat: latitude, lng: longitude };

      const address = await getAddressByCoordinates(newCoords);
      if (address) processGoogleLocationData(address);
      setCoordinates(newCoords);
    } catch (error) {
      console.error(error);
      setError('Error getting address. Please try again.');
    }
    setLoadingGeolocation(false);
  }, [processGoogleLocationData]);

  const handleFindMe = useCallback(() => {

    if (navigator?.geolocation) {
      !geolocationDisabled && setLoadingGeolocation(true);
      navigator.geolocation.getCurrentPosition(
        findGeolocationSuccess,
        (error) => {
          console.error('Geolocation error:', error);
          setError('Unable to get your location. Please try again.');
          setLoadingGeolocation(false);
          setGeolocationDisabled(true);
        },
        { enableHighAccuracy: true }
      );
    } else {
      setError('Geolocation is not supported by this browser');
    }
  }, [ geolocationDisabled, findGeolocationSuccess]);


  return {
    addressInput,
    setAddressInput,
    coordinates,
    setCoordinates,
    locationInfo,
    setLocationInfo,
    loadingGeolocation,
    geolocationDisabled,
    handleFindMe,
    findGeolocationSuccess,
    error,
  };
};