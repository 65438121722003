import React from 'react'
import { IonList, IonItem, IonSelect, IonSelectOption } from '@ionic/react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import track from 'react-tracking'

//MODELS
import UserModel from './../../models/User'
import SettingsModel from './../../models/Settings'
import Office from '../../models/Office'

//ICONS
import logo from './../../assets/logo.png'

import { currentApp } from '../../utils/currentCountry'

//EVENTS
import { selectCountryEvent, sendMetric } from '../firebase/firebaseTags'

import './NoCountryDetect.scss'

type IPathParams = {}

type Props = RouteComponentProps<IPathParams> & {
  user: UserModel
  settings: SettingsModel
  tracking?: any
  offices: Office
}

interface State {
  countryAvailable: any
}

class NoCountryDetect extends React.PureComponent<Props, State> {
  state: State = {
    countryAvailable: '',
  }

  async componentDidMount() {
    setTimeout(() => sendMetric('login_page_country'), 3000)
    const countryAvailable = await currentApp()
    this.setState({
      countryAvailable: countryAvailable,
    })
  }

  selectCountry = async (country: any) => {


    const { countryAvailable } = this.state
    const countrySelect = country.detail.value

    selectCountryEvent(countrySelect)

    const countryData = countryAvailable.find((item: any) => item.country === countrySelect)

    localStorage.setItem('countryName', JSON.stringify(countryData))
    localStorage.setItem('countrySelect', JSON.stringify(countryData))

    this.props?.history?.push('/', countryData)
  }

  render() {
    const { countryAvailable } = this.state

    return (
      <div className="no-country-detect">
        <div className="container-img">
          <img alt="Imagen logo" className="logoImage" src={logo} />
        </div>
        <div className="separator"></div>
        <div className="container-title-not-country">
          <div className="subtitle-validate-user">Seleccionar el país disponible</div>
        </div>
        <div className="container-select">
        <IonList>
          <IonItem>
            <IonSelect
              className="guru-initial-country-select"
              placeholder={'Seleccionar país'}
              interface="action-sheet"
              cancelText="Cancelar"
              onIonChange={(e) => this.selectCountry(e)}
              >
                {countryAvailable && countryAvailable.length > 0 &&
                  countryAvailable.map((item:any) =>(
                    <IonSelectOption 
                      key={item.countryCode} 
                      value={item.countryCode}
                    >
                        {item.countryName}
                    </IonSelectOption>)
                  )
                }
            </IonSelect>
          </IonItem>
        </IonList>
        </div>
      </div>
    )
  }
}

export default track({ page: 'NoCountryDetect' })(withRouter(NoCountryDetect))
