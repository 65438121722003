import React from 'react'
import { IoInformationCircle as InfoIcon } from "react-icons/io5";

interface ErrorListProps {
  rule?: string;
}

const ErrorList: React.FC<ErrorListProps> = ({rule}) => {
  return (
    <div className='error-message-container'>
      <div className="icon-error">
        <InfoIcon color='red' />
      </div>
      <p>{rule}</p>
    </div>
  )
}

export default ErrorList
