import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { produce } from 'immer'
import { StaticContext } from 'react-router-dom'

// Clients
import { registerUser, checkRegistered, getBusinessTypes } from '../../clients/register'

// Models
import UserModel from './../../models/User'
import Office from './../../models/Office'
import SettingsModel from './../../models/Settings'
import termsConditionModel from '../../models/TermsConditionModel'
import { OfficeAction } from '../../models/OfficeAction'

// Utils
import { newValidatePhone, validateEmail } from '../../utils/basetypes'
import { currentApp } from '../../utils/currentCountry'
import { countryCl, countryPe, countryMx, dniByCountry, phonePlaceholderByCountry, phoneLengthByCountry, isPhoneValidByCountry, countriesWithHunters, phoneAsUsernameCountries } from '../../utils/countriesTexts'
import { getLocalStorageObject } from '../../utils/localstorageData'

// Events
import { logError, sendMetric, registerSuccessfulEvent, fbSignUpEvent } from '../firebase/firebaseTags'

// Components
import Stepper from '../../components/stepper/Stepper'
import ContactSupport from '../../components/contact-support/ContactSupport'
import TextInput from '../../components/input-form/TextInput'
import ButtonComponent from '../../components/basic-button/ButtonComponent'
import GuruAcceptModal from '../../components/modal-action-accept/ModalActionAccept'
import AlertModal from '../../components/modals/AlertModal'
import TermsConditionsModal from '../terms-conditions-modal/TermsConditionsModal'
import LoadingCoverUserGuest from '../../components/loading-cover/LoadingCoverGuestUser'

// Styles
import './RegisterUser.scss'

interface RouteState {
  officeAction: OfficeAction
  lateRegistration?: boolean
}

interface UserRegistration {
  user_contac: string,
  username: string,
  phone: string,
  email: string,
  password: string,
  smsIsValid: boolean,
  emailValid: boolean,
  hunter: string,
  rfc?: string,
}

type IPathParams = {}

type Props = RouteComponentProps<IPathParams, StaticContext, RouteState> & {
  user: UserModel
  settings: SettingsModel
  tracking?: any
  goProfile?:any
  offices: Office
  showTermsConditions: termsConditionModel
  renderUsernameField: (isPhone: boolean, validationFunc: any) => any
}

interface Form {
  password: string
  email: string
  phone: string
  user_contac: string
  confirm_password: string
}

interface sendform {
  email: string
  phone: string
  user_contac: string
  comment: any
}

interface State<T, F> {
  data: any
  validation: any
  isFormValid: boolean
  loading: boolean
  showError: boolean
  messageError: string
  btnApplyfor: boolean
  dataSendform: {
    email: string
    phone: string
    user_contac: string
    comment: any
  }
  validationSendform: {
    email: boolean
    phone: boolean
    user_contac: boolean
    comment: boolean
  }
  spinner: boolean
  showModal: boolean
  showAlert: boolean
  showModalUserExists:boolean
  country: any
  username: string
  cleanUser: boolean
  approvedUserName: boolean
  currentStep: number
  termsConditions: boolean
  isBusinessModalOpen: boolean
  registerResultData: any
  modalTermsOpen: boolean
  termsText: string
  checkbox: boolean
  duplicateError: boolean
  isLoadingCoverUserGuest: boolean
  businessTypesOptions: string[]
  isShowModalBusinessTypeConfirm: boolean
  checkRegisteredExistsOrNot: boolean
}

const countriesWithBusinessModal = [countryPe, countryMx]

class RegisterUser extends React.PureComponent<Props, State<Form, sendform>> {
  state: State<Form, sendform> = {
    data: {
      password: '',
      confirm_password: '',
      email: '',
      phone: '',
      user_contac: '',
      hunter: '',
    },
    loading: false,
    validation: {
      password: false,
      email: false,
      phone: false,
      user_contac: false,
      confirm_password: false,
    },
    isFormValid: false,
    showError: false,
    messageError: '',
    btnApplyfor: false,
    dataSendform: {
      email: '',
      phone: '',
      user_contac: '',
      comment: '',
    },
    validationSendform: {
      email: false,
      phone: false,
      user_contac: false,
      comment: false,
    },
    spinner: false,
    showModal: false,
    showAlert: false,
    showModalUserExists:false,
    country: '',
    username: '',
    cleanUser: false,
    approvedUserName: false,
    currentStep: 1,
    termsConditions: false,
    isBusinessModalOpen: false,
    registerResultData: {},
    modalTermsOpen: false,
    termsText: 'Términos y Condiciones',
    checkbox: false,
    duplicateError: false,
    isLoadingCoverUserGuest: false,
    businessTypesOptions: [],
    isShowModalBusinessTypeConfirm: false,
    checkRegisteredExistsOrNot: false
  }

  componentDidMount = async () =>{
    const country = getLocalStorageObject('countryName') || ''
    const termsText = this.termsTextByCountry(country.countryCode)

    this.setState({
      country: currentApp().find((route:any) => country.countryCode === route.country),
      termsText,
    })

    const typeBusiness = await getBusinessTypes(country.countryCode)
    const businessTypesOptions: string[] = []

    typeBusiness.forEach(({ name, visible }: any) => {
      if (!visible) return
      businessTypesOptions.push(name)
    })

    this.setState({ businessTypesOptions })
  }

  termsTextByCountry = (countryCode: string) => {
    try {
      const termsTexts: any = [
        { code: countryCl, text: 'Términos y Condiciones' },
        { code: countryPe, text: 'Términos y Condiciones y la Política de Privacidad' },
        { code: countryMx, text: 'Términos y Condiciones y la Política de Privacidad' },
      ]
      return termsTexts.find(({ code }: any) => code === countryCode)?.text || termsTexts[0].text
      
    } catch (err) {
      console.error(err)
      return 'Términos y Condiciones'
    }
  }

  onButtonClick = async () => {
    const { country, data, username, checkRegisteredExistsOrNot } = this.state
    const { user_contac, phone, email, password, hunter } = data
    const { history } = this.props

    try {
      this.setState({
        loading: true,
        showModal: false,
        duplicateError: false,
        showModalUserExists: false,
        isLoadingCoverUserGuest: !checkRegisteredExistsOrNot ? true : false
      })

      const confirmData: UserRegistration = {
        user_contac,
        username,
        phone,
        email,
        password,
        smsIsValid: false,
        emailValid: false,
        hunter,
      }

      if (this.validateCountry(countryMx)) {
        confirmData.username = phone
        confirmData.rfc = username
      }
  
      const lastToken = localStorage.getItem('@last_token_access')
      let registerResult:any  
    
      let countryCode = country.country

      if (lastToken) {
        registerResult = await registerUser(confirmData, countryCode, lastToken)
      } else {
        registerResult = await registerUser(confirmData, countryCode)
      }
    
      if (registerResult) {
        this.setState({
          loading: false,
          messageError: '',
          isLoadingCoverUserGuest: false
        })
        if (!registerResult.error){
          const { username: name, phone, email, openFacturaValidation } = registerResult.user
          registerSuccessfulEvent(name, phone, email)
          fbSignUpEvent()
          this.setState({ registerResultData: registerResult })

          localStorage.setItem('registerSuccesfull', JSON.stringify(registerResult))
          
          history.push('/office-localization', { officeAction: "office-creation" })
        }
        if (registerResult.errorType === 'user-exist') {
          await this.setState({
            loading: false,
            showModalUserExists:true,
            currentStep: 1
          })
          this.cleanForm()
          logError('Rut comercial en uso', 'sign_up_error')
          sendMetric('ok_invalid_rut')
        } 
        if (registerResult.errorType === 'no-official-data' || registerResult.errorType === 'no-activity-data'){
          this.setState({
            loading: false,
            currentStep: 1,
            showModal: true
          })
      
          this.cleanForm()

          if(registerResult.errorType === 'no-official-data') logError('No es un rut comercial', 'sign_up_error')
          if(registerResult.errorType === 'no-activity-data') logError('Rut sin giro comercial', 'sign_up_error')
        } 
        if (registerResult.error &&  registerResult.errorType.includes("duplicate key error")){
          this.setState({
            duplicateError:true,
            loading: false,
            currentStep: 1,
          })
        }
      }   
    } catch (error) {
      console.error("Error ----->", error)
    }
  }

  onChange = (type:string, event:any) => {
    this.setState({
      data: {
        ...this.state.data,
        [type]: event.target.value,
      }
    })
  }

  onAcceptTermsConditions = () => {
    const {termsConditions, checkbox} = this.state

    this.setState({
      termsConditions: !termsConditions,
      checkbox: !checkbox
    })
  }

  hideToast = () => {
    this.setState({
      showError: false,
      messageError: '',
    })
  }

  onChangeInputNoProblem = (type: keyof sendform, value: sendform[keyof sendform]) => {
    this.setState(
      produce(this.state, draft => {
        switch (type) {
          case 'phone':
            draft.dataSendform[type] = value.trim()
            draft.validationSendform[type] = newValidatePhone(value)
            break
          case 'email':
            draft.dataSendform[type] = value
            draft.validationSendform[type] = validateEmail(value)
            break
          case 'user_contac':
            draft.dataSendform[type] = value
            draft.validationSendform[type] = value.length > 0
            break
          case 'comment':
            draft.dataSendform[type] = value
            draft.validationSendform[type] = value.length > 0
            break
        }

        if (
          draft.validationSendform['phone'] &&
          draft.validationSendform['email'] &&
          draft.validationSendform['comment']
        ) {
          draft.isFormValid = true
        } else {
          draft.isFormValid = false
        }
      }),
    )
  }

  goToProfile = () =>{
    this.setState({
      showModalUserExists: false,
    })
    this.props.goProfile("login")
  }
  
  handleShowModal = () => {
    this.setState({
      showModal: false,
    }, () => this.props?.history?.push('/contact'))
    sendMetric("exit_invalid_rut")
  } 

  cleanForm = () => {
    this.setState({
      data: {
        password: '',
        email: '',
        phone: '',
        user_contac: '',
        confirm_password: '',
      },
      validation: {
        password: false,
        email: false,
        phone: false,
        user_contac: false,
        confirm_password: false,
      },
      cleanUser:true
    })
  }

  validateCountry = (validatedCountry: string) => {
    const { country } = this.state
    const validateCountry = country && Object.entries(country).length > 0 && country.country === validatedCountry 
    return validateCountry
  }

  validateUser = async (user: any, approved: boolean) => {
    const { country } = this.state
    this.setState({
      username: user.username,
      approvedUserName: approved,
    })

    if (approved) {
      const isRegistered = await this.checkAlreadyRegistered(user.username)
      this.setState({ showModalUserExists: isRegistered })
    } 
  }
  
  checkAlreadyRegistered = async (username: any) => {
    try {
      const { country } = this.state
      return await checkRegistered(username, country.countryCode)
    } catch (error) {
      console.error(error)
      return false
    }
  }

  moveToNextStep = (validateOpenModal: boolean) => { 
    const { currentStep, country, businessTypesOptions } = this.state
    const countryCode = country?.country
    const shouldCheckModal = countriesWithBusinessModal.includes(countryCode)
    const hasOneBusinessType = businessTypesOptions?.length === 1

    const newState: any = { isBusinessModalOpen: true }

    if (!(validateOpenModal && shouldCheckModal && currentStep === 1 && hasOneBusinessType)) newState.currentStep = currentStep + 1

    this.setState(newState)
  }

  businessTypeConfirm = () => {
    const { checkRegisteredExistsOrNot } = this.state
    
   //  !checkRegisteredExistsOrNot && this.setState({isShowModalBusinessTypeConfirm: !isShowModalBusinessTypeConfirm})
    checkRegisteredExistsOrNot && this.setState({showModalUserExists: true})
    !checkRegisteredExistsOrNot && this.moveToNextStep(false) 
  }
  
  changeTermsModal = (val: boolean) => this.setState({ modalTermsOpen: val })

  validatePrefixNumber = (country:any) => Object.entries(country).length  &&  country.prefixNumber
  
  closeAlert = () => this.setState({ showAlert: false })

  validateFirstStep = () => {
    const { data: { user_contac, hunter, phone }, approvedUserName, country } = this.state
    const countryCode = country?.country

    const isContactValid = user_contac.length
    const isHunterValid = this.validateHunter(hunter, true)
    const isPhoneValid = isPhoneValidByCountry(phone)
    
    const validationsByCountry: any = {
      [countryCl]: isContactValid && approvedUserName,
      [countryPe]: isContactValid && approvedUserName,
      [countryMx]: isContactValid && isPhoneValid && isHunterValid,
    }

    return !validationsByCountry[countryCode]
  }

  validateSecondStep = () => {
    const { data: { phone, email }, username, country } = this.state
    const countryCode = country?.country

    const isEmailValid = validateEmail(email)
    const isUsernameValid = this.validateUsername(username)
    const isPhoneValid = isPhoneValidByCountry(phone)
    
    const validationsByCountry: any = {
      [countryCl]: isPhoneValid && isEmailValid,
      [countryPe]: isPhoneValid && isEmailValid,
      [countryMx]: isEmailValid && isUsernameValid,
    }

    return !validationsByCountry[countryCode]
  }

  validateUsername = (username: string) => {
    const { approvedUserName } = this.state
    if (username === '') return true
    return approvedUserName
  }

  isFormValid = () => {
    const {
      approvedUserName,
      termsConditions,
      country,
      data: { phone, email, user_contac, password, confirm_password }, 
    } = this.state

    const isPasswordValid = password.length >= 4 && (confirm_password.length && password === confirm_password);
    const isPhoneValid = isPhoneValidByCountry(phone)
    const isEmailValid = validateEmail(email)
    const isUserInfoValid = user_contac?.length && termsConditions
    const isUsernameValid = phoneAsUsernameCountries.includes(country.country) ? true : approvedUserName

    return isPasswordValid && isPhoneValid && isEmailValid && isUserInfoValid && isUsernameValid;
  } 

  renderHunterField = () => {
    const { country, data: { hunter } } = this.state
    let countryCode = country?.country

    if (!countriesWithHunters.includes(countryCode)) return ""

    return (
      <TextInput
        onChange={(event:any) => this.onChange('hunter', event)} 
        placeholder="Ej: 08"
        label="Vendedor"
        isValid={this.validateHunter(hunter, true)}
        error="Ingrese un código numérico de vendedor"
        value={hunter}
        type="text"
        toValidate={hunter}
        autoComplete="off"
        maxLength={3}
        informativeText="Esta información es opcional"
      />
    )
  }

  validateHunter = (hunter: string, isOptional: boolean) => {
    if (isOptional && hunter === "") return true
    const isNumeric = /^\d+$/.test(hunter)
    const isLengthValid = hunter?.length > 0 && hunter?.length <= 3

    return isNumeric && isLengthValid
  }

  renderPhoneField = () => {
    const { country, data: { phone } } = this.state
    let countryCode = country?.country
    
    if (phoneAsUsernameCountries.includes(countryCode)) return ""

    return (
      <TextInput
        iconDefault="icn_phone"
        iconValid="icn_phone-blue"
        iconInvalid="icn_phone-red"
        onChange={(event: any) => this.onChange('phone', event)}
        placeholder={phonePlaceholderByCountry()}
        label="Teléfono"  
        isValid={isPhoneValidByCountry(phone)}
        error={"Escribe tu número con el código de área"}
        value={phone}
        type="tel"
        toValidate={phone}
        maxLength={phoneLengthByCountry()}
        autoComplete="off"
      />
    )
  }

  renderConditionalPhone = () => {
    const { country, data: { phone } } = this.state
    let countryCode = country?.country

    if (!phoneAsUsernameCountries.includes(countryCode)) return ""

    return (
      <TextInput
        iconDefault="icn_phone"
        iconValid="icn_phone-blue"
        iconInvalid="icn_phone-red"
        onChange={(event: any) => this.onChange('phone', event)}
        placeholder={phonePlaceholderByCountry()}
        label="Teléfono"  
        isValid={isPhoneValidByCountry(phone)}
        error={"Escribe tu número con el código de área"}
        value={phone}
        type="tel"
        toValidate={phone}
        maxLength={phoneLengthByCountry()}
        autoComplete="off"
      />
    )
  }

  renderConditionalUsername = (step: number) => {
    const { renderUsernameField } = this.props
    const { country, data: { phone } } = this.state
    let countryCode = country?.country
    
    if (step == 1) {
      if (phoneAsUsernameCountries.includes(countryCode)) {
        return (
          <TextInput
            iconDefault="icn_phone"
            iconValid="icn_phone-blue"
            iconInvalid="icn_phone-red"
            onChange={this.onChangePhoneUsername}
            placeholder={phonePlaceholderByCountry()}
            label="Teléfono"  
            isValid={isPhoneValidByCountry(phone)}
            error={"Escribe tu número con el código de área"}
            value={phone}
            type="tel"
            toValidate={phone}
            maxLength={phoneLengthByCountry()}
            autoComplete="off"
          />
        )
      }
    
      return renderUsernameField(true, this.validateUser)

    } else if (step == 2) {
      if (!phoneAsUsernameCountries.includes(countryCode)) return ""
      return renderUsernameField(false, this.validateUser)
    }
  }

  onChangePhoneUsername = async (event: any) => {
    const val = event.target.value
    this.onChange('phone', event)
    
    if (isPhoneValidByCountry(val)) {
      const isRegistered = await this.checkAlreadyRegistered(val)
      this.setState({ showModalUserExists: isRegistered })
    }
  }

  renderBusinessConfirmationModal = () => {
    const { country, isBusinessModalOpen, businessTypesOptions, currentStep } = this.state
    const countryCode = country?.country
    const hasOneBusinessType = businessTypesOptions?.length === 1

    const businessesByCountry: any = {
      [countryPe]: "farmacias",
      [countryMx]: "tiendas",
    }

    const countryBusiness = businessesByCountry[countryCode]

    if (countriesWithBusinessModal.includes(countryCode) && hasOneBusinessType && countryBusiness && currentStep === 1) {

      return (
        <GuruAcceptModal
          className="btn-primary"
          textButton="Sí, continuemos"
          label={`Por el momento, solo trabajamos con ${countryBusiness}. ¿Estás de acuerdo?`}
          onClickButton={() => {
            const newState: any = { isBusinessModalOpen: false }
            newState.currentStep = currentStep + 1
            this.setState(newState)
          }}
          disabled={false}
          isOpen={isBusinessModalOpen}
        />
      )
    }

    return ""
  }

  nameLabelByCountry = (): string => {
    const {country} = this.state
    const countryCode = country?.country || countryPe

    const textLabel: any = {
      [countryCl]: 'Nombre',
      [countryPe]: 'Nombre Completo',
      [countryMx]: 'Nombre Completo',
    }
    
    return textLabel[countryCode] || 'Nombre Completo';
  }

  namePlaceholderByCountry = (): string => {
    const {country} = this.state
    const countryCode = country?.country || countryPe

    const textLabel: any = {
      [countryCl]: 'Ej: María',
      [countryPe]: 'Ej: María Rojas',
      [countryMx]: 'Ej: María Rojas',
    }
    
    return textLabel[countryCode] || 'Ej: María Rojas';
  }

  render() {
    const {
      data: { email, user_contac, password, confirm_password },
      showAlert, currentStep, loading, showError, messageError,
      showModal, showModalUserExists, checkbox, modalTermsOpen, termsText, duplicateError, isLoadingCoverUserGuest,
      businessTypesOptions, country
    } = this.state
    const steps = [
      { text: 'hola', stepNumber: 1, currentStep: 1 },
      { text: 'hola', stepNumber: 2, currentStep: 2 },
      { text: 'hola', stepNumber: 3, currentStep: 3 },
    ]
    const loadingPhrase = ['Verificando RUT Comercial', 'Guardando tu teléfono', 'Validando tu email']

    return (
      <div className="register-page">
        {this.renderBusinessConfirmationModal()}
        {isLoadingCoverUserGuest && this.validateCountry(countryCl) &&
          <LoadingCoverUserGuest 
            isLoading={isLoadingCoverUserGuest}
            title={"Estamos cargando tu información para completar el registro"}
            phrase={loadingPhrase}
            route="order"
          />}
          {showError &&
            <AlertModal 
              label={'Solicitud enviada'}
              text={messageError}
              buttonText={'Ok'}
              isOpen={showError}
              buttonAction={this.hideToast}
              onDismiss={this.hideToast}
            />
          }
          {showAlert &&
            <AlertModal 
              label={'Solicitud enviada'}
              text={'Su solicitud ya fue enviada, nos pondremos en contacto contigo a la brevedad'}
              buttonText={'Cerrar'}
              isOpen={showAlert}
              buttonAction={this.closeAlert}
              onDismiss={this.closeAlert}
            />
          }
          {showModalUserExists &&
            <AlertModal 
              label={`${dniByCountry()} ya existente`}
              text={`El ${dniByCountry()} que intentas registrar ya se encuentra inscrito en Almacén Gurú.`}
              buttonText={'Ingresa a tu cuenta'}
              isOpen={showModalUserExists}
              buttonAction={this.goToProfile}
              onDismiss={this.goToProfile}
            />
          }
          {showModal &&
            <AlertModal 
              label={`${dniByCountry()} sin giros comerciales`}
              text={`No pudimos registrar tu ${dniByCountry()} porque no posee giros comerciales válidos`}
              buttonText={'Comunícate con nosotros'}
              isOpen={showModal}
              buttonAction={this.handleShowModal}
              onDismiss={this.handleShowModal}
            />
          }
          {duplicateError &&
            <AlertModal 
              label={`Ocurrió un problema`}
              text={`No pudimos registrar tu ${dniByCountry()} por favor vuelve a intentar`}
              buttonText={'Reintentar'}
              isOpen={duplicateError}
              buttonAction={this.onButtonClick}
              onDismiss={this.onButtonClick}
            />
          }
          {modalTermsOpen &&
            <TermsConditionsModal 
              closeTermsModal={this.changeTermsModal}
              isOpen={modalTermsOpen}
              {...this.props} 
            />
          }
          <form className='form-container'>
            <div className="container-stepper">
              <Stepper currentStep={currentStep} steps={steps}/>
            </div>
            <div className="form-register">
              {currentStep === 1 && 
                <div className="container-form"> 
                  <TextInput 
                    iconDefault="nav_account"
                    iconValid="nav_account-blue"
                    onChange={(event:any) => this.onChange('user_contac', event)} 
                    placeholder={this.namePlaceholderByCountry()}
                    label={this.nameLabelByCountry()}  
                    isValid={!!user_contac.length}
                    error={"El nombre debe tener al menos un carácter"}
                    value={user_contac}
                    type="text"
                    toValidate={user_contac}
                    autoComplete="off"
                  />

                  {this.renderConditionalUsername(1)}

                  {this.renderHunterField()}

                  <div className="container-btn-stepper">
                    <ButtonComponent
                      className="btn-primary"
                      disabled={this.validateFirstStep()}
                      onClick={this.validateCountry(countryCl) ? this.businessTypeConfirm : () => this.moveToNextStep(true) }
                      text="Continuar"
                    />
                  </div>
                </div>
              }
              {currentStep === 2 &&
                <div>

                  {this.renderPhoneField()}

                  <TextInput 
                    iconDefault="icn_email" 
                    iconValid="icn_email-blue"
                    iconInvalid="icn_email-red"
                    onChange={(event:any) => this.onChange('email', event)} 
                    placeholder="Ej: maria@email.com"
                    label="Correo"  
                    isValid={!!validateEmail(email)}
                    error={"Tu email debe ser válido para recibir mensajes"}
                    value={email}
                    type="email"
                    toValidate={email}
                    autoComplete="off"
                  />

                  {this.renderConditionalUsername(2)}

                  <div className="container-btn-stepper">
                    <ButtonComponent
                      className="btn-primary"
                      disabled={this.validateSecondStep()}
                      onClick={() => this.moveToNextStep(false)}
                      text="Continuar"
                    />
                  </div>
                </div>
              }
              {currentStep === 3 &&
                <div>
                  <TextInput 
                    iconDefault="icn_password" 
                    iconInvalid="icn_password-red"
                    iconValid="icn_password-blue"
                    onChange={(event:any) => this.onChange('password', event)} 
                    placeholder="Ingresa tu contraseña"
                    label="Contraseña"
                    isValid={password.length > 3}
                    error={"La contraseña debe tener de 4 a 12 caracteres"}
                    value={password}
                    toValidate={password}
                    type="password"
                    maxLength={12}
                    autoComplete="off"
                  />

                  <TextInput 
                    iconDefault="icn_password" 
                    iconInvalid="icn_password-red"
                    iconValid="icn_password-blue"
                    onChange={(event:any) => this.onChange('confirm_password', event)} 
                    placeholder="Repite tu contraseña"
                    label="Confirmar Contraseña"
                    isValid={confirm_password.length && password === confirm_password}
                    error={"Recuerda que las contraseñas deben coincidir"}
                    value={confirm_password}
                    toValidate={confirm_password}
                    type="password"
                    maxLength={12}
                    autoComplete="off"
                  />
                  <div className="container-checkbox">
                    <div onClick={this.onAcceptTermsConditions} className={`checkbox ${checkbox ? 'checked' : ''}`}></div>
                    <div className="label-terms">
                      <span>He leído y acepto los </span>
                      <div className="terms-text" onClick={() => this.changeTermsModal(true)}>{termsText}</div>
                    </div>
                  </div>

                  <div className="container-btn-stepper">
                    <ButtonComponent
                      className={`btn-primary ${loading ? 'loading' : ''}`}
                      disabled={!this.isFormValid()}
                      onClick={this.onButtonClick}
                      text="Continuar"
                    />
                  </div>
                </div>
              }

              <div className="help">
                <ContactSupport />
              </div>
            </div>
          </form>
      </div>
    )
  }
}

export default withRouter(RegisterUser)
