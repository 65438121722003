import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { StaticContext } from 'react-router-dom'

// Components
import ModalInvitedUser from '../../components/modals/modal-invited-user/ModalInvitedUser'
import { IonFooter } from '@ionic/react'

// Models
import User from '../../models/User'

// Icon
import iconHomeActive from '../../assets/icons/nav_home-blue.svg'
import iconHomeInactive from '../../assets/icons/nav_home.svg'
import iconOrderInactive from '../../assets/icons/nav_order.svg'
import iconOrderActive from '../../assets/icons/nav_order-blue.svg'
import iconProfileInactive from '../../assets/icons/nav_account.svg'
import iconProfileActive from '../../assets/icons/nav_account-blue.svg'
import iconCouponInactive from '../../assets/icons/nav_coupons.svg'
import iconCouponActive from '../../assets/icons/nav_coupons-blue.svg'
import iconCardInactive from '../../assets/icons/nav_payment.svg'
import iconCardActive from '../../assets/icons/nav_payment-blue.svg'


// Utils
import { displayByCountry } from '../../utils/currentCountry'
import { countryMx, countryPe, getCountryCode } from '../../utils/countriesTexts'
import { getLocalStorageObject } from '../../utils/localstorageData'
import { validateIfIsInvitedUser } from '../../utils/invitedUser'

// Events
import { sendMetric } from '../firebase/firebaseTags'

import './Footer.scss'
import { isOfficeSupported } from '../../utils/validateCurrentOffice'

type IPathParams = {}

type RouteState = {}

type Props = RouteComponentProps<IPathParams, StaticContext, RouteState> & {user: User, ordersConfirm: any}

interface State {
  value: ''
  isActive: boolean
  isActive1: boolean
  isActive2: boolean
  isActive3: boolean
  isActive4: boolean
  notOffice: boolean
  singleProvider?: boolean
  country: any
  isOrdersCorfirmed: boolean
  showAlertIsUserInvited: boolean
  isOfficeSupportedResult: boolean
}

class Footer extends React.PureComponent<Props, State> {
  state: State = {
    value: '',
    isActive: false,
    isActive1: false,
    isActive2: false,
    isActive3: false,
    isActive4: false,
    notOffice: false,
    singleProvider: false,
    country:{},
    isOrdersCorfirmed: false,
    showAlertIsUserInvited: false,
    isOfficeSupportedResult: true,
  }

  countryThatAllowFooter: any[] = [countryPe]

  async componentDidMount() {
    const currentOffice = localStorage.getItem('currentOffice')
    setInterval(() => {
      const isOfficeSupportedResult: boolean = isOfficeSupported()
      this.setState({isOfficeSupportedResult})
    }, 1500)

    this.setState({
      notOffice: currentOffice === 'undefined', 
      country:displayByCountry(),
    })   

    const orders = this.props.ordersConfirm
    localStorage.setItem("ordersConfirm", JSON.stringify(orders?.length))

    const isOrdersCorfirmed = this.props.ordersConfirm.length > 0
    this.setState({isOrdersCorfirmed})

    setInterval(async () => {
      const orders = localStorage.getItem('ordersConfirm')
      const ordersConfirm = orders ? JSON.parse(orders) : ''

      this.setState({isOrdersCorfirmed: !(ordersConfirm === 0) })
     
     }, 2000)
  }

  componentWillReceiveProps() {
    this.handleButtonClick(window.location.pathname)
    const mainPath = `/${window.location.pathname.split('/')[1]}`
    if(mainPath === '/home-providers') {
      const singleProviderStorage = localStorage.getItem('@providers')
      const singleProviderObject = singleProviderStorage ? JSON.parse(singleProviderStorage) : null
      const dataVendors = Object.values(singleProviderObject).filter((item:any)=> item.isActiveInComune !== false)
      const singleProvider = dataVendors.length === 1
      this.setState({singleProvider})
    }
  }

  handleButtonClick = (value: any) => async () => {
    const isInvitedUser = await validateIfIsInvitedUser();
    const { notOffice, singleProvider } = this.state
    const mainPath = `/${value.split('/')[1]}`
    if(!isInvitedUser || (((value === '/home' || (mainPath === '/home-providers' && singleProvider)) || value === '/coupons') && !notOffice)) {
      if ((value === '/home' || (mainPath === '/home-providers' && singleProvider)) && !notOffice) {
        this.setState(
          {
            value,
            isActive: true,
            isActive1: false,
            isActive2: false,
            isActive3: false,
            isActive4: false,
          },
          () => {
            sendMetric("click_start_button");
            this.props?.history?.push(`${value}`)
          },
        )
      } else if (value === '/coupons' && !notOffice) {
        this.setState(
          {
            value,
            isActive: false,
            isActive1: false,
            isActive2: false,
            isActive3: false,
            isActive4: true,
          },
          () => {
            sendMetric("click_coupons");
            this.props?.history?.push(`${value}`)
          },
        )
      } else if (value === '/card' && !notOffice) {
        this.setState(
          {
            value,
            isActive: false,
            isActive1: true,
            isActive2: false,
            isActive3: false,
            isActive4: false,
          },
          () => {
            sendMetric("click_card");
            this.props?.history?.push(`${value}`)
          },
        )
      } else if (value === '/profile' && !notOffice) {
        this.setState(
          {
            value,
            isActive: false,
            isActive1: false,
            isActive2: true,
            isActive3: false,
            isActive4: false,
          },
          () => {
            sendMetric("click_profile");
            this.props?.history?.push(`${value}`)
          },
        )
      } else if ((value === '/orders' || value === '/order') && !notOffice) {
        this.setState(
          {
            value,
            isActive: false,
            isActive1: false,
            isActive2: false,
            isActive3: true,
            isActive4: false,
          },
          () => {
            sendMetric("click_orders");
            this.props?.history?.push(`${value}`)
          },
        )
      } 
    } else {
      this.setState({showAlertIsUserInvited: true})
    }
  }

  hidePaymentButton() {
    const noApplyCountries = [countryPe, countryMx]
    const country = getLocalStorageObject('countryName') || ''
    return noApplyCountries.find(c => c === country?.countryCode)
  }

  render() {
    const { singleProvider, country, isOrdersCorfirmed } = this.state
    const mainPath = `/${window.location.pathname.split('/')[1]}`
    const isHpAndSingleProvider = (mainPath === '/home-providers' && singleProvider)
    let isActive = this.state.isActive
    isActive = (window.location.pathname === '/home' || isHpAndSingleProvider ) ? true : false
    let isActive1 = this.state.isActive1
    isActive1 = window.location.pathname === '/card' ? true : false
    let isActive2 = this.state.isActive2
    isActive2 = window.location.pathname === '/profile' ? true : false
    let isActive3 = this.state.isActive3
    isActive3 = (window.location.pathname === '/orders' || window.location.pathname === '/order')  ? true : false
    let isActive4 = this.state.isActive4
    isActive4 = window.location.pathname === '/coupons' ? true : false

    const homeIcon = isActive ? iconHomeActive : iconHomeInactive
    const paymentsIcon = isActive1 ? iconCardActive : iconCardInactive
    const profileIcon = isActive2 ? iconProfileActive : iconProfileInactive
    const ordersIcon = isActive3 ? iconOrderActive : iconOrderInactive
    const couponsIcon = isActive4 ? iconCouponActive : iconCouponInactive

    const buttonsTexts = {
      home: "Inicio",
      payments: "Pagos",
      profile: getCountryCode() === countryMx ? 'Perfil' : 'Cuenta',
      orders: "Pedidos",
      coupons: "Cupones",
    }

    return (
      <>
        <IonFooter >
          <div className="footer-menu-home">
            <button className={isActive ? 'active' : ''} onClick={this.handleButtonClick('/home')}>
              <div><img className="footer-option-icon" src={homeIcon} alt=""/></div>
              <p className="paragraph-3 footer-title">{buttonsTexts.home}</p>
            </button>
            {!this.hidePaymentButton() &&
              <button value="/card" disabled={!this.state.isOfficeSupportedResult} className={isActive1 ? "active":""} onClick={this.handleButtonClick('/card')}>
                <div><img className="footer-option-icon" src={paymentsIcon} alt="" /></div>
                <p className="paragraph-3 footer-title">{buttonsTexts.payments}</p>
              </button>
            }
            <button value="/profile" className={isActive2 ? 'active' : ''} onClick={this.handleButtonClick('/profile')}>
              <div><img className="footer-option-icon" src={profileIcon} alt="" /></div>
              <p className="paragraph-3 footer-title">{buttonsTexts.profile}</p>   
            </button>
            <button disabled={!this.state.isOfficeSupportedResult} value="/orders" className={isActive3 ? "active":""} onClick={this.handleButtonClick('/orders')}>
              <div className="orders">
                <img className="footer-option-icon" src={ordersIcon} alt="" />
                {isOrdersCorfirmed && <div className="check-orders"></div>}
              </div>
              <p className="paragraph-3 footer-title">{buttonsTexts.orders}</p>
            </button>  
            <button disabled={!this.state.isOfficeSupportedResult} value="/coupons" className={isActive4 ? "active":""} onClick={this.handleButtonClick('/coupons')}>
              <div><img className="footer-option-icon" src={couponsIcon} alt="" /></div> 
              <p className="paragraph-3 footer-title">{buttonsTexts.coupons}</p>
            </button> 
          </div> 
        </IonFooter>
        <ModalInvitedUser 
          history={this.props.history}
          user={this.props.user}
          isOpen={this.state.showAlertIsUserInvited}
          closeModal={() => this.setState({showAlertIsUserInvited: false})}
        />
      </>
    );
  }
}
export default withRouter(Footer)
