
import React from 'react'

import { IonSpinner } from '@ionic/react'

interface IProps {
  singleProvider:any
  categories:any
  history:any
  vendorName?:any
}

interface IState { 
}

export default class CategoriesProviders extends React.PureComponent<IProps, IState>{ 
  state: IState = {
  }

  goToCategory = async (category: any, brandId: any = null) => {
    const { singleProvider, vendorName } = this.props

    this.props?.history?.push(`/provider/${singleProvider}/category/${category._id}`, {
          id: category._id,
          title: category.name,
          singleProvider:singleProvider,
          categoryId:category._id,
          nameProvider: vendorName || ''
        })
  }


  render(){
    const { categories } = this.props

    return (
      <div className='categories-list'>
        {categories &&  categories.length > 0 ? categories.map((category: any) => {
         return (
            <div
            key={category._id}
            className="categories-card"
            onClick={()=>  this.goToCategory(category)}
            >
              <div className='logo' style={{ backgroundImage: `url(${process.env.REACT_APP_BFF_IMAGE}categories/${category.logo})`}} ></div>
              <div className='name-categories' >{category.name}</div>
            </div>
          )
        })
        :
        <div className="loading-offers">
          <p className='loading'>Cargando categorias</p>
          <IonSpinner className="spinner-mid" name="crescent" /> 
        </div> 
        }
      </div>
    )
  }
}


