import React, { useEffect, useState } from 'react'
import { GuruSlider, GuruSlide } from '../../components';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { registerHomeBrandSliderItemEvent } from '../../pages/firebase/firebaseTags';
import { getHomeBrands, THomeBrand } from '../../clients/brands';
import BrandIcon from '../brand-icon/BrandIcon';
import { VisibilityTracker } from '../../hoc';
import './HomeBrandsSliderStyle.scss'

interface Brand {
  _id: string,
  name: string,
  urlLogo: string
}

const brandsSlidesOptions: any = {autoHeigth: true, slidesPerView: 'auto'}

type THomeBrandsSliderProps = RouteComponentProps;

const HomeBrandsSlider: React.FC<THomeBrandsSliderProps> = (props) => {
  const [homeBrands, setHomeBrands] = useState<THomeBrand[]>([]);
  const {history} = props;

  useEffect(() => {
    async function loadHomeBrands() {
      const homeBrandsResult = await getHomeBrands()
      setHomeBrands(homeBrandsResult)
    }

    loadHomeBrands()
  }, [])

  if (!homeBrands.length) return null

  const handleWhenBrandIsVisible = (brand: Brand) => {
    const placement = 'home'

    registerHomeBrandSliderItemEvent({
      eventName: 'home_carousel_brand_view',
      brandId: brand._id,
      brandName: brand.name,
      placementName: placement,
    })


  }

  const handleWhenBrandIsClicked = (brand: Brand) => {
    const placement = 'home'

    registerHomeBrandSliderItemEvent({
      eventName: 'home_carousel_brand_click',
      brandId: brand._id,
      brandName: brand.name,
      placementName: placement,
    })

    history.push(`/home-brands-detail/${brand._id}`, {name: brand.name})
  }

  return (
    <>
      <div className="container-distributo">
        <h1 className="providers-section-title">Marcas</h1>
      </div>
      <div className='container-distributo home-brands-container'>
        <GuruSlider 
          pager={false} 
          scrollbar={false} 
          slidesPerView='auto'
          autoHeight={true}
        >
          {homeBrands.map((brand: Brand) => {
            return (
              <GuruSlide 
                key={brand._id} 
                className='slide-home-brand'
              >
                <VisibilityTracker onVisible={() => handleWhenBrandIsVisible(brand)} threshold={0.5}>
                  <BrandIcon
                    urlLogo={brand.urlLogo}
                    handleAction={() => handleWhenBrandIsClicked(brand)}
                  />
                </VisibilityTracker>
              </GuruSlide>
            )
          })}
        </GuruSlider>
      </div>
    </>
  )
}

export default withRouter(React.memo(HomeBrandsSlider))
