import React from 'react'
import {
  IonPage,
  IonContent,
  IonLabel,
  IonRadio,
  IonList,
  IonRadioGroup,
  IonModal,
} from '@ionic/react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

//Models
import User from '../../models/User'
import Order from '../../models/Order'

//components
import { cancelOrderEvent } from '../firebase/firebaseTags'
import BottomProceedBtn from '../../components/bottom-proceed-btn/BottomProceedBtn'

import HeaderWithoutToolbar from '../../components/header-without-toolbar/HeaderWithoutToolbar'
import arrowBack from './../../assets/icons/arrow-back-blue.svg'
import ButtonComponent from '../../components/basic-button/ButtonComponent'
import AlertModal from '../../components/modals/AlertModal'
import './Order.scss'

type IPathParams = {
  id: string
}

type IProps = RouteComponentProps<IPathParams> & {
  readonly user: User
  readonly orders: Order
}

interface State {
  order: any
  comment: string
  showModal: boolean
  menssage: ''
  spinner: boolean
  answerQuestion: any
  question: any
  modalAnulation: boolean
  anyOptionHasBeenTouched: boolean
  selectQuestions: string
  limit: number
  page: number
}

class OrderDelete extends React.PureComponent<IProps, State> {
  state: State = {
    order: {},
    comment: '',
    showModal: false,
    menssage: '',
    spinner: false,
    answerQuestion: '',
    question: '',
    modalAnulation: false,
    anyOptionHasBeenTouched: false,
    selectQuestions: '',
    limit: 10,
    page: 0,
  }

  async componentDidMount() {
    let result = await this.props.orders.questions()

    result = result.map((question:any) => {
      question.answer = null 
      return question
    })   
    this.setState({
      order: this.props.history.location.state,
      answerQuestion: result
    })
  }

  getAnswerQuestions = () => {
    const { selectQuestions } = this.state
  
    let temp:any = {}
    temp[selectQuestions] = 'Si'
    return temp
  }

  confirmOrder = async () => {
    const { order, comment } = this.state 

    let answers = this.getAnswerQuestions()
    this.setState({ spinner: true })

    const result = await this.props.orders.orderDelete(order._id, comment, answers)

      if(result?.status === 200){
        const { _id, providerId, provider: { name: providerName }, orderedAt } = order
        cancelOrderEvent(_id, providerId, providerName, orderedAt)

        this.setState({
          menssage:result.respuesta,
          spinner:false
        })
        this.goToOrder()
    }
  }


  confirm = async () => {
    this.setState({
      showModal:true,
    })
  }

  goToOrder = async () => {
    const { order } = this.state

    const orders = await this.props.orders.findOne(order._id)
 
    if(Object.keys(orders).length > 0)  {
      this.confirm()
      this.props?.history?.push('/order', orders)
    }
  }

  setSelected = (answer:string) => { 
    this.setState({
      anyOptionHasBeenTouched:true,
      selectQuestions: answer
    })
  }


  onDismissModal =() => { 
    this.setState({
      showModal:false
    })
  }

  modalConfirmAnulation = () => {
    const { showModal } = this.state

      return (
        <IonModal 
          className="anulated-confirm" 
          backdropDismiss={false} 
          isOpen={showModal}>
            <div className="content">
              <div className="title">Anular</div>
              <div className="content-info">Por favor, confirme la anulación del pedido. </div>
              <div className="footer-order" >
                <div className="btn-order" onClick={this.onDismissModal}>Cancelar</div>
                <div className="btn-order-confirm" onClick={() => this.confirmOrder()}>Confirmar</div>
              </div>
            </div>
        </IonModal>
      )
    }

  

  showAnswerQuestion = () => {
    const { answerQuestion, selectQuestions } = this.state

    return(
      answerQuestion.map(({id, name}:any) => (
        <div className="container-questions-answers" id={id}>
          <div className="container-question">{name}
            <div className="container-checkbox" onClick={() => this.setSelected(name)}>
              <div className={`checkbox ${selectQuestions === name ? 'checked' : ''}`}></div>
            </div>
          </div>
        </div>
      ))
    )
  }


  render() {
    const { history } = this.props
    const { anyOptionHasBeenTouched, answerQuestion, order, showModal } = this.state

    return (
      <IonPage className="cart-page-order" >
        <HeaderWithoutToolbar 
          onClick={history.goBack}
          text={order?.provider?.alias || ''}
          icon={arrowBack}
        />
        <IonContent className="container-anulate-order" >
          <div className='card-delete'>
            <div className='text-delete'>Anula el pedido {order.orderNumber}</div>
            <div className='subtitle-delete'>Por favor, elija el motivo:</div>                                                                                                                                                                                                                                                                                                        
            <div className="container-list-questions">
              {answerQuestion && this.showAnswerQuestion()}
            </div>
          </div>
        </IonContent>
        <div className="container-btn-anulate-order">
          <ButtonComponent 
            className="btn-primary"
            text="Anular pedido"
            onClick={this.confirm}
            disabled={!anyOptionHasBeenTouched}
          />
        </div>
        <AlertModal 
          label="¿Estás seguro?"
          buttonText="Confirmar"
          text="Por favor, confirma que quieres anular tu pedido.                          "
          buttonAction={this.confirmOrder}
          isOpen={showModal}
          onDismiss={this.onDismissModal}
        />
      </IonPage>
    )
  }
}

export default withRouter(OrderDelete)
