import React, { useState, useRef } from 'react';
import { IonIcon } from '@ionic/react';
import { GuruSlider, GuruSlide } from '../components';
import arrowBack from '../assets/icons/act_photo-left.svg';
import arrowNext from '../assets/icons/act_photo-right.svg';

import './SliderImages.scss'

const slideOpts = {
  speed: 400,
  slidesPerView: 'auto',
  preloadImages: false,
  observer: true,
  observeParents: true
};

interface Props {
  productMultiplesImages: any[]
  getCurrentSliderIndex: any
  onClickPhoto: any
}

export const SliderImages: React.FC<Props> = ({productMultiplesImages, getCurrentSliderIndex, onClickPhoto}) => {
  const mySlides = useRef<any>(null);
  const [sliderIndex, setSliderIndex] = useState(0)
  const [medias, ] = useState(productMultiplesImages)
  
  const onIconClick = async (direction: string) => {
    // const swiper = await mySlides?.current?.getSwiper();
    // if (direction === "next") swiper.slideNext();
    // if (direction === "prev") swiper.slidePrev();
  }

  const onSliderChange = async () => {
    // const swiper = await mySlides?.current?.getSwiper();
    // setSliderIndex(swiper.activeIndex)
    // getCurrentSliderIndex(swiper.activeIndex)
  }

  const spliceRouteImage = (imagePath: string) => {
    const splittedPath = imagePath.split('static/')
    const completePath = `${process.env.REACT_APP_BFF_IMAGE}${splittedPath[splittedPath.length -1]}`
    return completePath
  }

    return (
      <div className="slides-container">
        { sliderIndex !== 0 &&
          <IonIcon
            className="slider-arrow-action arrow-icon-left" 
            onClick={() => onIconClick('prev')} 
            icon={arrowBack} 
            
          />
        }
        <GuruSlider 
          onSlideTransitionStart={() => onSliderChange()} 
          sliderRef={mySlides} 
          speed={400}
          slidesPerView='auto'
          observer={true}
          observeParents={true}
        >
          { medias.map((media, index) => (
            <GuruSlide 
              key={`slider-${index}`} 
              id={`slider-image-${index}`} 
            >
              <img onClick={() => onClickPhoto(sliderIndex)} className="main-picture" src={spliceRouteImage(media.url)} alt="Imagen del producto"/> 
            </GuruSlide>
          ))}
        </GuruSlider>
        { sliderIndex !== medias.length - 1 &&
          <IonIcon 
            onClick={() => onIconClick('next')} 
            className="slider-arrow-action arrow-icon-right" 
            icon={arrowNext} 
            
          />
        }
      </div>
    )
};

export default SliderImages;