import React from 'react'
import { GuruSlider, GuruSlide } from '../../..'
import { VisibilityTracker } from '../../../../hoc'
import BrandIcon from '../../../brand-icon/BrandIcon'
import { registerHomeBrandSliderItemEvent } from '../../../../pages/firebase/firebaseTags'
import './BrandSlider.scss'

interface Brand {
    _id: string,
    name: string,
    logo: string
}

interface BrandSliderProps {
    brands: Brand[]
    placementName: string
    onBrandIsVisible?: any
    onBrandIsClicked?: any

}

export const BrandSlider: React.FC<BrandSliderProps> = ({
    brands,
    placementName,
    onBrandIsClicked,
    onBrandIsVisible
}) => {

    const handleWhenBrandIsVisible = (brand: Brand) => {
        registerHomeBrandSliderItemEvent({
            eventName: `${placementName}_carousel_brand_view`,
            brandId: brand._id,
            brandName: brand.name,
            placementName: placementName,
        })

        if(onBrandIsVisible) {
            onBrandIsVisible()
        }

    }
    
    const handleWhenBrandIsClicked = (brand: Brand) => {
        registerHomeBrandSliderItemEvent({
            eventName: `${placementName}_carousel_brand_click`,
            brandId: brand._id,
            brandName: brand.name,
            placementName: placementName,
        })

        if(onBrandIsClicked) {
            onBrandIsClicked(brand)
        }
    }

    return (
        <GuruSlider 
          className='brand-slider'
          pager={false} 
          scrollbar={false} 
          slidesPerView='auto'
          autoHeight={true}
        >
          {brands?.map((brand: Brand) => {
            return (
              <GuruSlide 
                key={brand._id} 
                className='brand-slide'
              >
                <VisibilityTracker onVisible={() => handleWhenBrandIsVisible(brand)} threshold={0.3}>
                  <BrandIcon
                    urlLogo={brand.logo}
                    handleAction={() => handleWhenBrandIsClicked(brand)}
                  />
                </VisibilityTracker>
              </GuruSlide>
            )
          })}
        </GuruSlider>
    )
}