import React from 'react'
import {
  IonPage,
  IonContent,
  IonProgressBar,
  IonImg,
  IonHeader,
} from '@ionic/react'
import moment from 'moment'

import { groupBy } from 'lodash'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import Delivery from '../../models/Delivery'
import './Deliveries.scss'

import cartToolbarIcon from './../../assets/icons/icon_carrito.svg'
import withoutDeliveriesIcon from './../../assets/icons/sin_despacho.svg'
import ToolBar  from '../../components/tool-bar/ToolBar'
import { CartModel } from '../../models/CartModel'
import { navigateToCart } from '../../utils/navigation'

type IPathParams = {}

type IProps = RouteComponentProps<IPathParams> & {
  deliveries: Delivery
  cartModel: CartModel
}

interface State {
  deliveries: any
  limit: any
  loading: boolean
  page: any
}

class Deliveries extends React.PureComponent<IProps, State> {
  state: State = {
    deliveries: [],
    limit: 10,
    loading: true,
    page: 0,
  }

  async componentDidMount() {
    const deliveries = await this.props.deliveries.get(this.state.page, this.state.limit)
    this.setState({
      deliveries,
      loading: false,
    })
  }

  goToCart = () => {
    const { props } = this
    navigateToCart(props.history, props.location.state || props.history.location.state);

  }

  groupedDeliveries = (deliveries: any) => {
    const today = new Date()
    return groupBy(deliveries, (delivery: any) => {
      const isToday = moment(today).isSame(new Date(delivery.dispatchDate), 'day')
      return isToday ? 'Hoy' : moment(new Date(delivery.dispatchDate)).format('DD [de] MMMM')
    })
  }

  renderOrderList(key: any, orders: any) {
    return (
      <div key={key} className="list-orders">
        <div className="title">{key}</div>
        <div className="list">
          {orders.map((order: any) => {
            return (
              <div key={order._id} className="order">
                <div className="provider">
                  {order.provider && order.provider.alias}
                  <div className="date">{moment(order.orderedAt).fromNow()}</div>
                </div>
                <div className="dispatch-hour">{order.dispatchHour}</div>
                <div className="button-wrapper"></div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  render() {
    const { history, cartModel } = this.props
    const { deliveries, loading } = this.state
    const length = deliveries.length
    const grouped = this.groupedDeliveries(deliveries)
    const cartLocalStorage =  localStorage.getItem('@cart')
    let cart:any = ''
    if(cartLocalStorage){
      cart = JSON.parse(cartLocalStorage).products
    }
 
    cart = cart!=='' && cart.filter((item:any) => {return item.paid === false}) 
    return (
      <IonPage className="deliveries-page" >
        <IonHeader >
          <ToolBar
            title="Despachos"
            secondaryButtons={[{ type: 'back', onClick: history.goBack }]}
            primaryButtons={[
              { key: 'cart', onClick: this.goToCart, icon: cartToolbarIcon, badge: cart.length },
            ]}
            cartModel={cartModel}
          />
        </IonHeader>
        {/* Without items */}
        <IonContent >
          {loading && <IonProgressBar type="indeterminate" ></IonProgressBar>}
          {!loading && length === 0 && (
            <div className="without-products">
              <IonImg src={withoutDeliveriesIcon}  />
              <div className="message">Sin despachos.</div>
            </div>
          )}
          {!loading && length > 0 && (
            <div className="wrapper-orders">
              {Object.keys(grouped).map((key: any) => {
                return this.renderOrderList(key, grouped[key])
              })}
            </div>
          )}
        </IonContent>
      </IonPage>
    )
  }
}

export default withRouter(Deliveries)
