import React, { useState, useEffect, useRef } from 'react'
import {
  IonButton
} from '@ionic/react'
import { GuruSlider, GuruSlide} from '../../components'
import { Plugins } from '@capacitor/core'
import { StaticContext } from 'react-router-dom'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { registerBasicUserInfoEvent } from '../../pages/firebase/firebaseTags'
import ButtonComponent from '../basic-button/ButtonComponent'
import { VisibilityTracker } from '../../hoc'

import './Onboarding.scss'

const { Device } = Plugins

interface IPathParams {}

type IProps = RouteComponentProps<IPathParams, StaticContext> & {
  loginShowCases?:any
  onboarding: any
  nextPagePath: string
  onclickNextPagePath: (type:any) => void
  onClickInOnboarding?: any
  from: string
}

const slideOpts = {
  initialSlide: 0,
  speed: 400,
}

const Onboarding: React.FC<IProps> = props => {
  const [sliderIndex, setSliderIndex] = useState(0)
  const [deviceInfo, setDeviceInfo] = useState('')
  const [sliderOnboarding, setSliderOnboarding] = useState<any>([])
  const [loginShowCases, ] = useState<any>(props.loginShowCases?.sectionsVisibility)
  const mySlides = useRef<any>(null);

  useEffect(() => {
    get()
  }, []) 

  const get = async () => {
    registerBasicUserInfoEvent({
      eventName: 'view_onboarding',
      placementName: 'onboarding'
    })
    props.onboarding && setSliderOnboarding(props.onboarding)
  }

  useEffect(() => {
    getDeviceInfo()
  }, [deviceInfo])

  const getDeviceInfo = async () => {
    const platform = (await Device.getInfo()).platform
    setDeviceInfo(platform)
  }


  const onSliderChange = async () => {
    // const swiper = await mySlides?.current?.getSwiper();
    // setSliderIndex(swiper.activeIndex)
  }

  const onIconClick = async () => {
    // const swiper = await mySlides?.current?.getSwiper();
    // swiper.slideNext();
  }

  const onIconClickSkip = () => {
    const { nextPagePath, onclickNextPagePath } = props
    registerBasicUserInfoEvent({
      eventName: 'close_onboarding',
      placementName: 'onboarding'
    })

    if(nextPagePath === 'login' && onclickNextPagePath) onclickNextPagePath(false)
    if(nextPagePath === 'profile') props?.history?.push('/home')
  }

  const mapOnboardingImages = (onboarding:any) => {
    return (
      onboarding.map((item:any, index: number) =>{
        return(
          <VisibilityTracker threshold={0.3} onVisible={() => {
            registerBasicUserInfoEvent({
              eventName: 'view_onboarding_slide',
              placementName: 'onboarding',
              item_name: `slide-${index}`
            })
          }}>
            <GuruSlide className="git-slide">
              <div className="slide-info-wrapper">
                <img className="img-slide-onboarding" src={item.url} alt="Imagen del producto"/> 
                <div className="text-info">{item.text}</div>
              </div>
            </GuruSlide>
          </VisibilityTracker>
        )
      }))
  }
  
  const skipOnboarding = () => {
    registerBasicUserInfoEvent({
      eventName: 'close_onboarding',
      placementName: 'onboarding'
    })
    props.onclickNextPagePath && props.onclickNextPagePath(false)
  }
  
  const nameBtn = () => sliderIndex !== sliderOnboarding.length - 1 ? "Continuar" : "Comenzar" 

  const functionBtnClick = () => sliderIndex !== sliderOnboarding.length - 1 ? onIconClick() : onIconClickSkip()

  const classNameBtn = () => sliderIndex !== sliderOnboarding.length - 1 ? "btn-next" : "btn-skip" 

  return (
    <div className="page-omboarding">
      <div className={`container-skip-page ${(deviceInfo === 'ios') ? 'this-is-ios' : ''}`}>
        <ButtonComponent 
          className="btn-tertiary btn-skip-page" 
          onClick={skipOnboarding}
          text="Saltar"
        />
      </div>
      <div className="container-slides">
        <GuruSlider 
          className="slides-git"
          pager={true}
          speed={400}
          initialSlide={0}
          onSlideTransitionStart={() => onSliderChange()}
          sliderRef={mySlides}          
        >
            {props.onboarding && mapOnboardingImages(props.onboarding)}
          </GuruSlider>
      </div>
      <div className="container-btn">
          { props.from === 'profile' &&
            <IonButton  
              expand="block"
              className={classNameBtn()}
              onClick={functionBtnClick}            >
              {nameBtn()}
            </IonButton>
          }
      </div>
      <style>
        {`
          .footer-menu-home {
            display: none !important;
          }
        `}
      </style>
    </div>
  )
}

export default withRouter(Onboarding)
