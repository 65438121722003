import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { CartModel } from '../../models/CartModel'

type IPathParams = {}

interface State {}

type Props = RouteComponentProps<IPathParams> & {
  cartModel: CartModel
}

 class Logout extends React.PureComponent<Props, State> {
    state: State = {}

    componentDidMount() {
      this.signOut();
    }

		signOut = async () => {
      const { cartModel } = this.props
      localStorage.clear();
      await cartModel.clearCart()  
      this.props?.history?.push("/no-country")
      window.location.reload();
		}

    render() {
			return (null)
		}
  }
	
export default (withRouter(Logout))

