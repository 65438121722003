import React from 'react';
import { IonIcon, IonButtons } from '@ionic/react';

interface Props {
  iconButton: any
  onClickAction: any
}

export const IconButtonComponent: React.FC<Props> = ({iconButton, onClickAction}) => {

  return (
    <div>
      <IonButtons slot="secondary" >
          <IonIcon
          slot="icon-only"
          onClick={() => onClickAction()}
          icon={iconButton}           />
        </IonButtons>
    </div>
  )
};

export default IconButtonComponent;