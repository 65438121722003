import React, { useState } from 'react';
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";

interface GuruLightboxProps {
  images: string[];
  initialIndex: number;
  onClose: () => void;
  onMovePrev: () => void;
  onMoveNext: () => void;
}

const GuruLightbox: React.FC<GuruLightboxProps> = ({ 
  images, 
  initialIndex, 
  onClose,
  onMovePrev,
  onMoveNext
}) => {
  const [currentIndex, setCurrentIndex] = useState<number>(initialIndex);

  const slides = images.map(src => ({ src }));

  return (
    <Lightbox
      slides={slides}
      open={true}
      index={currentIndex}
      close={onClose}
      plugins={[Zoom]}
      zoom={{
        maxZoomPixelRatio: 1, // Esto efectivamente deshabilita el zoom
        zoomInMultiplier: 1
      }}
      styles={{ container: { padding: 60 } }}
      on={{
        view: ({ index }: { index: number }) => {
          setCurrentIndex(index);
          if (index > currentIndex) {
            onMoveNext();
          } else if (index < currentIndex) {
            onMovePrev();
          }
        },
      }}
    />
  );
};

export default GuruLightbox;