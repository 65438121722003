import React from 'react';
import { Rate } from 'antd';
import { StarFilled } from '@ant-design/icons';

const StarRating = ({ info, onChange }) => {
  const { title, subtitle, _id } = info;

  const handleChange = (value) => {
    onChange({
      questionId: _id,
      options: [{ label: '', value }]
    });
  };

  return (
    <div className="container-surveys">
      <h3>{title}</h3>
      {subtitle && <h4>{subtitle}</h4>}
      <Rate
        character={<StarFilled />}
        style={{ fontSize: 50 }}
        onChange={handleChange}
      />
    </div>
  );
};

export default StarRating;