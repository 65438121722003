import React from 'react'
import { ProviderModel } from '../../models/ProviderModel'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { IonPage, IonHeader, IonContent, IonButton } from '@ionic/react'
import ToolBar from '../../components/tool-bar/ToolBar'
import cartToolbarIcon from '../../assets/icons/icon_carrito.svg'
import dispatchToolbarIcon from '../../assets/icons/icono_despacho.svg'
// import dispatchToolbarIcon from './../../assets/icons/icono_pedidos_white.svg'
import { CartModel } from '../../models/CartModel'
import './ProviderSelection.scss'
import Category from '../../models/Category'
import { sortBy } from 'lodash'

import { moreInfoProvider } from '../firebase/firebaseTags'
import { getLengthOfCart } from '../../utils/cart'
import { navigateToCart } from '../../utils/navigation'

interface Props extends RouteComponentProps {
  providerModel: ProviderModel
  cartModel: CartModel
  categories: Category
  history?: any
  location?: any
}

interface State {
  alphabeticalOrder: any
  orderByProviderBrand: any
  brand: any
}

class ProviderSelection extends React.PureComponent<Props, State> {
  state: State = {
    alphabeticalOrder: true,
    orderByProviderBrand: true,
    brand: '',
  }

  async componentDidMount() {
    const { providerModel } = this.props
    await providerModel.loadProviders()
  }

  private goToCart = () => {
    navigateToCart(this.props.history, this.props.location.state || this.props.history.location.state);
  }

  private goToOrders = () => {
    this.props?.history?.push('/orders')
  }

  alphabeticalOrder = (value: any) => {
    switch (value) {
      case 'orderAZ':
        this.setState({
          alphabeticalOrder: true,
        })
        break
      case 'orderZA':
        this.setState({
          alphabeticalOrder: false,
        })
        break
    }
  }

  sortingByAlphabet = () => {
    const { providerModel } = this.props
    const { alphabeticalOrder } = this.state
    const providers = providerModel.state.providers
    return Object.values(providers).sort(function(a, b) {
      var textA = a.name
      var textB = b.name
      return alphabeticalOrder
        ? textA < textB
          ? -1
          : textA > textB
          ? 1
          : 0
        : textB < textA
        ? -1
        : textB > textA
        ? 1
        : 0
    })
  }

  async providerBrand(value: any) {
    const { categories } = this.props

    switch (value) {
      case 'provider':
        this.setState({
          orderByProviderBrand: true,
        })
        break
      case 'brand':
        this.setState({
          orderByProviderBrand: false,
          brand: sortBy(
            (await categories.brand()).length > 0
              ? (await categories.brand())
                  .find((r: any) => r.detail)
                  .detail.filter((r: any) => r.brands && r.brands.length > 0)
              : '',
            ['name'],
          ),
        })
        break
    }
  }

  render() {
    const { history } = this.props
    const { orderByProviderBrand, brand } = this.state
  
    return (
      <IonPage className="provider-selection-page" >
        <IonHeader >
          <ToolBar
            title="Proveedores"
            primaryButtons={[
              {
                icon: cartToolbarIcon,
                key: 'cart',
                onClick: this.goToCart,
                badge: getLengthOfCart(),
              },
              {
                icon: dispatchToolbarIcon,
                key: 'orders',
                onClick: this.goToOrders,
              },
            ]}
            secondaryButtons={[{ type: 'back', onClick: history.goBack }]}
          />
          <div>
            <div className="divProvider">
              <IonButton onClick={() => this.providerBrand('provider')} >Empresas</IonButton>
            </div>
            <div className="divBrand" onClick={() => this.providerBrand('brand')}>
              <IonButton >Marcas</IonButton>
            </div>
          </div>
        </IonHeader>
        <IonContent >
          <div>
            <div className="provider-list">
              {orderByProviderBrand &&
                this.sortingByAlphabet().map(r => {
                  return (
                    <div onClick={() => moreInfoProvider(r.name)} key={r.id}>
                      <div
                        key={r.id}
                        className="provider-card"
                        onClick={() => this.props?.history?.push(`/home-providers/${r.id}`, { singleProviderId: r.id })}
                      >
                        {r.disponible === true && (
                          <div
                            className="logo"
                            style={{ backgroundImage: `url(${process.env.REACT_APP_BFF_IMAGE}providers/${r.logo})` }}
                          ></div>
                        )}
                        <div className="name-provider">{r.name}</div>
                      </div>
                    </div>
                  )
                })}
            </div>
            {!orderByProviderBrand &&
              brand.length > 0 &&
              brand.map((categories: any) => {
                return (
                  <div key={categories._id}>
                    <div style={{ padding: '12px 11px', backgroundColor: '#d2d1d1a3' }}>
                      <span>{categories.name}</span>
                    </div>
                    {categories.brands.map((brands: any) => {
                      return (
                        <div
                          className="provider-brand-card"
                          onClick={() =>
                            history.push(`/home-brand/${brands.id}`, {
                              singleBrandId: brands.id,
                              nameBrand: brands.name,
                            })
                          }
                        >
                          <div
                            className="logo"
                            style={{ backgroundImage: `url(${process.env.REACT_APP_BFF_IMAGE}brands/${brands.logo})` }}
                          ></div>
                          <div style={{ textAlign: 'center' }}>{brands.name}</div>
                        </div>
                      )
                    })}
                  </div>
                )
              })}
          </div>
        </IonContent>
      </IonPage>
    )
  }
}

export default withRouter(ProviderSelection)
