import React, { Fragment, useContext } from 'react'
import { IonSelect, IonSelectOption } from '@ionic/react'
import { ProviderCartData } from '../../../../interfaces/carts'
import { handlePaymentMethodName } from '../../../../utils/functions/handlePaymentMethodName'
import './index.scss'
import { updateCartPaymentMethod } from '../../../../services/bff_mobile/v2/carts/updateCartPaymentMethod'
import useGetPaymentMethodsByProviderId from '../../../../hooks/carts/useGetPaymentMethodsByProviderId'
import AppContext from '../../../../context/AppContext'

interface Props {
  providerPaymentMethod: string
  provider: ProviderCartData
}

function PaymentMethodSection({ providerPaymentMethod, provider }: Props) {
  const { cartData, handlerCartData } = useContext(AppContext)
  const { paymentMethods } = useGetPaymentMethodsByProviderId(provider._id)

  const handlerPaymentMethod = async (paymentForm: string) => {
    await updateCartPaymentMethod(cartData._id, provider._id, paymentForm)

    const paymentMethods: string[] = cartData.data.map((element) => element.paymentMethod)

    if (paymentMethods.includes(paymentForm)) {
      const updatedCartData = cartData.data.map((data) => {
        if (data.paymentMethod !== providerPaymentMethod && data.paymentMethod !== paymentForm) return data

        if (data.paymentMethod !== providerPaymentMethod && paymentForm) {
          return {
            ...data,
            providers: [...data.providers, provider],
          }
        }

        return {
          ...data,
          providers: data.providers.filter((filterProvider) => filterProvider._id !== provider._id),
        }
      })

      handlerCartData({
        ...cartData,
        data: updatedCartData,
      })
    } else {
      const updatedCartData = cartData.data.map((data) => {
        if (data.paymentMethod !== providerPaymentMethod) return data

        return {
          ...data,
          providers: data.providers.filter((filterProvider) => filterProvider._id !== provider._id),
        }
      })

      handlerCartData({
        ...cartData,
        data: [
          ...updatedCartData,
          {
            paymentMethod: paymentForm,
            providers: [provider],
          },
        ],
      })
    }
  }

  return (
    <Fragment>
      {paymentMethods.length > 1 && (
        <div className="payment-method-content">
          <span>{handlePaymentMethodName(providerPaymentMethod)}</span>
          <IonSelect
            value={providerPaymentMethod}
            onIonChange={(e) => handlerPaymentMethod(e.detail.value)}
            interface="action-sheet"
            cancelText="Cancelar"           >
            {paymentMethods.map((paymentMethod, index) => (
              <IonSelectOption key={index} value={paymentMethod} >
                {handlePaymentMethodName(paymentMethod)}
              </IonSelectOption>
            ))}
          </IonSelect>
        </div>
      )}
    </Fragment>
  )
}

export default PaymentMethodSection
