import React from 'react'
import { IonModal, IonIcon } from '@ionic/react'
import { close } from 'ionicons/icons'
import User from '../../models/User'
import welcome from './../../assets/login_welcome-back.png'

import './ModalRecoveryPassword.scss'

interface Props {
  user: User
  history: any
  recoveryPassword: boolean
  closeModalRecoveryPassword: any
}

interface State {
  recoveryPassword: boolean
}

export default class ModalRecoveryPassword extends React.PureComponent<Props, State>{

closeModalRecoveryPassword = () => {
  this.props.closeModalRecoveryPassword()
}

render(){
  const { recoveryPassword } = this.props
   return (
    <IonModal className="recovery-password-modal" backdropDismiss={false} isOpen={recoveryPassword}>
      <div className="content">
      <div className="header" onClick={this.closeModalRecoveryPassword}>
          <IonIcon icon={close}   />
        </div>
        <div className="body-recovery-password">
          <div className="star" style={{ backgroundImage: `url(${welcome})` }}></div>
        </div>
        <div className="container-info-productModal">
          <div className="container-title-modal">¡BIENVENIDO DE NUEVO!</div>
          <div className="info-modal">
            Tu contraseña ha sido actualizada.
          </div>
        </div>
      </div>
    </IonModal>
   );
 }
}
 