import React from 'react';
import { IonModal, IonContent, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton } from '@ionic/react';
import './GuruBottomSheetModal.scss';

interface GuruBottomSheetModalProps {
  isOpen: boolean;
  onClose: () => void;
  customHeight?: number;
  title?: string;
  children: React.ReactNode;
}

const GuruBottomSheetModal: React.FC<GuruBottomSheetModalProps> = ({
  isOpen,
  onClose,
  customHeight = 90,
  title = '',
  children
}) => {
  return (
    <IonModal 
      isOpen={isOpen} 
      onDidDismiss={onClose}
      className={`custom-bottom-sheet custom-bottom-sheet-${customHeight}`}
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>{title}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onClose}>Cerrar</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="bottom-sheet-content">
          {children}
        </div>
      </IonContent>
    </IonModal>
  );
};

export default GuruBottomSheetModal;