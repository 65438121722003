import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';
import {
  IonPage
} from '@ionic/react'

import { 
  IoCall as PhoneIcon,
  IoLogoWhatsapp as WhatsappIcon
} from "react-icons/io5";

// Models
import UserModel from './../../models/User'
import SettingsModel from './../../models/Settings'
import contactFormModel from './../../models/ContactForm'

// Assets
import arrowBack from './../../assets/icons/arrow-back-blue.svg'

// Components
import DniField from '../../components/dni-field/DniField';
import AdaptativeLoginForm from '../adaptative-login-form/AdaptativeLoginForm'
import GuruHelper from '../../components/guru-helper/GuruHelper'
import HeaderWithoutToolbar from '../../components/header-without-toolbar/HeaderWithoutToolbar'
// TODO: resolver import GuruIcon from 'guru-react-icons'


// Utils
import { getLocalStorageObject } from '../../utils/localstorageData';
import { countryCl, countryMx, countryPe, getCountryCode, phoneAsUsernameCountries } from '../../utils/countriesTexts'
import { contactForms } from '../firebase/firebaseTags'

// Styles
import './Contact.scss'

type IPathParams = {}

type Props = RouteComponentProps<IPathParams> & {
  user: UserModel
  settings: SettingsModel
  contactFormModel: contactFormModel
}

interface Form { }

interface State<T> {
  contactInfo: any
  appInfo: any
  countryName: any
  rut: string
  approvedRut: boolean
}

class Contact extends React.PureComponent<Props, State<Form>> {
  state: State<Form> = {
    contactInfo: {},
    appInfo: {},
    countryName: '',
    rut: '',
    approvedRut: false
  }

  componentDidMount = async () => {
    const countryCode = getCountryCode()

    const contactInfo = await this.props.contactFormModel.getContactDetails(countryCode)
    if (contactInfo) {
      this.setState({ contactInfo: contactInfo.sections[1] })
    }

    const info = await Device.getInfo()
    const appInfoCapacitor = await App.getInfo().catch(err => null)
    const countryName = this.getCountryName()
    const approvedRut = phoneAsUsernameCountries.includes(countryCode)

    this.setState({
      appInfo: { ...info, appVersion: appInfoCapacitor?.version || "" },
      countryName,
      approvedRut,
    })
  }

  private clickWhatsapp = () => {
    const eventName = 'help_contact'
    const contactType = 'contact_whatsapp'
    const contactPlatform = 'Whatsapp'
    contactForms(eventName, contactType, contactPlatform);
  }

  private clickCall = () => {
    const eventName = 'help_contact'
    const contactType = 'contact_call'
    const contactPlatform = 'Llamada'
    contactForms(eventName, contactType, contactPlatform);
  }

  cleanPhoneNumber = (phoneNumber: string) => {
    return phoneNumber ? phoneNumber.replace('+', '').replaceAll('', '') : ''
  }

  getCountryName = () => getLocalStorageObject('countryName')?.countryName || ''

  validateUser = (user:any, approved:boolean) =>{
    this.setState({
      rut: user.username,
      approvedRut: approved,
    })
  }

  renderUsernameConditional = (countryCode: string) => {
    if (!countryCode || phoneAsUsernameCountries.includes(countryCode)) return ""

    const messages: any = {
      [countryCl]: 'Esta información es opcional',
      [countryPe]: 'Esta información es opcional',
      [countryMx]: ''
    }

    const informativeText: string = messages[countryCode] || ''

    return <DniField countryCode={countryCode} properties={{ validateUser: this.validateUser, informativeText }} />
  }

  render() {
    const { contactInfo, appInfo, countryName, rut, approvedRut } = this.state
    const { history } = this.props
    const cleanedPN = this.cleanPhoneNumber(contactInfo.cellphone)
    const phone = contactInfo.phone ? contactInfo.phone.replaceAll(' ', '') : ''
    const countryCode = getCountryCode() || ""
    const isDniIncluded = !phoneAsUsernameCountries.includes(countryCode)
    const dniIsValid = approvedRut || undefined || rut === ''

    return (
      <IonPage className="contact-page" >
        <div className="scroll">
          <HeaderWithoutToolbar 
            icon={arrowBack}
            text="Solicita ayuda"
            onClick={history.goBack}
          />
          <GuruHelper 
            label="Rellena el formulario" 
            text="Deja tus datos de contacto para comunicarnos contigo lo más pronto posible."
          />

          {this.renderUsernameConditional(countryCode)}

          <AdaptativeLoginForm
            message={`solicitud de ingreso ${countryName}`}
            subject={`[Solicitud - ingreso ${countryName}] - Usuario solicitando ingreso`}
            automaticMessage={false}
            userRut={approvedRut && rut ? rut : undefined}
            validDni={dniIsValid}
            withDni={isDniIncluded}
            notRecoveryAccess={true}
            {...this.props}
          />
          <div className="separator" />
          <div className="center-text">Puedes contactarnos directamente a través de las siguientes opciones</div>
          <div className="container-contact-forms">
            <div className="item-contact-whatsapp">
                <PhoneIcon color='blue' />
              <a onClick={this.clickCall} href={`tel:${phone}`}>{contactInfo.phone}</a>
            </div>
            <div className="item-contact">
              <WhatsappIcon color='green' />
              <a onClick={this.clickWhatsapp} href={`https://wa.me/${cleanedPN}`}>{ contactInfo.cellphone}</a>
            </div>
          </div>
          <div className={`app-version ${appInfo.platform}`}>
            <span>©Almacén Gurú - {countryName}</span>
            {process.env.REACT_APP_NODE_ENV !== 'production' && 
              <span className="capitalize">
                - {process.env.REACT_APP_NODE_ENV} 
              </span>&&
              <span> - {appInfo.platform} </span>
            }
            {(appInfo.platform === 'ios' || appInfo.platform === 'android') && 
              <span> versión {appInfo.appVersion}</span>
            }
          </div>
        </div>
      </IonPage>
    )
  }
}

export default withRouter(Contact)
