import React, { useEffect, useState } from 'react'
import { IonBadge, IonContent, IonPage, IonSpinner } from '@ionic/react'
import { RouteComponentProps, withRouter, useLocation } from 'react-router-dom'
import { StaticContext } from 'react-router-dom'
import track from 'react-tracking'
import { categoryTreeOfVendor } from '../../clients/category'
import { getProviderById } from '../../clients/provider'

//COMPONENTS
import ToolBar from '../../components/tool-bar/ToolBar'
import GlobalComponents from '../../components'
import WordsSlider from '../../components/words-slider/WordsSlider'

//MODELS
import { ProviderModel } from '../../models/ProviderModel'
import { CartModel } from '../../models/CartModel'
import { ProductModel } from '../../models/ProductModel'
import { PromotionModel } from '../../models/PromotionModel'
import Category from '../../models/Category'
import Office from '../../models/Office'
import Settings from '../../models/Settings'
import User from '../../models/User'

//UTILS
import { getCartLength, getLengthOfCart } from '../../utils/cart'
import { ifExistAndMayorToZero } from '../../utils/arrays'

//ICONS
import cartToolbarIcon from '../../assets/icons/nav_cart_blue.svg'
import { onPageScroll } from '../../utils/scroll-spy'

import LoadingComponent from '../../components/loading-component/LoadingComponent'
import { EventEmitter } from '../../utils/events'
import './VendorBrand.scss'

type IPathParams = {
  vendorId: string;
  brandId: string;
}

interface RouteState {
  vendorName: string
  categoryTree: any
  vendorId: string
  brandId: string
  from: string
}

type IProps = RouteComponentProps<IPathParams, StaticContext, RouteState> & {
  user: User
  settings: Settings
  offices: Office
  categories: Category
  promotion: PromotionModel
  productModel: ProductModel
  cartModel: CartModel
  providerModel: ProviderModel
}

const VendorBrand: React.FC<IProps> = props => {
  const { history } = props
  const location = useLocation()
  const [vendorId, setVendorId] = useState<string | undefined>(undefined)
  const [brandId, setBrandId] = useState<string | undefined>(undefined)
  const [tree, setTree] = useState<any>(null)
  const [currentVendor, setCurrentVendor] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [cartLengthData, setCartLengthData] = useState<any>({ cartLength: 0, prevLength: 0})
  const [animateAtc, setAnimateAtc] = useState<string>('')
  const [error, setError] = useState<any>(null)

  const getVendorId = (locationPathName: string) => {
    if(!!locationPathName && locationPathName.split('/').length === 5 ) {
      return locationPathName.split('/')[2]
    }
  }

  const getBrandId = (locationPathName: string) => {
    if(!!locationPathName && locationPathName.split('/').length === 5 ) {
      return locationPathName.split('/')[4]
    }
  }

  useEffect(() => {
    setIsLoading(true)
    if(location) {
      const vendorId = getVendorId(location.pathname)
      setVendorId(vendorId)

      const brandId = getBrandId(location.pathname)
      setBrandId(brandId)
    } else {
      setIsLoading(false) // "Error case, location should be always definedq"
    }
  }, [location])


  useEffect(() => {
    if(brandId && vendorId) {
      handleFetchCategoryTree()
    }
  }, [brandId, vendorId])

  useEffect(() => {
    const cartLocal = localStorage.getItem('@cart')
    const cart = cartLocal ? JSON.parse(cartLocal) : null
    if (cart) {
      const updatedCartLength: any = cart.products?.length || null
      setCartLengthData({ cartLength: updatedCartLength, prevLength: updatedCartLength })
    }

    getCart()

    EventEmitter.subscribe('UpdateCartCount', ({ cartUpdateAction }: any) => {
      setCartLengthData((prevState: any) => {
        let newCartLength = prevState.cartLength
        if(cartUpdateAction === 'add') newCartLength = newCartLength + 1
        if(cartUpdateAction === 'subtract') newCartLength = newCartLength - 1
        
        const didAdd = newCartLength > prevState.cartLength
        if (didAdd) {
          setAnimateAtc('badge-anim-in')
          setTimeout(() => { setAnimateAtc('badge-anim-out') }, 1);
        }
        return { 
          cartLength: newCartLength,
          prevLength: prevState.cartLength 
        }
      })
    })

    return () => {
      EventEmitter.unsubscribe('UpdateCartCount')
    }
  }, [])

  const handleFetchCategoryTree = async () => {
    if (!vendorId || !brandId) {
      setError('Invalid vendor or brand ID')
      setIsLoading(false)
      return
    }

    try {
      const vendor = await getProviderById(vendorId)
      const categoryTree = await categoryTreeOfVendor(brandId, vendorId)
      setTree(categoryTree)
      setCurrentVendor(vendor?.name || '')
      setIsLoading(false)
    } catch (err) {
      console.error('Error fetching category tree:', err)
      setError('Failed to load category data. Please try again later.')
      setIsLoading(false)
    }
  }

  const getCart = async () => {
    const cartLength: any = await getCartLength()
    setCartLengthData({ cartLength, prevLength: cartLength })
  }

  const isLoadingProducts = () => setIsLoading(false)
    
  const getProductsWithTree = () => {
    return (
      tree.map((category:any, index: number) => (
        <GlobalComponents.ProductSliderAsync
          key={`product-slider-${index}`}
          filterBrand={false}
          singleProviderId={vendorId}
          category={category}
          placementName={`vendor-brand-category-product-slider-${category.name}`}
          hideMore={true}
          isLoading={isLoadingProducts}
          brandId={brandId}
          from='vendor-brand'
          {...props}
          categoryPosition={index + 1}
          nameProvider={''}
        />
      ))
    )
  }

  const goToCart = () => {
    const sourceObj: any = { eventSource: 'brand'}
    history.push('/cart', sourceObj)
  }

  const handleGoBack = () => {
    history.goBack()
  }

  if (isLoading) {
    return (
      <IonPage className="vendor-brand-page">
        <IonContent className="ion-padding">
          <IonSpinner />
          <p>Loading category data...</p>
        </IonContent>
      </IonPage>
    )
  }

  if (error) {
    return (
      <IonPage className="vendor-brand-page">
        <IonContent className="ion-padding">
          <p>{error}</p>
        </IonContent>
      </IonPage>
    )
  }

  return (
    <IonPage className="vendor-brand-page" >
      <div className='relative-header-wrapper'>
        {cartLengthData.cartLength > 0 && <IonBadge className={`cart-badge-btn ${animateAtc}`} >{cartLengthData.cartLength}</IonBadge> }
        <ToolBar
          title={currentVendor}
          secondaryButtons={[{ type: 'back', onClick: handleGoBack}]}
          secondaryHeader={true}
          boxShadow={true}
          primaryButtons={[
            {
              icon: cartToolbarIcon,
              key: 'cart',
              onClick: goToCart,
              badge: cartLengthData.cartLength,
              animateAtc,
            },
          ]}
        />
      </div>
      {ifExistAndMayorToZero(tree) &&  <WordsSlider words={tree}/>}
      <IonContent>
        {ifExistAndMayorToZero(tree) && getProductsWithTree()}
        <div className="container-height"></div>
      </IonContent>
    </IonPage>
  )
}

export default track({ page: 'VendorBrand' })(withRouter(VendorBrand))