import React from 'react'
import { IonPage, IonContent, IonBadge } from '@ionic/react'
import { StaticContext } from 'react-router-dom'
import { withRouter, RouteComponentProps } from 'react-router-dom'

// Models
import User from '../../models/User'
import Category from '../../models/Category'
import Settings from '../../models/Settings'
import { ProductModel } from '../../models/ProductModel'
import { CartModel } from '../../models/CartModel'

// Components
import GlobalComponents from '../../components'
import ToolBar  from '../../components/tool-bar/ToolBar'

// Utils
import { getCartLength, getLengthOfCart } from '../../utils/cart'
import { EventEmitter } from '../../utils/events'

// Icons
import cartToolbarIcon from '../../assets/icons/nav_cart_blue.svg'

// Styles
import './SearchProductsProvider.scss'
import { navigateToCart } from '../../utils/navigation'

type IPathParams = {}

interface RouteState {
  title: string
  providerId:any
  textSearch:any
  productsFound:any
}

type IProps = RouteComponentProps<IPathParams, StaticContext, RouteState> & {
  user: User
  settings: Settings
  categories: Category
  productModel: ProductModel
  cartModel: CartModel
  hidePrice?:boolean
}

interface State {
  cartLengthData: any
  animateAtc: any
}

class SearchProductsProvider extends React.PureComponent<IProps, State> {
  state: State = {
    cartLengthData: { cartLength: 0, prevLength: 0 },
    animateAtc: '',
  }

  async componentDidMount() {
    const cartLocal = localStorage.getItem('@cart')
    const cart = cartLocal ? JSON.parse(cartLocal) : null
    if (cart) {
      const updatedCartLength: any = cart.products?.length || null
      this.setState({ cartLengthData: { cartLength: updatedCartLength, prevLength: updatedCartLength } })
    }
    
    this.getCart()

    EventEmitter.subscribe('UpdateCartCount', ({ cartUpdateAction }: any) => {
      this.setState((prevState: any) => {
        let newCartLength = prevState.cartLengthData.cartLength
        if(cartUpdateAction === 'add') newCartLength = newCartLength + 1
        if(cartUpdateAction === 'subtract') newCartLength = newCartLength - 1
        
        const didAdd = newCartLength > prevState.cartLengthData.cartLength
        if (didAdd) {
          this.setState({ animateAtc: 'badge-anim-in' })
          setTimeout(() => { this.setState({ animateAtc: 'badge-anim-out'}) }, 1);
        }

        return {
          cartLengthData: {
            cartLength: newCartLength,
            prevLength: prevState.cartLengthData.cartLength,
          }
        }
      })
    })
  }

  componentWillUnmount() {
    EventEmitter.unsubscribe('UpdateCartCount')
  }

  getCart = async () => {
    const cartLength: any = await getCartLength()
    this.setState({ cartLengthData: { cartLength, prevLength: cartLength } })
  }

  goToCart = () => {
    navigateToCart(this.props.history, this.props.location.state || this.props.history.location.state);
  }

  goToDeliveries = () => {
    this.props?.history?.push('/deliveries')
  }

  goToOrders = () => {
    this.props?.history?.push('/orders')
  }

  renderProducts = (products: any) => {
    return products && products.length
      ? products.map((product: any) => (
        <GlobalComponents.ProductCard
          key={product._id}
          settings={this.props.settings}
          product={product}
          cartModel={this.props.cartModel}
          onGoToCart={this.goToCart}
          history={this.props.history}
        />
      ))
      : null
  }

  render() {
    const { history, location } = this.props
    const { cartLengthData, animateAtc } = this.state
    const titleToolbar = location.state ? location.state.textSearch : ''
    const productsFound = location.state ? location.state.productsFound : ''
  
    
    return (
      <IonPage className="plp-page" >
          <div className='relative-header-wrapper'>
            {cartLengthData.cartLength > 0 && <IonBadge className={`cart-badge-btn ${animateAtc}`} >{cartLengthData.cartLength}</IonBadge> }
            <ToolBar
              title={titleToolbar}
              secondaryHeader={true}
              primaryButtons={[
                {
                  icon: cartToolbarIcon,
                  key: 'cart',
                  onClick: this.goToCart,
                  badge: cartLengthData.cartLength
                },
              ]}
              secondaryButtons={[{ type: 'back', onClick: history.goBack }]}
            />
          </div>
        <IonContent >
          <div>
            {productsFound &&  productsFound.products  && productsFound.products.length > 0 &&
              productsFound.products.map((product: any) => {
                return (
                <div className="container-card-search">
                  <GlobalComponents.ProductCard
                    key={product.sku}
                    settings={this.props.settings}
                    product={product}
                    cartModel={this.props.cartModel}
                    onGoToCart={this.goToCart}
                    history={history}
                  />
                </div>
                )
              })}
          </div>
        </IonContent>
      </IonPage>
    )
  }
}

export default withRouter(SearchProductsProvider)
