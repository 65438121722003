import React, { Fragment, useContext } from 'react'
import { IonContent, IonPage } from '@ionic/react'
import LoadingComponent from '../../components/loading-component/LoadingComponent'
import CartToolbar from './components/cart_toolbar'
import PaymentMethodCard from './components/payment_method_card'
import './index.scss'
import useGetCartData from '../../hooks/carts/useGetCartData'
import AppContext from '../../context/AppContext'
import useGetCouponsActivated from '../../hooks/carts/useGetCouponsActivated'
import { formatCurrency } from '../../utils/intl'
import useCalculateCartTotalPrice from '../../hooks/carts/useCalculateCartTotalPrice'
import GuruWhiteScreen from '../../components/guru-white-screen/GuruWhiteScreen'

function Cart() {
  // Subiendo dev
  const { cartData } = useContext(AppContext)
  const { loading } = useGetCartData()
  const { totalPrice } = useCalculateCartTotalPrice()

  useGetCouponsActivated()

  return (
    <IonPage >
      <IonContent >
        <CartToolbar />

        <div className="cart-content">
          {cartData && (
            <div className="cart-content__header">
              <p>Cantidad de carros: {cartData.data.length}</p>
              <p>Total: {formatCurrency(totalPrice)}</p>
            </div>
          )}

          <hr className="cart-content__separator" />

          {/* Sección por tipo de pago */}
          {loading ? (
            <LoadingComponent height="60vh" />
          ) : (
            <Fragment>
              {cartData.data.length > 0 ? (
                <div className="cart-content__payment-methods-content">
                  {cartData.data.map((data, index) => (
                    <Fragment key={index}>
                      {data.providers.length > 0 && <PaymentMethodCard paymentMethodData={data} />}
                    </Fragment>
                  ))}
                </div>
              ) : (
                <div className="cart-content__carro-vacio">
                  <GuruWhiteScreen text="Todavía no has agregado nada al carrito" />
                </div>
              )}
            </Fragment>
          )}
        </div>
      </IonContent>
    </IonPage>
  )
}

export default Cart
