import React from 'react'
import { IonIcon, IonModal, IonButton,IonInput, IonItem,IonAvatar, IonLabel, 
	IonTextarea,IonSpinner,  IonAlert, IonContent} from '@ionic/react'
import './Modals.scss'
import { close } from 'ionicons/icons'
import track from 'react-tracking'

import icn_user_name from './../../assets/icons/icn_user-name.svg'
import icn_phone  from './../../assets/icons/icn_phone.svg'
import icn_email  from './../../assets/icons/icn_email.svg'
import { produce } from 'immer'
import { validatePhone, validateEmail } from '../../utils/basetypes'
import { checkmarkCircle } from 'ionicons/icons'
import { sendForm } from '../../clients/validateSms'
import { sendMetric } from '../firebase/firebaseTags'

type IPathParams = {}

type RouteState = {
}

interface Form {
	username: string
	email: string
	phone: string
	message:string
}

interface IProps {
	title: any
	img:boolean
	pathImag?:any 
	productForm?:any
	openModal?:any
	onDismiss: (dissmisAgent?: boolean) => void
}

interface State<T> {
  data: { [name in keyof T]: T[name] }
	validation: { [name in keyof T]: boolean }
	isFormValid: boolean
	spinner: boolean
	showAlert: boolean
	user:any
	direction:any
}

class Modals extends React.PureComponent<IProps, State<Form>> {
  state: State<Form>= {
		data: {
      username: '',
      email: '',
			phone: '',
			message:''
		},
    validation: {
      username: false,
      email: false,
      phone: false,
			message:false
		},
		isFormValid:false,
		spinner:false,
		showAlert:false,
		user:"",
		direction:""
	}
	
	componentDidMount = async () =>{
		const user = localStorage.getItem("@user")
		const direction = localStorage.getItem("currentOffice")
		if(user && direction){
			this.setState({
				user:JSON.parse(user),
				direction:JSON.parse(direction),
			})
		}
	}

	private onDismissModal = async (sendToOrders?: boolean) => {
		const { onDismiss } = this.props
    await onDismiss(sendToOrders)
	}

	onChangeInput = (type: keyof Form, value: Form[keyof Form]) => {

		this.setState(
      produce(this.state, draft => {
        switch (type) {
          case 'username':
						draft.data[type] = value
						draft.validation[type] = value.length > 0
            break
          case 'phone':
            draft.data[type] = value
            draft.validation[type] = validatePhone(value)
            break
          case 'email':
            draft.data[type] = value
            draft.validation[type] = validateEmail(value)
            break
          case 'message':
            draft.data[type] = value
            draft.validation[type] = value.length > 0
            break
				}
				
        let allAreTrue = true
          ; (Object.keys(draft.validation) as Array<keyof typeof draft.validation>).forEach(key => {
            const field = draft.validation[key]
            if (field === false) {
              allAreTrue = false
            }
          })
        draft.isFormValid = allAreTrue
      }),
    )
	}
	
	applyFor = async () =>{
    const {
      data: { username, phone, email, message}, user, direction
		} = this.state
		const { productForm } = this.props
		productForm && sendMetric("request")
		// requestSuccessfull()
    this.setState({
      spinner:true
		})
	
	
	const data = {
		"product": productForm,
		"rutUser": user && user.username,
		"direction":direction && direction.location
	}

	 const emailInternal= process.env.REACT_APP_CONTACT_FORM_EMAIL
	 const subject= productForm  ? "[Segmentación-Comuna] - Producto no disponible" : "[Guru -Pro] - Agendar Contacto Agente"
		const result: any = productForm  ?  
		await sendForm( username, phone, email, emailInternal, message, subject, data) :
		await sendForm( username, phone, email, emailInternal, message, subject)
	

    if(result && result.status === 200){
			this.setState({
        spinner:false,
        showAlert:true,
        data: {
					username:'',
					phone:'',
					email:'',
					message:''
        },
        validation: {
					username: false,
					email: false,
					phone: false,
					message:false
        },
      })
		}
	}


  render() {
		const { title, img, pathImag, productForm, openModal } = this.props
		const {
			data: {  phone, email, username, message },
			spinner, showAlert
		} = this.state
		
    return (
			<IonContent >
			<IonModal className="modal-no-product" backdropDismiss={false}  isOpen={openModal}>
				<div className="content">
					<div className="header" onClick={() => this.onDismissModal(false)}>
						<IonIcon icon={close} />
					</div>
						<div className="body">
							<div className='body-img' style={{ backgroundImage: img  ? `url(${pathImag})` : ""}} ></div>
						</div>
						<div className='container-info-productModal'>
						<div className='container-title-modal'>{title}</div>
          	</div> 
						<div className='container-input-modals' >
						<IonItem mode="md" className={this.state.validation.username ? 'item-valid' : ''} > 
							<IonAvatar className="avatar" slot="start" >
									<img className="img-avatar" src={icn_user_name } alt="username"/>
							</IonAvatar>
							<IonInput
								placeholder={'Nombre'}
								value={username}
								onIonChange={e => this.onChangeInput('username', e.detail.value || '')}
								type='text' 							>
							</IonInput>
							<IonIcon size="small" icon={checkmarkCircle} slot="end"  />
						</IonItem>
						<IonItem slot="start" mode="md" className={this.state.validation.phone ? 'item-valid' : ''}  >
							<IonAvatar className="avatar"  >
								<img className="img-avatar" src={icn_phone} alt="telefono"/>
							</IonAvatar>
							<IonLabel >+56 9</IonLabel>
							<IonInput
								value={phone}
								onIonChange={e => this.onChangeInput('phone', e.detail.value || '')}
								type="tel"
								required={true}
								maxlength={8} 							>
							</IonInput>
							<IonIcon size="small" icon={checkmarkCircle} slot="end"  />
						</IonItem>
						<IonItem slot="start" mode="md" className={this.state.validation.email ? 'item-valid' : ''}  > 
							<IonAvatar className="avatar" slot="start" >
								<img className="img-avatar" src={icn_email} alt="email"/>
							</IonAvatar>
							<IonInput
								value={email}
								onIonChange={e => this.onChangeInput('email', e.detail.value || '')}
								type="email"
								placeholder={"Email"} 							>
							</IonInput>
							<IonIcon size="small" icon={checkmarkCircle} slot="end"  />
						</IonItem>
						<div className="container-textarea"> 
							<div className='message'>Mensaje</div>
							<IonTextarea 
								value={message}
								onIonChange={e => this.onChangeInput('message', e.detail.value || '')}
								placeholder={"Escribe algo aquí..."} 							>
							</IonTextarea>
						</div>
							<div className="container-product-info">
								{productForm && 	
								<div >
									<div className="tittle">Producto</div>
									<div className="picture-info">
										<div className="picture" style={{ backgroundImage: `url(${productForm.filename})` }}></div>
										<div className="brand">
											<div>{`${productForm.brand}`}</div>
											<div>{productForm && productForm.name}</div>
										</div>
									</div>
									</div>}
						</div>
          </div>
					<div className='footer'>
						<IonButton 
							expand="block"
							disabled={!this.state.isFormValid}
							onClick={this.applyFor} 							>
							ENVIAR
              {spinner &&
              <IonSpinner 
								name="crescent"               />}
							</IonButton>
					</div>
				</div>
    	</IonModal>
				<IonAlert
						isOpen={showAlert}
						header={'Solicitud enviada'}
						message={'Su solicitud ya fue enviada, nos pondremos en contacto contigo a la brevedad'}
						buttons={[
							{
								text: 'Cerrar',
								handler: () => this.onDismissModal(true),
							}
						]}
					/>
			</IonContent>
    )
  }
}

export default track({})(Modals)
