import { useContext, useEffect, useState } from 'react'
import AppContext from '../../context/AppContext'
import { getCartData } from '../../pages/cart2/functions'

function useGetCartData() {
  const { handlerCartData } = useContext(AppContext)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const action = async () => {
      setLoading(true)
      try {
        handlerCartData(await getCartData())
      } catch (error) {
        handlerCartData({
          _id: '',
          data: [],
          discounts: [],
        })
      }
      setLoading(false)
    }

    action()
  }, []) 

  return { loading }
}

export default useGetCartData
