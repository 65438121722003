import React from 'react'
import { IonIcon } from '@ionic/react'
import { GuruSlider, GuruSlide } from '../../../../components'
import NewProductCard from '../../../../components/new_product_card'
import { chevronForwardOutline } from 'ionicons/icons'
import './index.scss'
import { ProductSlidesTypeEnums } from '../../../../enums/products'
import Star from '../../../../assets/icons/act_star.svg'

const products = [
  {
    product: {
      productId: '5efb730a63f30a0022903ac0',
      providerName: 'EGC Foodservice ',
      productName: 'Aceite Vegetal 900 Cc',
      imageFullPath: 'https://static.almacenguru.com/static/5efb71a990d93500220c8886/9964801203_1652890308291.jpg',
      offerPrice: 0,
      package: 'unidad',
      price: 1800,
      promotionalDynamicDetail: null,
      promotionalDynamicType: 'directOfferPrice',
      quantityMax: 60,
      quantityMin: 1,
      stock: null,
      units: 1,
      uom: 'Unidades',
      outOfStock: true,
    },
    paymentMethod: '',
    providerId: '5efb71a990d93500220c8886',
  },
  {
    product: {
      productId: '5efb730a63f30a0022903ac0',
      providerName: 'EGC Foodservice ',
      productName: 'Aceite Vegetal 900 Cc',
      imageFullPath: 'https://static.almacenguru.com/static/5efb71a990d93500220c8886/9964801203_1652890308291.jpg',
      offerPrice: 1690,
      package: 'unidad',
      price: 1800,
      promotionalDynamicDetail: null,
      promotionalDynamicType: 'directOfferPrice',
      quantityMax: 60,
      quantityMin: 1,
      stock: null,
      units: 1,
      uom: 'Unidades',
      outOfStock: false,
    },
    paymentMethod: '',
    providerId: '5efb71a990d93500220c8886',
  },
  {
    product: {
      productId: '5efb730a63f30a0022903ac0',
      providerName: 'EGC Foodservice ',
      productName: 'Aceite Vegetal 900 Cc',
      imageFullPath: 'https://static.almacenguru.com/static/5efb71a990d93500220c8886/9964801203_1652890308291.jpg',
      offerPrice: 1690,
      package: 'unidad',
      price: 1800,
      promotionalDynamicDetail: null,
      promotionalDynamicType: 'directOfferPrice',
      quantityMax: 60,
      quantityMin: 1,
      stock: null,
      units: 1,
      uom: 'Unidades',
      outOfStock: false,
    },
    paymentMethod: '',
    providerId: '5efb71a990d93500220c8886',
  },
  {
    product: {
      productId: '5efb730a63f30a0022903ac0',
      providerName: 'EGC Foodservice ',
      productName: 'Aceite Vegetal 900 Cc',
      imageFullPath: 'https://static.almacenguru.com/static/5efb71a990d93500220c8886/9964801203_1652890308291.jpg',
      offerPrice: 1690,
      package: 'unidad',
      price: 1800,
      promotionalDynamicDetail: null,
      promotionalDynamicType: 'directOfferPrice',
      quantityMax: 60,
      quantityMin: 1,
      stock: null,
      units: 1,
      uom: 'Unidades',
      outOfStock: false,
    },
    paymentMethod: '',
    providerId: '5efb71a990d93500220c8886',
  },
  {
    product: {
      productId: '5efb730a63f30a0022903ac0',
      providerName: 'EGC Foodservice ',
      productName: 'Aceite Vegetal 900 Cc',
      imageFullPath: 'https://static.almacenguru.com/static/5efb71a990d93500220c8886/9964801203_1652890308291.jpg',
      offerPrice: 1690,
      package: 'unidad',
      price: 1800,
      promotionalDynamicDetail: null,
      promotionalDynamicType: 'directOfferPrice',
      quantityMax: 60,
      quantityMin: 1,
      stock: null,
      units: 1,
      uom: 'Unidades',
      outOfStock: true,
    },
    paymentMethod: '',
    providerId: '5efb71a990d93500220c8886',
  },
  {
    product: {
      productId: '5efb730a63f30a0022903ac0',
      providerName: 'EGC Foodservice ',
      productName: 'Aceite Vegetal 900 Cc',
      imageFullPath: 'https://static.almacenguru.com/static/5efb71a990d93500220c8886/9964801203_1652890308291.jpg',
      offerPrice: 1690,
      package: 'unidad',
      price: 1800,
      promotionalDynamicDetail: null,
      promotionalDynamicType: 'directOfferPrice',
      quantityMax: 60,
      quantityMin: 1,
      stock: null,
      units: 1,
      uom: 'Unidades',
      outOfStock: false,
    },
    paymentMethod: '',
    providerId: '5efb71a990d93500220c8886',
  },
]

interface Props {
  type: ProductSlidesTypeEnums
  title: string
  setShowModal: (show: boolean) => void
}

function ProductsSlidesFulfillment({ title, type, setShowModal }: Props) {
  return (
    <div className="products-slides-fulfillment">
      <div className="products-header">
        <h3
          className={`products-header--title ${
            type === ProductSlidesTypeEnums.OFERTAS && 'products-header--title-ofertas'
          }`}
        >
          {type === ProductSlidesTypeEnums.FAVORITOS && <img src={Star} alt="" />} {title}
        </h3>

        <span>
          Todos <IonIcon icon={chevronForwardOutline} ></IonIcon>
        </span>
      </div>

      <div className="products-slides">
        <GuruSlider 
          pager={false} 
          scrollbar={false} 
          autoHeight={true}
          slidesPerView='auto'
        >
          {products.map((product, index) => (
            <GuruSlide key={index} className="slide-product">
              <NewProductCard
                product={product.product}
                paymentMethod={product.paymentMethod}
                providerId={product.providerId}
                setShowModal={setShowModal}
              />
            </GuruSlide>
          ))}
        </GuruSlider>
      </div>
    </div>
  )
}

export default ProductsSlidesFulfillment
