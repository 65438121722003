import axios from 'axios'
import { Device } from '@capacitor/device'
import { App } from '@capacitor/app'

export const getAppCurrentVersion = async () => {
    try {
      const result = await axios.get(`${process.env.REACT_APP_BFF_PUBLIC}/v1/app/version`)
      return result.data
    } catch (error) {
      console.error('Error: =>>>>>>>', error)
      return null
    }
}

export const getAppInfo = async () => {
    try {
        let info = await Device.getInfo()
        const appInfoCapacitor = await App.getInfo().catch(() => null)

        info = Object.assign(info, appInfoCapacitor, { appBuild: appInfoCapacitor?.build })
        const result = await axios.post(`${process.env.REACT_APP_BFF_NODE}/v1/app/version`, {
            info,
        })
        return result.data
    } catch (error) {
      console.error('Error: =>>>>>>>', error)
      return null
    }
}