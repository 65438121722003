import React from 'react'
import { IonContent, IonPage, IonButton, IonHeader, IonCheckbox, IonLabel } from '@ionic/react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { StaticContext } from 'react-router-dom'
import parse from 'html-react-parser';
import {sendMetric} from '../firebase/firebaseTags'
import ButtonComponent from '../../components/basic-button/ButtonComponent';
import HeaderWithoutToolbar from '../../components/header-without-toolbar/HeaderWithoutToolbar';

import User from '../../models/User'
import termsConditionModel from '../../models/TermsConditionModel'

import arrowBack from './../../assets/icons/arrow-back-blue.svg'
import welcomeImg from '../../assets/guru-welcome.png'

import './TermsConditions.scss'

type IPathParams = {}

type RouteState = {
  fromInside?: boolean
  history?: any
}

type Props = RouteComponentProps<IPathParams, StaticContext, RouteState> & {
  user: User
  showTermsConditions: termsConditionModel
}

interface State {
  user: any
  termsConditions: any
  checkboxValue: boolean
  currentTerms: number
  renderFinish: boolean
  termsAccepted: boolean
}

class TermsConditions extends React.PureComponent<Props, State> {
  state: State = {
    user: null,
    termsConditions:{},
    checkboxValue: false,
    currentTerms: 0,
    renderFinish: false,
    termsAccepted: true,
  }

  componentDidMount = async () => {
    sendMetric("click_terms");
    const user: any = await this.props.user.getUser() || null
    const userInfoLocal: any = localStorage.getItem('@user') || null
    const userInfo = userInfoLocal ? JSON.parse(userInfoLocal) : null
    const termsConditions = await this.props.showTermsConditions.getTermsConditions();

    this.dataToTerms(termsConditions);
    this.setState({ user, termsAccepted: userInfo?.termsandconditions })
  }
  
  checkboxTextByTerm = (termIndex: any) => {
    const termsTexts = [
      { 
        index: 0, 
        text: 'He leído los Términos y Condiciones'
      },
      {
        index: 1, 
        text: 'He leído la Política de Privacidad'
      },
      {
        index: 2, 
        text: 'He leído el Uso de Datos'
      },
    ]

    return termsTexts.find(({index}: any) => index === termIndex)?.text || termsTexts[0].text
  }

  acceptOneStep = (index: number, termsLength: any) => {
    if(index === termsLength - 1) {
      this.setState({ 
        currentTerms: index + 1,
        renderFinish: true,
      })
      this.acceptConditions()
    }

    this.setState({
      currentTerms: index + 1,
      checkboxValue: false,
    })
  }

  acceptConditions = async () =>{
    const user: any = (await this.props.user.getUser()) || null
    const data = { termsandconditions: true }
    await this.props.user.updateUserValues(data)
    
    if(user){
      user.termsandconditions = true
      localStorage.setItem('@user', JSON.stringify(user))
    }     
  }

  dataToTerms = (value:any) => {
    this.setState({
      termsConditions: value
    })
  }

  checkboxChange = (checkboxValue: any) => this.setState({ checkboxValue: !checkboxValue})

  renderToolbar = (history: any) => {
    const locationState = history?.location?.state 
    return locationState?.origin !== undefined
  }

  renderFinishSection = (history: any) => (
    <div className="finish-container">
      <div className="finish-inner-wrapper">
        <div className="finish-content">
          <img src={welcomeImg} alt="" />
        </div>
        <div className='title-finish'>
          Gracias por leer y aceptar nuestras bases legales
        </div>
        <div className="finish-button-wrapper">
          <ButtonComponent 
            onClick={() => history.push('/home')}
            text={"Finalizar"}
            className={'btn-primary'}
          />
        </div>
      </div>
    </div>
  )
  
  shouldRenderSection = (index: number, currentTerms: number, history: any) => {
    const selectedTerms = history?.location?.state?.origin
    if(selectedTerms) return index === selectedTerms 
    return index === currentTerms 
  }
  
  termsParagraph = (paragraph: any) => {
    try {
      if(paragraph.includes("[link]")) {
        let parsedParagraph = paragraph.replace("[link]", '')
        parsedParagraph = parsedParagraph.split('|')
        const url = parsedParagraph[1]
        parsedParagraph = `${parsedParagraph[0]} <a href=${url}>${url}</a>`
        return parse(parsedParagraph)
      }
      return paragraph

    } catch (err) {
      console.error(err)
      return ""
    }
  }
  
  render() {
    const { history } = this.props
    const { termsConditions, checkboxValue, currentTerms, renderFinish, termsAccepted } = this.state

    const shouldRenderToolbar: any = this.renderToolbar(history)
    const termsLength = termsConditions?.content?.length

    return (
      <IonPage >
        {shouldRenderToolbar && 
          <div className="terms-header-wrapper">
            <HeaderWithoutToolbar 
              text="Términos y Condiciones"
              icon={arrowBack}
              onClick={history.goBack}
            />
          </div>
        }
        <IonContent >
          {renderFinish && this.renderFinishSection(history)}
        </IonContent>
          {termsLength > 0 && termsConditions.content.map((terms : any, index: number) => {
            const shouldRenderSection = this.shouldRenderSection(index, currentTerms, history)
            const checkboxText = this.checkboxTextByTerm(index)
            if (shouldRenderSection) return (
              <div key={index} className="terms-page">
                <div className="terms-wrapper">
                  <h3 className='title'>{terms.title}</h3>
                  <div className='paragraph'>{terms.subtitle}</div>
                  <div className='general-text'>{terms.paragraph}</div>
                  <div>
                    {terms.content && terms.content.length > 0 && terms.content.map((internalTerms:any, index: any) => {
                      return (
                        <div key={index}>
                          <div className='points'>{internalTerms.title}</div>
                          <div className='general-text'>{internalTerms.paragraph}</div>
                          {internalTerms.content && internalTerms.content.length > 0 && internalTerms.content.map((moreInternalTerms:any, index: number) => {
                          const paragraph = this.termsParagraph(moreInternalTerms.paragraph)
                            return (
                              <div key={index} className='general-text'><span>{moreInternalTerms.title}</span> {paragraph} </div>
                            )
                          })}
                        </div>
                      )
                    })}
                  </div>
                  { 
                    termsAccepted === false && 
                    <div className="accept-terms-container">
                      <div className="checkbox-wrapper">
                        <div onClick={() => this.checkboxChange(checkboxValue)} className={`checkbox ${checkboxValue ? 'checked' : ''}`}></div>
                        <label>{checkboxText}</label>
                      </div>
                      <div className="terms-button-wrapper">
                        <ButtonComponent 
                          disabled={!checkboxValue}
                          onClick={()=> this.acceptOneStep(index, termsLength)}
                          text={"Aceptar"}
                          className={'btn-primary'}
                        />
                      </div>
                    </div>
                  } 
                </div>    
              </div>       
            )
            return ''
          })}
        <style>
          {`
            .footer-menu-home {
             display: none !important; 
            }
          `}
        </style>
      </IonPage>
    )
  }
}

export default withRouter(TermsConditions)
