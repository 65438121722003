import React from 'react'
import Settings from '../../models/Settings'
import { IonIcon } from '@ionic/react'
import close from './../../assets/icons/nav_close.svg'
import search from '../../assets/icons/act_search.svg'

import track from 'react-tracking'

import './SearchBar.scss'

import { Device } from '@capacitor/device';

type Props = {
  settings?: Settings
  history?: any,
  onSearch?: any,
  categoryId?: any,
  searchText?: string,
  tracking?: any,
  isBackgroundTransparent?: boolean,
  onSearchCriteriaChange?: React.ChangeEventHandler<HTMLInputElement>
  routeProvider?: boolean
  routeHome?: boolean
}

type State = {
  searchText: string
  categoryId: string
  placeholder: string
  interval: any
  iconClose: boolean
  iconCloseClick: boolean
  icon: any
  lastSearchText: string
}

class SearchBar extends React.PureComponent<Props, State> {
  state: State = {
    searchText: '',
    categoryId: '',
    placeholder: 'Busca por proveedor, marca o producto',
    interval: null,
    iconClose: false,
    iconCloseClick: false, 
    icon: '',
    lastSearchText: ''
  }

  devicePlatform: any = {}

  async componentDidMount() {
    const { routeProvider, routeHome, searchText } = this.props

    this.placeHolderChanger()
    this.devicePlatform = (await Device.getInfo()).platform

    if (this.routeSplit()[4]) {
      this.setState({ icon: close, searchText: this.routeSplit()[4] })
    } 

    if (routeHome) {
      this.setState({
        icon: close, 
        iconClose: true,
        lastSearchText: searchText || ''
      })
    }
    if (routeProvider) this.setState({ icon: search })
    if (!routeProvider && !routeHome) this.setState({ icon: search })
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.searchText !== prevProps.searchText && !this.state.iconCloseClick) {
      this.setState({
        searchText: this.props.searchText || '',
      })
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.interval)
  }

  onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      this.onSearch()
    }
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { lastSearchText } = this.state

    this.setState({ searchText: event.target.value })

    if (lastSearchText !== '' && lastSearchText !== event.target.value) {
      this.setState({ icon: search, iconClose: false })
    }
  }

  onSearch = () => {
    const { routeProvider, routeHome, onSearch, history, categoryId } = this.props
    const { searchText } = this.state

    if (routeProvider || routeHome) {
      this.setState({
        iconClose: true,
        icon: close, 
        lastSearchText: searchText
      })
    }

    if (searchText && searchText.length > 2) {
      if (onSearch) {
        onSearch(searchText, categoryId)
      } else if (history) {
        const currentPath = history.location.pathname
        const newPath = `/results/${searchText}`
        
        if (currentPath !== newPath) {
          history.push(newPath, {
            searchText,
            categoryId,
            time: Date.now()
          })
        }
      }
    }
  }

  onClickIconClose = () => {
    this.setState({
      searchText: '',
      icon: search,
      iconClose: false,
      iconCloseClick: true,
    })
  }

  onSearchClick = () => { 
    const { iconClose } = this.state
    
    if (iconClose) {
      this.onClickIconClose()
    } else {
      this.onSearch()
    }
  }

  placeHolderChanger = () => {
    const { routeProvider } = this.props
    
    let placeholders = [
      'Intenta buscar "Leches"',
      '¿Qué buscas hoy?',
      '¿Cómo te podemos ayudar?',
      'Intenta palabras como "Manjar 1kg"'
    ]

    if (routeProvider) placeholders = ['Busca por proveedor, marca o producto']

    if (this.state.interval) {
      clearInterval(this.state.interval)
    }
    const interval = setInterval(() => {
      this.setState({
        placeholder: placeholders[Math.floor(Math.random() * placeholders.length)]
      })
    }, 10000)
    this.setState({
      interval
    })
  }

  showIcon = () => {
    const { routeProvider } = this.props
    const { iconClose, searchText } = this.state

    if (routeProvider && !iconClose) return search
    if (iconClose && searchText !== '' && routeProvider) return close
    if (!routeProvider) return search
  }

  routeSplit = () => this.props.history.location.pathname.split("/")

  render() {
    const { icon, searchText, placeholder } = this.state
    const { isBackgroundTransparent, history } = this.props
    const isIosAndResult = (history.location.pathname.indexOf('/results') !== -1 && this.devicePlatform === 'ios')

    return (
      <div className={`search-bar ${isBackgroundTransparent ? 'transparent-background' : ''} ${isIosAndResult ? 'no-padding-top' : ''}`}>
        <div>
          <input
            value={searchText}
            placeholder={placeholder}
            onKeyDown={this.onKeyDown}
            onChange={this.onChange}
          />
          <IonIcon className="search-bar-icon" icon={icon} onClick={this.onSearchClick} />
        </div>
      </div>
    )
  }
}

export default track({})(SearchBar)