import { Container } from 'unstated-typescript'
import { postCart, getCartClient, updateCartClient, verifyProviderReferal, whitelistByPaymentMethod as _whitelistByPaymentMethod } from '../clients/cart'
import { getFromCache } from '../utils/cache'
import { idCentralMayorista } from '../../src/utils/intl'
import { eventWithName, eventForCart } from '../pages/firebase/firebaseTags'
export interface CartProduct {
  providerId: string
  sku: string
  price: any
  quantity: number
  units: number
  packageType: string
  infoPrice: any
  paid?:boolean
  paymentForm?:string
  name?:string
}

export interface CartDiscounts {
  code: string
  amount: string
  discountType: string
  couponId: string
  couponActivateId: string
  provider: string
  realDiscount: string
  minimumAmount: string
  applyDiscount: boolean
  couponType: string
  providerId: string
}

export interface Cart {
  id: string
  state: string
  products: CartProduct[]
  discounts: CartDiscounts[]
  total: string
}

const storageCartName = '@cart'
export class CartModel extends Container<Cart> {
  state: Cart = {
    id: '',
    state: 'active',
    products: [],
    discounts: [],
    total: '',
  }

  constructor() {
    super()

    const fromCartCache = getFromCache<Cart>(storageCartName)
    if (fromCartCache) {
      this.setState(fromCartCache)
    }
  }

  setDiscounts = async (discount: CartDiscounts) => {
    if (discount.couponId) {
      const {
        code,
        amount,
        discountType,
        couponId,
        couponActivateId,
        provider,
        realDiscount,
        minimumAmount,
        applyDiscount,
        couponType,
        providerId
      } = discount
      const { discounts } = this.state
      const found = discounts.findIndex(
        (p: any) =>
          p.couponId === discount.couponId &&
          p.couponActivateId === discount.couponActivateId &&
          p.provider === discount.provider,
      )
      let newDiscounts
      let productos = this.state.products

      if (productos && productos.length > 0) {
        if (found !== -1) {
          const before = discounts.slice(0, found)
          const after = discounts.slice(found + 1, discounts.length)
          const newDiscount: CartDiscounts = {
            code,
            amount,
            discountType,
            couponId,
            couponActivateId,
            provider,
            realDiscount,
            minimumAmount,
            applyDiscount,
            couponType,
            providerId
          }
          newDiscounts = [...before, newDiscount, ...after]
        } else {
          newDiscounts = [...discounts, discount]
        }
        await this.setState({ discounts: newDiscounts })
      } else {
        await this.setState({ discounts: [] })
      }
      const response = await postCart(this.state)
      if (response.data._id && response.data._id !== undefined && response.data._id !== null) {
        await this.setState({
          id: response.data._id,
        })
      }

      localStorage.setItem(storageCartName, JSON.stringify(this.state))
    }
  }

  removeDiscount = async (providerId: string) => {
    let { discounts } = this.state
    if (providerId === '-1') {
      discounts = discounts.filter(p => p.provider === providerId)
    } else {
      discounts = discounts.filter(p => p.provider !== providerId)
    }
    await this.setState({ discounts: discounts })
  }

  setProduct = async (product: CartProduct) => {
    const { providerId, sku, price, quantity, units, infoPrice, packageType, paymentForm,paid, name} = product
    const { products, id } = this.state
    const found = products.findIndex((p: any) => p.sku === product.sku && p.providerId === product.providerId)

    let newProducts
    if (found !== -1) {
      const before = products.slice(0, found)
      const after = products.slice(found + 1, products.length)
      const newProduct: CartProduct = {
        providerId,
        sku,
        price,
        quantity,
        units,
        packageType,
        infoPrice,
        paymentForm,
        paid,
        name
      }
      newProducts = [...before, newProduct, ...after]
    } else {
      newProducts = [...products, product]
    }

    const carro = newProducts.filter((r: any) => {
      if (product.providerId === idCentralMayorista()) {
        return r.providerId === idCentralMayorista()
      } else {
        return r.providerId !== idCentralMayorista()
      }
    })
  
    await this.setState({ products: carro })
    if (id) {
      await updateCartClient(this.state).then((response) => {
        this.validatorForToast(response)
      })
      
    } else {
      await postCart(this.state).then((response) => {
        this.validatorForToast(response)
        this.setState({
          id: response.data._id,
        })
      })
    }

    localStorage.setItem(storageCartName, JSON.stringify(this.state))
  }

  

  validatorForToast = (response:any) =>{
    if(response && response.status === 200){
      localStorage.setItem("@addedToCart", JSON.stringify(true))
    }
  }

  removeProduct = async (providerId: string, sku: string, product:any, eventSource?: any) => {
    var { products } = this.state
    await this.setState({ products: products.filter((p: any) => p.sku !== sku || p.providerId !== providerId) })
    products = products.filter((p: any) => p.sku !== sku || p.providerId !== providerId)
    let productsByProvider = products.find((o: any) => o.providerId === providerId)
    if (!productsByProvider) {
      this.removeDiscount(providerId)
    }
    await updateCartClient(this.state)
    // eventForCart("delete_product_cart", product.name, product.id, product.brand, product.category, eventSource)
    localStorage.setItem(storageCartName, JSON.stringify(this.state))
  }

  saveCart = async(cart:any) =>{
    await this.setState({ products: cart.products})
    localStorage.setItem('@cart', JSON.stringify(cart))

    await updateCartClient(cart)
  }

  updateCart = async(cart:any) => {
      await this.setState({ products: cart})
  }

  getCart = () => {
    return this.state.products
  }

  getId = () => this.state.id
  getDiscounts = () => this.state.discounts
  getAllCard = () => this.state

  clearCart = async () => {
    await this.setState({ products: [], state: 'active' })
    const cart = this.state
        cart.state = 'cleaned'
        eventWithName("clear_cart");
    if (cart.id) {
      await updateCartClient(cart)
    }
    localStorage.removeItem(storageCartName)
    await this.setState({ products: [], state: 'active', id: '' })
  }

  finishCart = async (providerIdsToKeep?: Set<string>) => {
    const cart = this.state
    cart.state = 'sended'

    await this.setState({
      products: cart.products.filter((p: any) => providerIdsToKeep && providerIdsToKeep.has(p.providerId)),
      discounts: cart.discounts.filter((p: any) => providerIdsToKeep && providerIdsToKeep.has(p.provider)),
      state: 'active',
    })
    await postCart(cart)
    if (this.state.products && this.state.products.length > 0) {
      localStorage.setItem(storageCartName, JSON.stringify(this.state))
    } else {
      localStorage.removeItem(storageCartName)
    }
  }

  getCartClientApi = async (id: string) => (await getCartClient(id)).data

  async verifyProviderReferal(couponType: string) {
    const countResult = await verifyProviderReferal(couponType)
    return countResult
  }

  fixNotFoundCart = async () => {
    let cart: any = localStorage.getItem(storageCartName)
    if (cart) {
      cart = JSON.parse(cart)
    }
    cart.id = null
    const response = await postCart(cart)
    cart.id = response.data._id
    this.setState({
      id: response.data._id,
    })
    localStorage.removeItem(storageCartName)
    localStorage.setItem('@cart', JSON.stringify(cart))
  }

  whitelistByPaymentMethod = async (listIdProviders:any) => {
    const result = await _whitelistByPaymentMethod(listIdProviders)
    return result
  }
}
