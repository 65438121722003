export const getOfficeId = (): string | null => {
  const currentOffice = localStorage.getItem('currentOffice')

  if (!currentOffice || currentOffice != undefined) return null

  const { _id } = JSON.parse(currentOffice)

  return _id
}

export const getBussinesTypeId = (): string | null => {
  const currentOffice = localStorage.getItem('currentOffice')

  if (!currentOffice || currentOffice != undefined) {
    return null
  }

  const { bussinesTypeId } = JSON.parse(currentOffice)

  return bussinesTypeId
}

export const getComuneId = (): string | null => {
  const currentOffice = localStorage.getItem('currentOffice')

  if (!currentOffice || currentOffice != undefined) return null

  const { comuneId } = JSON.parse(currentOffice)

  return comuneId
}
