import { IonTextarea } from '@ionic/react'
import React, { Fragment, useState } from 'react'
import './SurveyNps.scss'

interface Props {
  info:any
  onChange: Function
}

const Textarea: React.FC<Props> = props => {
  const { info, onChange } = props
  const { title, _id } = info
  const [isFocused, setIsFocused] = useState(false)

  const onChangeRate = (e:any) => {
    let tempAnwserObj = {
      questionId: _id,
      options: [
        {
          'label': title,
          'value': e.detail.value
        }
      ]
    }

    onChange(tempAnwserObj)
  } 

  return (
    <Fragment>
      <div className="container-surveys">
        <h3>{title}</h3>
        <div className={`container-textarea ${isFocused ? 'focused' : ''}`}>
          <span className="message">Mensaje</span>
          <IonTextarea 
            onIonFocus={() => setIsFocused(true)}
            onIonBlur={() => setIsFocused(false)}
            placeholder="Tu opinión es importante"
            debounce={700}
            onIonChange={onChangeRate}           />
        </div>
      </div>
    </Fragment>
  )
}

export default Textarea
