import React, { Fragment, useEffect, useState } from 'react'
import { IonButton, IonContent, IonPage } from '@ionic/react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { StaticContext } from 'react-router-dom'
import track from 'react-tracking'

//COMPONENTS
import ImgLoginComponent from '../../pages/img-login-component/ImgLoginComponent'
import FindSurveyComponents from './FindSurveyComponents'
import ModalsController from '../../pages/modals-controller/ModalsController'

//MODELS
import { ProviderModel } from '../../models/ProviderModel'
import { CartModel } from '../../models/CartModel'
import { ProductModel } from '../../models/ProductModel'
import { PromotionModel } from '../../models/PromotionModel'
import Category from '../../models/Category'
import Office from '../../models/Office'
import Settings from '../../models/Settings'
import User from '../../models/User'
import SurveyModel from '../../models/Survey'

//ICONS
import delivery from '../../assets/delivery.png'

import './SurveyNps.scss'

type IPathParams = {}

interface RouteState {
  targetType: string
  targetId: string
  key?:string
  group?:string
  vendorId?:string
  isComplete?:boolean
  closeRedirectLink?: string
  closeLinkLocationState?: any
}

type IProps = RouteComponentProps<IPathParams, StaticContext, RouteState> & {
  user: User
  settings: Settings
  offices: Office
  categories: Category
  promotion: PromotionModel
  productModel: ProductModel
  cartModel: CartModel
  providerModel: ProviderModel
  surveyModel: SurveyModel
}

const SurveyNps: React.FC<IProps> = props => {
  const { surveyModel } = props
  const { closeRedirectLink, closeLinkLocationState } = props.location.state
  const [questions, setQuestions] = useState<any>([])
  const [tempAnswers, setTempAnswers] = useState<any>([])
  const [requestBody, setRequestBody] = useState<object>({})
  const [surveyId, setSurveyId] = useState<string>('')
  const [isAvailableToSend, setIsAvailableToSend] = useState<boolean>(false)
  const [showOnlyModal, setShowOnlyModal] = useState<boolean>(false)
  const [isComplete, setIsComplete] = useState<boolean>(false) 

  useEffect(() => {
    getQuestions()
  }, [!questions]) 

  useEffect(() => {
    validateIfSurveyCanBeSubmitted()
    prepareRequestBody()
  }, [tempAnswers])

  const getQuestions = async () => { 
    const { key, group, vendorId } = props.history.location.state
    const countryName = localStorage.getItem('countryName') 
    const country = countryName ? JSON.parse(countryName) :  null 
    const idVendor = vendorId || undefined
    const fetchQuestions = await surveyModel.getSurveys(country.countryCode, key, idVendor, group)
    if(fetchQuestions?.length) {
      setSurveyId(fetchQuestions[0]._id)
      setQuestions(fetchQuestions)
    } 
  }
  
  const onChangeAnswer = (questionAnswered: any) => {
    const matchAnswer = findInTempAnswers(questionAnswered) 
    let tempAnswersCopy = [...tempAnswers]

    if (matchAnswer) {
      const ifExistsThatAnswer = (answered:any) => answered.questionId === matchAnswer.questionId;
      const index = tempAnswersCopy.findIndex(ifExistsThatAnswer)
      tempAnswersCopy[index] = questionAnswered
    } else {
      tempAnswersCopy.push(questionAnswered)
    }

    setTempAnswers(tempAnswersCopy)
  }

  const validateIfSurveyCanBeSubmitted = () => {
    if (!questions.length) return

    const currentAnswersQuestionsIds = tempAnswers.map((tempAnswer: any) => tempAnswer.questionId)
    const requiredQuestionsIds = questions[0].questions
      .filter((question:any) => question.required)
      .map((question:any) => question._id)

    const ifIsExistsAllAnswers = (answerId:any) => requiredQuestionsIds.includes(answerId);

    if ((currentAnswersQuestionsIds.length >= requiredQuestionsIds.length) && currentAnswersQuestionsIds.every(ifIsExistsAllAnswers)) {
      setIsAvailableToSend(true)
    }
  }

  const prepareRequestBody = () => {
    const { targetType, targetId, key } = props.history.location.state

    let requestBodyCopy:any = {
      ...requestBody, 
      ...{
        'surveyKey': key,
        'targetType': targetType,
        'answers': tempAnswers
      } 
    }

    if (targetId || targetId !== '') requestBodyCopy['targetId'] = targetId

    setRequestBody(requestBodyCopy)
  }

  const mappingQuestions = () => (
    questions.map((question: any, index:number) => (
      <FindSurveyComponents 
        key={index} 
        questions={question.questions}
        onChange={onChangeAnswer}
        isComplete={setIsComplete}
      />
      )
    )
  )

  const onSendSurvey = async () => {
    setIsAvailableToSend(false)
    await surveyModel.sendSurvey(surveyId, requestBody)
    setShowOnlyModal(true)
  }
  
  const findInTempAnswers = (questionAnswered:any) => tempAnswers.find(({questionId}:any) => questionId === questionAnswered.questionId)

  return (
    <IonPage >
      { !showOnlyModal &&
        <Fragment>
          {!isComplete && <div className="is-not-loaded"></div>}
          <IonContent className="parent-survey-container" >
            <ImgLoginComponent
              img={delivery}
              text={''}
              arrowBackOnclik={false}
              from='survey-nps'
              closeRedirectLink={closeRedirectLink}
              closeLinkLocationState={closeLinkLocationState}
              {...props}
            />
            {questions && mappingQuestions()}
          </IonContent>
          <div className='footer-btn-survey'>
            <IonButton 
              expand="block"
              shape="round"
              onClick={onSendSurvey}
              disabled={!isAvailableToSend}             >
              ENVIAR
            </IonButton>
          </div> 
        </Fragment>
      }
      { showOnlyModal &&
        <ModalsController 
          showModals={true}
          titleWelcome={'¡Muchas gracias por tu aporte!'}
          showModalWelcome={true}
          fromSurvey={true}
          {...props}
          closeRedirectLink={closeRedirectLink}
          closeLinkLocationState={closeLinkLocationState} 
        />
      }
      <style>
        {`
          .footer-menu-home {
            display: none !important;
          }
        `}
      </style>
    </IonPage>
  )
}

export default track({ page: 'SurveyNps' })(withRouter(SurveyNps))
