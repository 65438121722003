import React, { useEffect, useState } from 'react'
import { IonBadge, IonContent, IonPage, IonSpinner } from '@ionic/react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { StaticContext } from 'react-router-dom'
import track from 'react-tracking'

//COMPONENTS
import ToolBar from '../../components/tool-bar/ToolBar'
import GlobalComponents from '../../components'
import WordsSlider from '../../components/words-slider/WordsSlider'

//MODELS
import { ProviderModel } from '../../models/ProviderModel'
import { CartModel } from '../../models/CartModel'
import { ProductModel } from '../../models/ProductModel'
import { PromotionModel } from '../../models/PromotionModel'
import Category from '../../models/Category'
import Office from '../../models/Office'
import Settings from '../../models/Settings'
import User from '../../models/User'

//UTILS
import { getCartLength, getLengthOfCart } from '../../utils/cart'
import { ifExistAndMayorToZero } from '../../utils/arrays'

//ICONS
import cartToolbarIcon from '../../assets/icons/nav_cart_blue.svg'

import { viewCatalogCategoryEvent, viewCatalogEvent } from '../firebase/firebaseTags'
import './OfferList.scss'
import { EventEmitter } from '../../utils/events'
import { navigateToCart } from '../../utils/navigation'

type IPathParams = {}

interface RouteState {
  categoryTree: any
  vendorId: string
}

type IProps = RouteComponentProps<IPathParams, StaticContext, RouteState> & {
  user: User
  settings: Settings
  offices: Office
  categories: Category
  promotion: PromotionModel
  productModel: ProductModel
  cartModel: CartModel
  providerModel: ProviderModel
}

const OfferList: React.FC<IProps> = props => {
  const { history } = props
  const [vendorsWithOffer, setVendorsWithOffers ] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [categorySelected, setCategorySelected] = useState<string>('')
  const [availableCategories, ] = useState<any>([])
  const [words, setWords] = useState<any>([])
  const [currentDeeplink, setCurrentDeeplink] = useState<string>('')
  const [selectedCategoryName, setSelectedCategoryName] =useState<any>('')
  const [cartLengthData, setCartLengthData] = useState<any>({ cartLength: 0, prevLength: 0})
  const [animateAtc, setAnimateAtc] = useState<string>('')

  useEffect(() => {
    registerViewCatalog()
    getVendorsWithOffers()
  }, []) 

  useEffect(() => {
    const cartLocal = localStorage.getItem('@cart')
    const cart = cartLocal ? JSON.parse(cartLocal) : null
    if (cart) {
      const updatedCartLength: any = cart?.products?.length || null
      setCartLengthData({ cartLength: updatedCartLength, prevLength: updatedCartLength })
    }

    getCart()

    EventEmitter.subscribe('UpdateCartCount', ({ cartUpdateAction }: any) => {
      setCartLengthData((prevState: any) => {
        let newCartLength = prevState.cartLength
        if(cartUpdateAction === 'add') newCartLength = newCartLength + 1
        if(cartUpdateAction === 'subtract') newCartLength = newCartLength - 1
        
        const didAdd = newCartLength > prevState.cartLength
        if (didAdd) {
          setAnimateAtc('badge-anim-in')
          setTimeout(() => { setAnimateAtc('badge-anim-out') }, 1);
        }
        return { 
          cartLength: newCartLength,
          prevLength: prevState.cartLength 
        }
      })
    })

    return () => {
      EventEmitter.unsubscribe('UpdateCartCount')
    }
  }, [])

  const getCart = async () => {
    const cartLength: any = await getCartLength()
    setCartLengthData({ cartLength, prevLength: cartLength })
  }

  useEffect(() => {
    getCategoriesWithOffers()
  }, [availableCategories]) 
  
  const registerViewCatalog = () => {
    const deeplink = window.location.href
    viewCatalogEvent(deeplink)
    setCurrentDeeplink(deeplink)
  }

  const getVendorsWithOffers = async (categoryId?: string, wordName?: string) => {
    let category = categoryId || ''
    if(category !== '') {
      if(wordName) viewCatalogCategoryEvent(currentDeeplink, wordName)
      setCategorySelected(category) 
      setSelectedCategoryName(wordName)
    } else {
      setCategorySelected('')
      setSelectedCategoryName('')
    }
    const fetchVendors = await props.categories.vendorsWithOffers(category)
    setVendorsWithOffers(fetchVendors)
  }

  const getCategoriesWithOffers = async () => {
    const fetchCategories = await props.categories.categoriesWithOffers()
    
    setWords(modifyPropertiesOfCategories(fetchCategories))
  }

  const modifyPropertiesOfCategories = (categories:any) => {
    let newArrayOfCategories = categories.map((category:any) =>{
      return {
        _id: category.id,
        name: category.name,
        from: 'list-offers'
      };
   });

   return newArrayOfCategories
  }

  const getProductsWithTree = () => {
    if(ifExistAndMayorToZero(vendorsWithOffer)) {
      return (
        vendorsWithOffer.map((category:any, index: number) => {
          return (
            <GlobalComponents.ProductSliderAsync
              filterBrand={false}
              singleProviderId={category._id}
              category={category}
              hideMore={false}
              isLoading={isLoadingProducts}
              categorySelectedInWordSlider = {categorySelected}
              from='list-offers'
              showInBlueColor={true}
              {...props}
              onlyOffers={true}
              categoryPosition={index + 1}
              filterCategoryName={selectedCategoryName}
              nameProvider={''}
              placementName={`offer-list-product-slider`}
            />
          )
        })
      )
    }
  }

  const goToCart = () => navigateToCart(history, props.location.state || props.history.location.state);

  const goToHome = () => history.push('/home')

  const isLoadingProducts = (value:boolean) => setIsLoading(value)

  const whenUpdateOffers = () => setVendorsWithOffers([])

    return (
      <IonPage className="offers-page" >
        <div className='relative-header-wrapper'>
          {cartLengthData.cartLength > 0 && <IonBadge className={`cart-badge-btn ${animateAtc}`} >{cartLengthData.cartLength}</IonBadge> }
          <ToolBar
            title="Ofertas"
            secondaryButtons={[{ type: 'back', onClick: goToHome }]}
            secondaryHeader={true}
            boxShadow={true}
            primaryButtons={[
              {
                icon: cartToolbarIcon,
                key: 'cart',
                onClick: goToCart,
                badge: cartLengthData.cartLength,
                animateAtc,
              },
            ]}
          />
        </div>
        {ifExistAndMayorToZero(words) &&  
          <WordsSlider 
            words={words} 
            getVendorsWithOffers={getVendorsWithOffers}
            whenUpdateOffers={whenUpdateOffers}
            isLoading={isLoadingProducts}
            isLoadingProducts={isLoading}
          />
        }
        <IonContent >
          {isLoading &&
            <div className="loading-products">
              <p className="loading">Cargando productos</p>
              <IonSpinner className="spinner-mid" name="crescent"  />
            </div>
          }
          {getProductsWithTree()}
          <div className="container-height"></div>
        </IonContent>
      </IonPage>
    )
}

export default track({ page: 'OfferList' })(withRouter(OfferList))
