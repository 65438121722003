import React, { ReactNode } from 'react'
import './GuruSlide.scss'

interface GuruSlideProps {
    className?: string
    id?: string
    onClick?: () => void
    dataBannerType?: string
    dataProviderName?: string
    dataProviderId?: string
    dataBannerId?: string
    dataBannerName?: string
    children: ReactNode
}

export const GuruSlide: React.FC<GuruSlideProps> = ({
    id,
    className,
    onClick,
    dataBannerType,
    dataProviderName,
    dataProviderId,
    dataBannerId,
    dataBannerName,
    children
}) => {
    return (
        <div  
            id={id}
            className={`guru-slide-container ${className ?? ''}`}
            onClick={onClick ?? (() => {})}
            data-banner-type={dataBannerType}
            data-provider-name={dataProviderName}
            data-provider-id={dataProviderId}
            data-banner-id={dataBannerId}
            data-banner-name={dataBannerName}
        >
            {children}
        </div>
    )
}
