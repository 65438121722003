import React, { useRef } from 'react';
import { StandaloneSearchBox } from '@react-google-maps/api';

interface LocationSearchInputProps {
  addressInput: string | null;
  setAddressInput: (address: string | null) => void;
  setCoordinates: (coords: { lat: number; lng: number }) => void;
  findGeolocationSuccess: (position: GeolocationPosition) => void;
}

const LocationSearchInput: React.FC<LocationSearchInputProps> = ({
  addressInput,
  setAddressInput,
  setCoordinates,
  findGeolocationSuccess,
}) => {
  const searchBoxRef = useRef<google.maps.places.SearchBox | null>(null);

  const handlePlaceSelect = () => {
    if (searchBoxRef.current) {
      const places = searchBoxRef.current.getPlaces();
      if (places && places.length > 0) {
        const place = places[0];
        if (place.formatted_address) {
          setAddressInput(place.formatted_address);
        } else {
          setAddressInput('');
        }

        if (place.geometry && place.geometry.location) {
          const lat = place.geometry.location.lat();
          const lng = place.geometry.location.lng();

          setCoordinates({ lat, lng });

          const position: GeolocationPosition = {
            coords: {
              latitude: lat,
              longitude: lng,
              accuracy: 0,
              altitude: null,
              altitudeAccuracy: null,
              heading: null,
              speed: null,
            },
            timestamp: Date.now(),
          };

          findGeolocationSuccess(position);
        }
      }
    }
  };

  return (
      <StandaloneSearchBox
        onLoad={(ref) => {
          searchBoxRef.current = ref;
        }}
        onPlacesChanged={handlePlaceSelect}
      >
        <input
          type="text"
          placeholder="Ingresa tu dirección de despacho"
          defaultValue={addressInput ?? ''}
          className="location-search-input"
        />
      </StandaloneSearchBox>
  );
};

export default LocationSearchInput;