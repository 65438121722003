import React, { ReactNode, useEffect, useState } from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { Capacitor } from '@capacitor/core';
import './SafeAreaView.scss';

interface SafeAreaViewProps {
  children: ReactNode;
}

export const SafeAreaView: React.FC<SafeAreaViewProps> = ({ children }) => {
  const [platform, setPlatform] = useState<string>('');

  useEffect(() => {
    setPlatform(Capacitor.getPlatform());
  }, []);

  return (
    <IonPage>
      <IonContent className={`safe-area-content ${platform}`}>
        <div className="safe-area-container">
          {children}
        </div>
      </IonContent>
    </IonPage>
  );
};
